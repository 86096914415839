import React, { Component, useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { logout } from '../utils';

import MobileMenu from './dashboard-elements/MobileMenu';
import TopBar from './dashboard-elements/TopBar';
import Sidebar from './dashboard-elements/Sidebar';
import MenuSidebarProfil from './profile/MenuSidebar';

import SectionsView from './sections/View';
import SectionsCreate from './sections/Create';
import LinkInfo from './links/LinkInfo';
import LinkStatistics from './links/LinkStatistics';
import LinkShortInfo from './links/LinkShortInfo';
import SortedView from './links/SortedView';
import AddLink from './links/Add';
import Trash from './links/Trash';
import GroupsCreate from './groups/Create';
import GroupHistory from './groups/History';
import GroupSettings from './groups/Settings';
import GroupInfo from './groups/GroupInfo';
import GroupMembers from './groups/Members';

import SortLink from '../pages/links/SortLink';

import { GroupAPI, ProfileInfoAPI, LinkGetByAPI, GroupSectionsListAPI, LinksListAPI, GroupUsersListAPI } from '../utils';

const Group = (props) => {

  let { id } = useParams();

  const [userEmail, setUserEmail] = useState('');
  const [UserPhoto, setUserPhoto] = useState('');
  const [UserUsername, setUserUsername] = useState('');
  const [UserData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        logout();
        props.history.push('/dashboard');
    }

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(ProfileInfoAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setUserEmail(data.email);
          setUserPhoto(data.photo);
          setUserUsername(data.username,data.first_name,data.last_name);
          setUserData(data);
          setLoading(false);
        });
    }
  }, []);

  const [LinksByGroupData, setLinksByGroupData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  const [currentCount, setCount] = useState(10);
  const timer = () => {
    fetch(LinkGetByAPI+'?group='+id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
        setLinksByGroupData(data);
      //   setloadingLinks(false);
          if(currentCount >= 5){
            setCount(currentCount - 1);
          }
    });
  }
  
  useEffect(
      () => {
        // console.log(currentCount);
          if (currentCount == 10) {
              // return;
              const id = setInterval(timer, 1);
              return () => clearInterval(id);
          }else{
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
          }
      },
      [currentCount]
  );


  const [LinksData, setLinksData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(LinksListAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setLinksData(data);
        //   setloadingLinks(false);
        });
    }
  }, []);

  const [SectionsData, setSectionsData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(GroupSectionsListAPI+splitLocation[3]+'/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          if(typeof data[0] !== "undefined"){
            setSectionsData(data[0]);
            // console.log(data);
          }
        //   setloadingLinks(false);
        });
    }
  }, []);


  var currentLocation = window.location.pathname;
  var splitLocation = currentLocation.split("/");

  const [Group_users, setGroup_users] = useState([]);
  const [Group_user_role, setGroup_user_role] = useState('');
  const [Group_user_id, setGroup_user_id] = useState('');
  
  const [Owner_id, setOwner_id] = useState([]);
  const [Status_group, setStatus_group] = useState('');

  const [If_is_member_of_group, setIf_is_member_of_group] = useState(false);

  const [Can_delete_links, setCan_delete_links] = useState(false);
  const [Can_delete_sections, setCan_delete_sections] = useState(false);
  const [Can_invite_and_delete_users, setCan_invite_and_delete_users] = useState(false);
  const [Can_add_new_admins, setCan_add_new_admins] = useState(false);

  useEffect(() => {
    fetch(GroupUsersListAPI+splitLocation[3], {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
    .then(res => res.json())
    .then(data => {
      setGroup_users(data);
      // setTimeout(() => {
        // data.filter(data => data.user.id !== UserData.id).map((d)=>{
        //   // setIf_is_member_of_group(true);
        //   console.log(d.user.id);
        //   window.location.replace('/groups');
        // });
      // }, 500);
    });

                /// get Owner_id
              fetch(GroupAPI+splitLocation[3]+'/', {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
              })
              .then(res => res.json())
              .then(data => {
                  setOwner_id(data.user);
                  setStatus_group(data.status);
                  // console.log(data.status);
              });

  }, []);

  // if(Status_group !== ''){
  //   Group_users.filter(data => data.user.id !== UserData.id && Status_group !== true).map((d)=>{
  //     // window.location.replace('/groups');
  //     console.log(d.user.id);
  //     console.log(UserData.id);
  //     console.log(Status_group);
  //   });
  // }

  if(Group_user_role == ''){

    Group_users.filter(data => data.user.id == UserData.id).map((d)=>{
      setGroup_user_role(d.group_role.name);
      setGroup_user_id(d.id);

      setIf_is_member_of_group(true);
      // setCurrent_permisions(d.user_permisions);

      d.user_permisions.filter(Perm => Perm.permission.id == '1').map((d)=>{
        setCan_delete_links(true);
      });
      d.user_permisions.filter(Perm => Perm.permission.id == '2').map((d)=>{
        setCan_delete_sections(true);
      });
      d.user_permisions.filter(Perm => Perm.permission.id == '3').map((d)=>{
        setCan_invite_and_delete_users(true);
      });
      d.user_permisions.filter(Perm => Perm.permission.id == '4').map((d)=>{
        setCan_add_new_admins(true);
      });

    });
  }

//   const vio = [LinksData];

//   console.log(vio);



  // var text_top = 'Giallaib documente';
  // var icon_top = 'icon-folder_s_on icon-fz24';

  const [LinkID, setGetId] = useState('')

  document.body.classList.add('GR');

  const [Search_term, setSearch_term] = useState('')
  var Search_term_notfound = '';

  if (Search_term != '') {
    Search_term_notfound = '';
    const filter = Search_term;
    const data = LinksByGroupData;
    const lowercasedFilter = filter;
    var filteredData = data.filter(item => {
      return item.name.toLowerCase().includes(lowercasedFilter)
    });
    if (filteredData.length === 0) {
      Search_term_notfound = 'No matches found. Try another word.';
    }
  } else {
    var filteredData = LinksByGroupData;
  }

  return (
    <Route>
      <MobileMenu/>

            <div className="Dashboard d-flex">

                <MenuSidebarProfil photo={UserPhoto} username={UserData} />

                <Sidebar photo={UserPhoto} username={UserData} Unsorted={LinksData.filter(LinksData => LinksData.folder == null && LinksData.group == null).length} />

                

                <div className={splitLocation[2] == 'folder' || splitLocation[2] == 'group' ? "Content d-none d-md-block d-block":"Content d-none d-md-block"}>
                    <div className="container-fluid">

                      <TopBar Search_term={Search_term} onSearchTermChange={setSearch_term} If_is_member_of_group={If_is_member_of_group} UserId={UserData.id} Owner_id={Owner_id} GroupUserRole={Group_user_role} />


                        <div className="row">

                            <div className="Content__Zone col-12">

                              {If_is_member_of_group == true ?
                                <Route>
                                <div className="Sections row">
                                  {Group_user_role == 'author' || Group_user_role == 'private_author' || Group_user_role == 'admin' ?
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0">
                                        <a className="Section Section_Add" title="Create new section" data-toggle="modal" data-target="#CreatingSection">
                                            <div className="Section__bg d-flex align-items-center">
                                                <i className="icon-section"></i>
                                                <div className="Section__Options">
                                                    <h3 className="title mb-0">Create new section</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                  :null }

                                    {SectionsData.filter(SectionsData => SectionsData.group == id && SectionsData.parent == null ).map((section, index) => (
                                      <SectionsView key={index} UserId={UserData.id} Group_user_role={Group_user_role} section={section} FolderID={id} />
                                    )
                                    )}


                                </div>
                                <div className="Links row">

                                {/* {apiData.map((link) => <LinksView link={link} />)} */}

                                {/* {LinksData.length>0 && LinksData.map(link => <LinksView link={link} />)} */}

                                <Router>
                                  <Switch>
                                    
                                        <Route path="/groups/group/:id" children={ 
                                          <Router>
                                          {filteredData.filter(filteredData => filteredData.section == null).map((link, index) => (
                                          // {LinksData.filter(LinksData => LinksData.folder_id == null).map((link, index) => (
                                            <SortedView key={index} link={link} Can_delete_links={Can_delete_links} UserId={UserData.id} Group_user_role={Group_user_role} onGetId={setGetId} />
                                          ))}
                                          {Search_term_notfound != '' ?
                                          <p className='w-100 py-15 text-center'>{Search_term_notfound}</p>:null}
                                          </Router>
                                        } />

                                  </Switch>
                                </Router>
                                    

                                    {Group_user_role == 'author' || Group_user_role == 'private_author' || Group_user_role == 'admin' ?
                                      <button className="Add-link" data-toggle="modal" data-target="#AddLink"><i className="icon-plus"></i> <span>Add new link</span></button>
                                    :null }
                                
                                </div>
                                </Route>:<h4 className='text-gray py-25 w-100 text-center mx-auto'>You are not a Group Member. Join in a Group!</h4>
                              }  
                                
                            </div>

                        </div>

                    </div>
                </div>

                {Group_user_role == 'author' || Group_user_role == 'private_author' || Group_user_role == 'admin' ?
                  <AddLink />
                :null }

                <Trash />

                <GroupsCreate />
                <GroupHistory />

                {Group_user_role == 'admin' ? 
                  <GroupMembers GroupUserRole={Group_user_role} Can_delete_links={Can_delete_links} Can_delete_sections={Can_delete_sections} Can_invite_and_delete_users={Can_invite_and_delete_users} Can_add_new_admins={Can_add_new_admins} />
                  :null
                }
                {Group_user_role == 'admin' && Owner_id == UserData.id ? 
                  <GroupSettings />:
                  <GroupInfo GUid={Group_user_id} Gusers={Group_users.length} SectionS={SectionsData.filter(SectionsData => SectionsData.group == id).length} Glinks={LinksByGroupData.length} />
                }

                <SectionsCreate currentfolder={id} Group_user_role={Group_user_role} />

                <LinkInfo LinkID={LinkID} />
                <LinkStatistics LinkID={LinkID} />
                <LinkShortInfo LinkID={LinkID} />

                <SortLink LinkID={LinkID} />

            </div>
      </Route>
  );
};

export default Group;