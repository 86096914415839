import React, { Component, useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { logout } from '../utils';

import MobileMenu from './dashboard-elements/MobileMenu';
import TopBar from './dashboard-elements/TopBar';
import Sidebar from './dashboard-elements/Sidebar';
import MenuSidebarProfil from './profile/MenuSidebar';

import SectionsView from './sections/View';
import SectionsCreate from './sections/Create';
import LinkStatistics from './links/LinkStatistics';
import LinkInfo from './links/LinkInfo';
import SortedView from './links/SortedView';
import AddLink from './links/Add';
import Trash from './links/Trash';
import FoldersCreate from './folders/Create';
import FolderHistory from './folders/History';
import FolderSettings from './folders/Settings';
import GroupsCreate from './groups/Create';
import SortLink from '../pages/links/SortLink';

import { ProfileInfoAPI, LinkGetByAPI, SectionsListAPI, LinksListAPI, LinkAPI } from '../utils';

const Folders = (props) => {

  let { id } = useParams();

  const [userEmail, setUserEmail] = useState('');
  const [UserPhoto, setUserPhoto] = useState('');
  const [UserUsername, setUserUsername] = useState('');
  const [UserData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        logout();
        props.history.push('/dashboard');
    }

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(ProfileInfoAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setUserEmail(data.email);
          setUserPhoto(data.photo);
          setUserUsername(data.username,data.first_name,data.last_name);
          setUserData(data);
          setLoading(false);
        });
    }
  }, []);

  const [LinksByFolderData, setLinksByFolderData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  const [currentCount, setCount] = useState(10);
  const timer = () => {
    fetch(LinkGetByAPI+'?folder='+id, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
        setLinksByFolderData(data);
      //   setloadingLinks(false);
          if(currentCount >= 5){
            setCount(currentCount - 1);
          }
    });
  }
  
  useEffect(
      () => {
        // console.log(currentCount);
          if (currentCount == 10) {
              // return;
              const id = setInterval(timer, 1);
              return () => clearInterval(id);
          }else{
            const id = setInterval(timer, 1000);
            return () => clearInterval(id);
          }
      },
      [currentCount]
  );


  const [LinksData, setLinksData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(LinksListAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setLinksData(data);
        //   setloadingLinks(false);
        });
    }
  }, []);

  const [SectionsData, setSectionsData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(SectionsListAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setSectionsData(data);
        //   setloadingLinks(false);
        });
    }
  }, []);

//   const vio = [LinksData];

//   console.log(vio);



  // var text_top = 'Giallaib documente';
  // var icon_top = 'icon-folder_s_on icon-fz24';

  const [LinkID, setGetId] = useState('')


  var currentLocation = window.location.pathname;
  var splitLocation = currentLocation.split("/");

  const [Search_term, setSearch_term] = useState('');
  var Search_term_notfound = '';

  if(Search_term != ''){
    Search_term_notfound = '';
    const filter = Search_term;
    const data = LinksByFolderData;
    const lowercasedFilter = filter;
    var filteredData = data.filter(item => {
        return item.name.toLowerCase().includes(lowercasedFilter)
    });
    if (filteredData.length === 0) {
      Search_term_notfound = 'No matches found. Try another word.';
    }
  }else{
    var filteredData = LinksByFolderData;
  }

// Drag & drop

// Pentru trăgător
const handleDragStart = (event) => {
  event.dataTransfer.effectAllowed = "move";
  event.dataTransfer.setData("linkId", event.target.id);
}

// Pentru țintă
const handleDrop = (event) => {
  event.preventDefault();
  const linkId = event.dataTransfer.getData("linkId");
  const link = document.getElementById(linkId);
  // event.target.appendChild(link);
  console.log(event.currentTarget.id);
  console.log(linkId);
  if(event.currentTarget.id && linkId){

    const links = {
      section: event.currentTarget.id,
    };

    fetch(LinkAPI+linkId+'/', {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(links)
    })
    .then(res => res.json())
    .then(data => {
        if (data) {
            console.log(data);
        }
    });

  }
}

// Pentru țintă
const handleDragEnter = (event) => {
  event.preventDefault();
    event.target.style.border = "dashed 2px gray";
    // event.currentTarget.classList.add("dragged-over");
}

// Pentru țintă
const handleDragLeave = (event) => {
  event.preventDefault();
  event.target.style.border = "none";
  // event.currentTarget.classList.remove("dragged-over");
}

const handleDragOver = (event) => {
  // console.log('1---'+event.target+"------------");
  // const targetElement = event.target;
  // console.dir(targetElement);
  // console.dir('1---'+event.target+"------------");
  // console.log('2---'+event.currentTarget+"------------");
  // if (event.target === event.currentTarget) {
    event.preventDefault();
    event.dataTransfer.effectAllowed = "move";
  // }
}

  return (
    <Route>
      <MobileMenu/>

      
            <div className="Dashboard d-flex">

                <MenuSidebarProfil photo={UserPhoto} username={UserData} />

                <Sidebar photo={UserPhoto} username={UserData} Unsorted={LinksData.filter(LinksData => LinksData.folder == null && LinksData.group == null).length} />

                

                <div className={splitLocation[2] == 'folder' || splitLocation[2] == 'group' ? "Content d-none d-md-block d-block":"Content d-none d-md-block"}>
                    <div className="container-fluid">

                      <TopBar Search_term={Search_term} onSearchTermChange={setSearch_term} />


                        <div className="row">

                            <div className="Content__Zone col-12">

                                <div className="Sections row">

                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0">
                                        <a className="Section Section_Add" title="Create new section" data-toggle="modal" data-target="#CreatingSection">
                                            <div className="Section__bg d-flex align-items-center">
                                                <i className="icon-section"></i>
                                                <div className="Section__Options">
                                                    <h3 className="title mb-0">Create new section</h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>

                                    {SectionsData.filter(SectionsData => SectionsData.folder == id && SectionsData.parent == null).map((section, index) => (
                                      <SectionsView key={index} id={section.id} onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} section={section} FolderID={id} />
                                    ))}


                                </div>
                                <div className="Links row">

                                {/* {apiData.map((link) => <LinksView link={link} />)} */}

                                {/* {LinksData.length>0 && LinksData.map(link => <LinksView link={link} />)} */}

                                <Router>
                                  <Switch>
                                    

                                        <Route path="/dashboard/folder/:id" children={ 
                                          <Router>
                                          {filteredData.filter(filteredData => filteredData.section == null).map((link, index) => (
                                          // {LinksData.filter(LinksData => LinksData.folder_id == null).map((link, index) => (
                                            <SortedView key={index} id={link.id} onDragStart={handleDragStart} link={link} onGetId={setGetId} />
                                          ))}
                                          {Search_term_notfound != '' ?
                                          <p className='w-100 py-15 text-center'>{Search_term_notfound}</p>:null}
                                          </Router>
                                        } />

                                  </Switch>
                                </Router>
                                    


                                    <button className="Add-link"  data-toggle="modal" data-target="#AddLink"><i className="icon-plus"></i> <span>Add new link</span></button>
                                </div>
                                
                            </div>

                        </div>

                    </div>
                </div>

                <AddLink />
                <Trash />

                <FoldersCreate />

                <FolderSettings />
                
                <FolderHistory />
                
                <GroupsCreate/>

                <SectionsCreate currentfolder={id} />

                <LinkStatistics LinkID={LinkID} />

                <LinkInfo LinkID={LinkID} />

                <SortLink LinkID={LinkID} />

            </div>
    </Route>
  );
};

export default Folders;