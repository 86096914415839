import React, { useState, useEffect, useLocation } from 'react';
import axios from 'axios';

import { loginAPI, GoogleloginAPI } from '../../utils';
import HomeContent from '../../components/HomeContent';

import { GoogleLogin } from 'react-google-login';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";


const SignIn = (props) => {

  let { TKcode } = useParams();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ShowError, setShowError] = useState('');
  
    useEffect(() => {
      if (localStorage.getItem('token') !== null) {
        window.location.replace('/dashboard');
      } else {
        setLoading(false);
      }
    }, []);
  
    const onSubmit = e => {
      e.preventDefault();
  
      const user = {
        email: email,
        password: password
      };
  
      fetch(loginAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then(res => res.json())
        .then(data => {
          if (data.key) {
            localStorage.clear();
            localStorage.setItem('token', data.key);
            window.location.replace('/dashboard');
          } else {
            setEmail('');
            setPassword('');
            localStorage.clear();
            setErrors(true);
            setShowError(data.non_field_errors);
          }
        });
      };


      const usePathname = () => {
        const location = useLocation();
        return location.pathname;
      }

      // const responseGoogle = (response) => {
      //   console.log(response);
      // }

      function responseGoogle(response) {
        const token = response.accessToken;
        // axios.post(GoogleloginAPI+'/api/google-login/', { token })
        //   .then(response => {
        //     // tratați răspunsul de la Django
            // console.log(response);
        //   })
        //   .catch(error => {
        //     // tratați erorile
        //     console.log(error);
        //   });
        const google_token = {
          access_token: token,
          email: response.profileObj.email,
          googleId: response.profileObj.googleId,
          imageUrl: response.profileObj.imageUrl
        };
        fetch(GoogleloginAPI, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(google_token)
        })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            if (data.key) {
              localStorage.clear();
              localStorage.setItem('token', data.key);
              window.location.replace('/dashboard');
            } else {
              localStorage.clear();
              setErrors(true);
              setShowError(data.non_field_errors);
            }

          });
      }

      // const location = useLocation();
      // console.log(useLocation().pathname);
      var currentLocation = window.location.pathname;
      var splitLocation = currentLocation.split("/");

    return (
      <Route>
      {usePathname == "login" || TKcode == "confirmed-email" ? <HomeContent/>:null}

      <div className={TKcode || splitLocation[1] == "login" ? 'SideH activ':'SideH'}>
        {loading === false && (
          <Route>

          {/* {currentLocation == "signup" && (
            <HomeContent/>
          )}; */}

          <div className="Sidebar h-md-100 Sidebar--scroll px-25">
            <button className="close d-md-block"><i className="icon-close"></i></button>
            <h3 className="Conect-title mt-30 mb-25 text-center">{loading === false && 'Sign in'}</h3>

            {/* <a href="" className="Login-fb"><i className="icon-facebook"></i> <span>Sign in with Facebook</span></a> */}
            {/* <a href="" className="Login-google"><i className="icon-google"></i> <span>Sign in with Google</span></a> */}
            <GoogleLogin
              clientId="971264208978-qevpg665ptvub5mkm0pc3slk933ntaoj.apps.googleusercontent.com"
              className="mb-16"
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
            {/* <a href="" className="Login-apple"><i className="icon-apple"></i> <span>Sign in with Apple</span></a> */}

            <div className="or">or</div>

            <form onSubmit={onSubmit}>
                <div className="Forms Forms--blue">   
                    {errors === true && <span className="d-block text-center text-red fz-14 mb-10">{ShowError}</span>}    
                    {TKcode ? <span className="d-block text-center text-secondary fz-14 mb-10">Email confirmed successfully. You can log in to your account!</span>:null}                             
                    <div className="form-group">
                        <input type="email" name="email" className="form-control clearable" id="email" placeholder="Email" value={email} required onChange={e => setEmail(e.target.value)}/>{' '}
                        <label htmlFor="link">Email</label>
                    </div>
                    <div className="form-group">
                        <input type="password" name="name" className="form-control passv" id="password" placeholder="Password" value={password} required onChange={e => setPassword(e.target.value)}/>{' '}
                        <label htmlFor="link">Password</label>
                        <div className="pview"><i className="icon-visible_true"></i></div>
                    </div>
                <button type='submit' className="Button Button--blue w-100">Login</button>
                </div>
            </form>
            <a href="recover-password" className="Button Button--simple mb-15 mt-5">Forget password?</a>

            <div className="Conect-question mt-auto text-center mb-10">Don’t have an account?</div>
            <Link to="/signup" className="Button Button--blue-border mb-20">Sign up</Link>
          </div>
        </Route>
      )}
    </div>
    </Route>
    );
};

export default SignIn;