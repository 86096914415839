import React, { Component, useCallback, useEffect, useState, status } from 'react';
import { useLocation, setState, Route } from "react-router-dom";
import SkeletonLoader from "tiny-skeleton-loader-react";
import $ from 'jquery'; 

import { SiteURL, LinkStatsAPI } from '../../utils';

import moment from "moment";

// class LinksView extends Component {
const LinksView = (props, { onGetId }) => {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         onNameChange: this.props.onNameChange
    //         // link: this.props.link
    //     }
    // }

    // render(props) {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        // function handleSortLink(e) {
        //     e.preventDefault();
        //     // // console.log('You clicked submit.');
        //     // $("#SortLink").modal('show');
        //     // console.log(e.currentTarget.getAttribute("id"));
        //     // this.setState({link_id: e.currentTarget.getAttribute("id")});

        //     // $('#SortLink').attr('lsort', e.currentTarget.getAttribute("id"));
        //     props.onNameChange(e.currentTarget.getAttribute("id"))
        // }
        const handleGetId = useCallback(e => {
            props.onGetId(e.currentTarget.getAttribute("id"))
          }, [onGetId])
            // Sort Link
        // $(".Link .sort").click(function(){
        //     var link_id = $(this).attr('link-id');
        //     // $('.modal-title b').text(link_id);
        //     $("#SortLink").modal('show');
        // });
        
        // function checkImage(url) {
        //     var request = new XMLHttpRequest();
        //     request.open("GET", url, true);
        //     request.send();
        //     request.onload = function() {
        //       status = request.status;
        //       if (request.status == 200) //if(statusText == OK)
        //       {
        //         console.log("image exists");
        //         url = url;
        //       } else {
        //         url = '/assets/img/giallaib.png';
        //       }
        //     }
        //     return url;
        // }

        function validURL(image_url) {
            var res = image_url.match(/^[a-z][a-z0-9+.-]*:/);

            if(res !== null){
                return image_url;
            }else{
                return '/assets/img/giallaib.png';
            }
        }

        function nFormatter(num, digits) {
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
        }

        const [StatsVisits, setStatsVisits] = useState([]);

        
        function GetStats(link_id) {
            // var visits = [];
            useEffect( async () => {
                await fetch(LinkStatsAPI+link_id+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    setStatsVisits(data);
                    // console.log('2222')
                });
            }, []);
            return StatsVisits.filter(visits => visits.id != null).length
        }

        const isAbsoluteUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

        return (
            <div className="Link_style col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0">
                <div className="Link">
                    <div className="Link__bg">
                        <div className={props.link.thumb_img ? 'embed-responsive embed-responsive-16by9 cursor-pointer':'embed-responsive embed-responsive-16by9 cursor-pointer placeholder-bg'} onClick={()=> window.open(SiteURL+props.link.short_url_code, "_blank")} >
                            {props.link.thumb_img ? 
                                (<img className={isAbsoluteUrl(props.link.thumb_img) == false ? 'Link__share-img cover_img cover_img--placeholder img-fluid w-100':'Link__share-img cover_img img-fluid w-100'} src={validURL(props.link.thumb_img)} alt="Logo" />):
                                // (<img className="Link__share-img cover_img cover_img--placeholder img-fluid" src={props.link.favicon_img} alt="Logo" />)
                                (props.link.site == 'loading...' ? <SkeletonLoader /> : <img className="Link__share-img cover_img img-fluid" src="/assets/img/placeholder_bg.png" alt="Logo" />)
                            }
                        </div>
                        <h3 className="title-list title-list--mobile mr-auto d-none cursor-pointer" onClick={()=> window.open(SiteURL+props.link.short_url_code, "_blank")}>{props.link.name}</h3>
                        <div className="Link__Options d-flex pt-10">
                            
                            {splitLocation[2] == 'folder' ? 
                                (<img className="Link__share-img Link__share-img--list Link__icon img-fluid" src={props.link.favicon_img} alt="Logo" />) : 
                                (<button type="button" onClick={handleGetId} id={props.link.id} className="sort mr-auto" data-toggle="modal" data-target="#SortLink"> <span>Sort</span> </button>)
                            }
                            <h3 className="title-list mr-auto d-none cursor-pointer" onClick={()=> window.open(SiteURL+props.link.short_url_code, "_blank")}>{props.link.site == 'loading...' ? <SkeletonLoader /> : props.link.name}</h3>
                            <span className="data d-none">{moment(props.link.created_at).utc().format('DD.MM.YYYY HH:mm')}</span>
                            <span className="views mr-15 d-none"><i className="icon-visible_true"></i> 25.8 k</span>
                                <button type="button" onClick={handleGetId} id={props.link.id} className="statistic ml-auto" data-toggle="modal" data-target="#LinkStatistics">
                                    <i className="icon-statistic2"></i>
                                </button>
                                <button type="button" className="more LinkMoreOptions ml-0" onClick={handleGetId} id={props.link.id} data-toggle="modal" data-target="#LinkOptions">
                                    <i className="icon-more_vert"></i>
                                </button>
                            {splitLocation[2] == 'folder' ? 
                                (<Route></Route>) : 
                                (<button type="button" onClick={handleGetId} className="sort sort--mobile d-none" data-toggle="modal" data-target="#SortLink"> <span>Sort</span> </button>)
                            }
                        </div>
                    </div>
                    <div className="time-views d-flex align-items-center">
                        <span className="data">{moment(props.link.created_at).utc().format('DD.MM.YYYY HH:mm')}</span>
                        <span className="views ml-auto"><i className="icon-visible_true"></i> {nFormatter(GetStats(props.link.id), 1)}</span>
                    </div>
                    <h3 className="title cursor-pointer" onClick={()=> window.open(SiteURL+props.link.short_url_code, "_blank")}>{props.link.site == 'loading...' ? <SkeletonLoader /> : props.link.name}</h3>
                </div>
            </div>
        );
    // }
}

export default LinksView;