import React, { Component } from 'react';

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <footer className="Footer">
            <div className="container-fluid">
                <div className="row mb-30">
                    <div className="col-lg-2 text-center text-md-left">
                        <a className="Footer__Logo" href=""><img className="img-fluid" src="/assets/img/logo.svg" alt="Logo" /></a>
                    </div>
                    <div className="col-md-6 col-lg-2 text-center text-md-left">
                        <h3>Product</h3>
                        <ul className="Footer__Menu">
                            <li><a href="">Overview</a></li>
                            <li><a href="">Pricing</a></li>
                            <li><a href="">Costumer stories</a></li>
                            <li><a href="">Desktop apps</a></li>
                            <li><a href="">Mobile apps</a></li>
                            <li><a href="">Web Clipper</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-2 text-center text-md-left">
                        <h3>Notion for</h3>
                        <ul className="Footer__Menu">
                            <li><a href="">Enterprise</a></li>
                            <li><a href="">Small business</a></li>
                            <li><a href="">Personal use</a></li>
                            <li><a href="">Remote work</a></li>
                            <li><a href="">Startups</a></li>
                            <li><a href="">Education</a></li>
                            <li><a href="">Engineering</a></li>
                            <li><a href="">Product</a></li>
                            <li><a href="">Design</a></li>
                            <li><a href="">Managers</a></li>
                            
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-2 text-center text-md-left">
                        <h3>Resources</h3>
                        <ul className="Footer__Menu">
                            <li><a href="">Blog</a></li>
                            <li><a href="">Guides & tutorials</a></li>
                            <li><a href="">Webinars</a></li>
                            <li><a href="">Template gallery</a></li>
                            <li><a href="">Community</a></li>
                            <li><a href="">What's new</a></li>
                            <li><a href="">Find a consultant</a></li>
                            <li><a href="">API docs</a></li>
                            <li><a href="">Switch from Evernote</a></li>
                            <li><a href="">Switch from Confluence</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-2 text-center text-md-left">
                        <h3>Templates</h3>
                        <ul className="Footer__Menu">
                            <li><a href="">Company home</a></li>
                            <li><a href="">Meeting notes</a></li>
                            <li><a href="">To-dos</a></li>
                            <li><a href="">Weekly agenda</a></li>
                            <li><a href="">Docs</a></li>
                            <li><a href="">Roadmap</a></li>
                            <li><a href="">Design system</a></li>
                            <li><a href="">New hire onboarding</a></li>
                            <li><a href="">Product wiki</a></li>
                            <li><a href="">Content calendar</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-lg-2 text-center text-md-left">
                        <h3>Company</h3>
                        <ul className="Footer__Menu">
                            <li><a href="">About us</a></li>
                            <li><a href="">Careers</a></li>
                            <li><a href="">Media kit</a></li>
                            <li><a href="">Contact sales</a></li>
                            <li><a href="">Contact support</a></li>
                            <li><a href="">Email us</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row border-top align-items-center py-30">
                    <div className="col-lg-7">
                        <ul className="Footer__Info">
                            <li><a href=""><i className="icon-globe"></i> English</a></li>
                            <li><a href="">Terms and Conditions</a></li>
                            <li><a href="">Privacy Policy</a></li>
                            <li><a className="copy" href="">Copyright © 2022 Giallaib Inc.</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-center justify-content-md-end">
                        <ul className="Footer__Social">
                            <li><a href=""><i className="icon-twitter"></i></a></li>
                            <li><a href=""><i className="icon-linkedin"></i></a></li>
                            <li><a href=""><i className="icon-facebook-rect"></i></a></li>
                            <li><a href=""><i className="icon-instagram"></i></a></li>
                            <li><a href=""><i className="icon-youtube-play"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        );
    }
}

export default Footer;