import React, { Component } from 'react';

class Privacy extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <h1>Privacy</h1>
            </div>
        );
    }
}

export default Privacy;