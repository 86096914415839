import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';

import { GroupsCreateAPI, FolderToGroup, FolderAPI } from '../../utils';

const GroupsCreate = (props) => {

            const [group, setGroup] = useState('');
            const [group_status_create, setgroup_status_create] = useState(false);
            const [errors, setErrors] = useState(false);
            const [loading, setLoading] = useState(true);


            function onChangeStatus(e){
              if(e.currentTarget.getAttribute("stats") == 'true') {
                  setgroup_status_create(true)
              }else{
                  setgroup_status_create(false)
              }
            }
          
    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split("/");

    useEffect( async () => {
        if(splitLocation[2] == 'folder'){
            await fetch(FolderAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                setGroup(data.name)
            });
        }
    }, []);
          
        const onAddGroup = async e => {
              e.preventDefault();
          
              const groups = {
                name: group,
                status: group_status_create,
              };
            //   console.log(groups);
          
            if(splitLocation[2] == 'folder'){

            await fetch(FolderToGroup+splitLocation[3]+'/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(groups)
              })
                .then(res => res.json())
                .then(data => {
                  if (data) {
                    window.location.replace('/groups/group/'+data.group_id);
                  }
                });

            }else if(splitLocation[1] == 'groups'){
                await fetch(GroupsCreateAPI, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  },
                  body: JSON.stringify(groups)
                })
                  .then(res => res.json())
                  .then(data => {
                    if (data) {
                      window.location.replace('/groups/group/'+data.id);
                    }
                  });
            }
        };
    // }
              // console.log(group_status);

        return (
            <div className="modal fade" id="CreatingGroup">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={onAddGroup}>
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-plus"></i>{splitLocation[2] == 'folder' ? "Transfer folder to group":"Creating new group"} </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="Forms">
                                  <div className="form-group">
                                      <input type="text" name="group_name" className="form-control clearable" value={group} id="group_name" placeholder="Group name" required onChange={e => setGroup(e.target.value)} />
                                      <label htmlFor="group_name">Group name</label>
                                  </div>
                                </div>
                                  <div className="MMenu">
                                    <ul>
                                        <li>
                                            <a>
                                                <div className="d-flex align-items-center"><i className="icon-lock"></i> <span>Status</span></div>
                                                <div className="Custom-radius text-tertiary d-flex align-items-center ml-auto">
                                                    {/* {this.state.group_status == true ? */}
                                                        <Route>
                                                            {group_status_create == true ?
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_cr" stats="true" onClick={onChangeStatus} name="group_status_create" defaultChecked='checked' />
                                                                <label className="custom-control-label" htmlFor="public_cr">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>:
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_cr" stats="true" onClick={onChangeStatus} name="group_status_create" />
                                                                <label className="custom-control-label" htmlFor="public_cr">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>
                                                            }
                                                        </Route>
                                                        <Route>
                                                            {group_status_create == false ?
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_cr" stats="false" onClick={onChangeStatus} name="group_status_create" defaultChecked='checked' />
                                                                    <label className="custom-control-label" htmlFor="private_cr">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>:
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_cr" stats="false" onClick={onChangeStatus} name="group_status_create" />
                                                                    <label className="custom-control-label" htmlFor="private_cr">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>
                                                            }
                                                        </Route>
                                                    {/* } */}
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                  </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                                <button type='submit' className="Button Button--bg">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
}

export default GroupsCreate;