import React, { Component } from 'react';

import { FolderAPI } from '../../utils';

class FolderSettings extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            folder_name: '',
            Folder_data: [],
            saving: false,
        }
                
    }

    async componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[3]){
            await fetch(FolderAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Folder_data: data,
                        folder_name: data.name,
                    });
            });
        }

    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })
    // onChangeDes = e => {console.log(e.target.value )}
    


    
    render() {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        var FolderiD = splitLocation[3];
        var name = this.state.folder_name;

        async function onSubmitFolderChanges(e){
            e.preventDefault();
    
            const Folderid = FolderiD;            
    
            const folders = {
                name: name,
            };
    
            await fetch(FolderAPI+Folderid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(folders)
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace(currentLocation);
                }
            });
        }


        async function DeleteFolder(e){
            e.preventDefault();
    
            await fetch(FolderAPI+FolderiD+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace('/dashboard');
                }
            });
        }


        return (
            <div className="modal fade" id="FolderSettings">
                <div className="modal-dialog modal-dialog-centered modal-md text-center">
                    <div className="modal-content">
                    <form onSubmit={onSubmitFolderChanges}>
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-settings"></i> General settings<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                            <div className="FolderOptions pxy16">
                            
                                    <div className="Forms">

                                        <div className="form-group">
                                            <input type="text" name="folder_name" className="form-control clearable mb-0" id="folder_name" placeholder="Folder name" value={this.state.folder_name} onChange={this.onChange} />{' '}
                                            <label htmlFor="folder_name">Folder name</label>
                                        </div>

                                    </div>

                            </div>
                        
                            <div className="MMenu">
                                <ul>
                                    <li className='OpenFolderHistory' data-toggle="modal" data-target="#FolderHistory" data-dismiss="modal"><a><i className="icon-users"></i><span>History of changes</span></a></li>
                                    <li data-toggle="modal" data-target="#Trash" data-dismiss="modal">
                                        <a>
                                            <div className="d-flex align-items-center"><i className="icon-trash"></i> <span>Trash</span></div>
                                            {/* <div className="text-tertiary d-flex align-items-center ml-auto"><span>Never</span> <i className="icon-right ml-15 mr-0"></i></div> */}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="modal-space"></div>

                            <div className="MMenu">
                                <ul>
                                    <li onClick={DeleteFolder}><a><span className="text-red">Delete folder</span></a></li>
                                    <li data-toggle="modal" data-target="#CreatingGroup"><a><i className="icon-users"></i><span>Make a group</span></a></li>
                                </ul>
                            </div>

                            <div className="bg-graylight py-30 py-md-50 py-lg-100"></div>

                        </div>


                        <div className="modal-footer d-flex">
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                            {this.state.saving == false ? 
                                (<button type="submit" className="Button Button--bg">Save</button>) : 
                                (<button type="submit" className="Button Button--disable">Saving..</button>)
                            }
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default FolderSettings;