import React, { Component } from 'react';
import { Route } from "react-router-dom";

import ProfilEdit from '../profile/ProfilEdit'
import ChangePassword from '../profile/ChangePassword'

import { UserAPI } from '../../utils';

class ProfilSetting extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
        }
    }
    render() {
        return (     
            <Route>   
                <div className="modal fade ProfAct" id="ProfilSetting">
                    <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"><i className="icon-settings"></i> Setari</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="icon-close"></i></span>
                        </button>
                    </div>
                    <div className="modal-body px-0">

                        <div className="MProfile d-none d-md-flex align-items-center mr-15">
                                <div className="PR_image PR_image--80 mr-15">
                                    {this.props.photo ?
                                        <img className="embed-responsive-item cover_img img-fluid" src={this.props.photo} alt="Logo" />:
                                        <img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                    }
                                </div>
                        <div>
                            <h5 className="name">{this.props.username}</h5>
                            <span className="type">Free account</span>
                        </div>
                        </div>

                        <div className="modal-space"></div>
                        
                        <div className="MMenu">
                        <ul>
                            <li><a className="ProfilEdit" data-toggle="modal" data-target="#ProfilEdit"><i className="icon-edit-3"></i> Edit profile</a></li> 
                            {/* <li>
                            <a>
                                <div><i className="icon-language"></i> Language</div>
                                <div className="text-secondary ml-auto">English</div>
                            </a>
                            </li> */}
                            <li><a className="ChangePassword" data-toggle="modal" data-target="#ChangePassword"><i className="icon-lock"></i> Change password</a></li>
                        </ul>
                        </div>

                        <div className="modal-space"></div>

                        <div className="MMenu mb-20 mb-md-40">
                        <ul>
                            <li><a className="text-red"><i className="icon-trash text-red"></i> Delete account</a></li>
                        </ul>
                        </div>
                        
                    </div>
                    </div>
                </div>
                </div>

                <ProfilEdit />
                <ChangePassword />
            </Route>
        );
    }
}

export default ProfilSetting;