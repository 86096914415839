import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { logout } from '../../utils';
import ProfilSetting from '../profile/ProfilSetting'
import BrowserExtensions from '../profile/BrowserExtensions'
import ChooseAplan from '../profile/ChooseAplan'
import ContactTeams from '../profile/ContactTeams'
import Terms from '../profile/Terms'
import Privacy from '../profile/Privacy'


class MenuSidebarProfil extends Component {

    // state = {
    //     username: this.props.username
    // };
    

    render(props) {

        function refreshPage() {
            logout();
            window.location.reload(false);
        }

        var Name = this.props.username.first_name ? this.props.username.first_name+' '+this.props.username.last_name:this.props.username.username;

        return (
            <Route>
                <div className="overlay ovp">
                    <div className="Profile">
                        <button className="close d-none d-md-block"><i className="icon-close"></i></button>
                        <div className="Profile__Info text-center">
                            <div className="Logo mt-20 mb-20">Giallaib</div>
                            <div className="MyProfile mb-30">
                                <div className={this.props.username.id == '2' ? "PR_image King":"PR_image"}>
                                    {this.props.photo ?
                                        <img className="embed-responsive-item cover_img img-fluid" src={this.props.photo} alt="Logo" />:
                                        <img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                    }
                                </div>
                                <div className="d-block">
                                    <h5 className="name">{Name}</h5>
                                    <span className="type">Free account</span>
                                </div>
                            </div>
                        </div>
                        <div className="Profile__Menu">
                            <ul>
                                <li><a className="ProfilSetting" data-toggle="modal" data-target="#ProfilSetting"><i className="icon-settings"></i> Setări profil</a></li>
                                <li><a className="BrowserExtensions" data-toggle="modal" data-target="#BrowserExtensions"><i className="icon-majesticons_puzzle-line"></i> Extensii Browser</a></li>
                                {/* <li><a><i className="icon-statistic2"></i> Statistică profil</a></li> */}
                                <li><a className="ChooseAplan" data-toggle="modal" data-target="#ChooseAplan"><i className="icon-ph_rocket"></i> Choose a plan</a></li>
                                {/* <li><a><i className="icon-share-2"></i> Recomand Giallaib</a></li> */}
                                <li><a><i className="icon-info"></i> Giallaib FAQ</a></li>
                                <li><a className="ContactTeams" data-toggle="modal" data-target="#ContactTeams"><i className="icon-send"></i> Contactează Echipa</a></li>
                                <li><a className="Terms" data-toggle="modal" data-target="#Terms"><i className="icon-visible_true"></i> Termenii și Condițiile</a></li>
                                <li><a className="Privacy" data-toggle="modal" data-target="#Privacy"><i className="icon-shield"></i> Politica de Confidențialitate</a></li>
                                <li><a className="Logout" onClick={refreshPage}><i className="icon-log-out"></i> Log Out</a></li>
                            </ul>

                            
                        </div>
                    </div>
                </div>

                <ProfilSetting photo={this.props.photo} username={Name} />
                <BrowserExtensions />
                <ChooseAplan />
                <ContactTeams username={Name} />
                <Terms />
                <Privacy />
            </Route>
        );
    }
}

export default MenuSidebarProfil;