import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { UserAPI } from '../../utils';

class Terms extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
        }
    }
    render() {
        return (        
            <div className="modal fade ProfAct" id="Terms">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title"><i className="icon-visible-true"></i> Termenii și Condițiile</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                </div>
                <div className="modal-body">

                    <div className="Text-content pxy-16">
                    <p>In dolor vitae morbi enim. Id in tellus nec velit morbi at pulvinar porttitor. Vitae, dapibus commodo facilisis nisl augue nulla. At vel vitae ipsum mauris, blandit felis.</p> 
                    <p>Ut natoque sed elit ut est diam duis. Dis amet vitae in maecenas mattis euismod quis malesuada malesuada. Neque, lectus massa nulla ultrices euismod ultrices donec eget. Vulputate etiam quam fermentum, ultricies suspendisse nunc. Vitae tincidunt senectus mattis feugiat at eu, faucibus felis arcu. Velit imperdiet convallis pulvinar in sapien, proin. Lectus nec rhoncus volutpat potenti eu nam.</p>
                    <p>Nunc id ipsum, purus quis lectus. Id consequat cras nec elementum nec amet quam lectus enim. Nec, quis pellentesque ornare tortor nam. Iaculis tortor, faucibus lobortis aliquam integer mauris. Felis non nunc lectus suspendisse nisi integer pharetra facilisis. </p>
                    <p>Amet bibendum leo tortor id. Ac egestas id eleifend nascetur natoque dictum quis nunc viverra. Ut velit purus mauris volutpat ultrices viverra risus in.</p>
                    <p>Pulvinar etiam interdum vitae amet orci maecenas aliquam, dis. Ut sodales in ornare ullamcorper pharetra interdum. Justo augue risus, bibendum nunc viverra sapien pellentesque hac curabitur. Posuere hendrerit cras luctus eu orci molestie faucibus. Feugiat sed eu vulputate vel nam nulla. </p>
                    <p>Commodo convallis lectus ut viverra magna varius velit augue. Rhoncus erat phasellus interdum egestas nulla lectus. Cursus nibh commodo vivamus eu lorem tincidunt. Egestas eget rutrum pulvinar fringilla non dis eu tellus, sit. Nec elementum scelerisque amet mi. Sed diam velit sem eget sed. In adipiscing sed diam eget cras erat aliquet vulputate condimentum. Hendrerit nunc ut mattis eros. Lorem bibendum sed feugiat at elit.</p> 
                    </div>

                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Terms;