import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';

import { SectionsCreateAPI } from '../../utils';

const SectionsCreate = (props) => {

            const [section, setSection] = useState('');
            const [section_status_create, setsection_status_create] = useState(false);
            const [errors, setErrors] = useState(false);
            const [loading, setLoading] = useState(true);
          
            // console.log(props.currentfolder);

            var currentLocation = window.location.pathname;
            var splitLocation = currentLocation.split("/");

            var currentfolder = '';
            var currentgroup = '';
            var currenturl = '';
            var currentsection = '';

            // var isd = '';
            const [isd, setisd] = useState('');

            if(splitLocation[2] == 'folder'){
              currentfolder = props.currentfolder;
              currentgroup = null;
              if(splitLocation[4] == 'section'){
                currenturl = '/dashboard/folder/'+currentfolder+'/section/'+props.currentsection;
              }else{
                currenturl = '/dashboard/folder/'+currentfolder;
              }
            }else if(splitLocation[2] == 'group'){
              currentfolder = null;
              currentgroup = props.currentfolder;
              if(splitLocation[4] == 'section'){
                currenturl = '/groups/group/'+currentgroup+'/section/'+props.currentsection;
              }else{
                currenturl = '/groups/group/'+currentgroup;
              }
            }
            
            if(splitLocation[4] == 'section'){
              currentsection = props.currentsection;
            }

            // useEffect(() => {
            //   // setTimeout(() => {
                if(props.Group_user_role == 'author' && isd == ''){
                  console.log(props.Group_user_role);
                  // section_status_create = props.Group_user_role;
                  setisd('1');
                  setsection_status_create(true);
                }
            //   // }, 1000);
            // }, []);


            function onChangeStatus(e){
              if(e.currentTarget.getAttribute("stats") == 'true') {
                setsection_status_create(true)
              }else{
                setsection_status_create(false)
              }
            }

            const onAddSection = e => {
              e.preventDefault();
          
              const sections = {
                name: section,
                folder: currentfolder,
                group: currentgroup,
                parent: currentsection,
                status: section_status_create,
              };
          
              fetch(SectionsCreateAPI, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(sections)
              })
                .then(res => res.json())
                .then(data => {
                  if (data) {
                    window.location.replace(currenturl);
                  }
                });
              };

        return (
            <div className="modal fade" id="CreatingSection">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={onAddSection}>
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-plus"></i> Creating new section</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="Forms">
                                  <div className="form-group">
                                      <input type="text" name="section_name" className="form-control clearable" value={section} id="section_name" placeholder="Section name" required onChange={e => setSection(e.target.value)} />
                                      <label htmlFor="section_name">Section name</label>
                                  </div>
                                </div>
                                {splitLocation[2] == 'group' ?
                                <div className="MMenu">
                                    <ul>
                                        <li>
                                            <a>
                                                <div className="d-flex align-items-center"><i className="icon-lock"></i> <span>Status</span></div>
                                                <div className="Custom-radius text-tertiary d-flex align-items-center ml-auto">
                                                    {/* {props.Group_user_role == 'author' ? */}
                                                        <Route>
                                                            {section_status_create == true ?
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_sec_cr" stats="true" onClick={onChangeStatus} name="section_status_create" defaultChecked='checked' />
                                                                <label className="custom-control-label" htmlFor="public_sec_cr">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>:
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_sec_cr" stats="true" onClick={onChangeStatus} name="section_status_create" />
                                                                <label className="custom-control-label" htmlFor="public_sec_cr">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>
                                                            }
                                                        </Route>
                                                        {props.Group_user_role !== 'author' ?
                                                        <Route>
                                                            {section_status_create == false ?
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_sec_cr" stats="false" onClick={onChangeStatus} name="section_status_create" defaultChecked='checked' />
                                                                    <label className="custom-control-label" htmlFor="private_sec_cr">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>:
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_sec_cr" stats="false" onClick={onChangeStatus} name="section_status_create" />
                                                                    <label className="custom-control-label" htmlFor="private_sec_cr">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>
                                                            }
                                                        </Route>:null
                                                        }
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                  </div>:null
                                  }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                                <button type='submit' className="Button Button--bg">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
}

export default SectionsCreate;