import React, { useState } from 'react';
import { Route } from "react-router-dom";

import { LinksCreateAPI } from '../../utils';

const AddLink = (props) => {

            const [link, setLink] = useState('');
            const [linkname, setLinkname] = useState('');
            const [errors, setErrors] = useState(false);
            const [loading, setLoading] = useState(false);

            
            var currentLocation = window.location.pathname;
            var splitLocation = currentLocation.split("/");

            var folder_id = '';
            var group_id = '';
            var section_id = '';

            if(splitLocation[3] == ''){
              folder_id = null;
              group_id = null;
            }else if(splitLocation[2] == 'folder'){
              folder_id = splitLocation[3];
              group_id = null;
            }else if(splitLocation[2] == 'group'){
              folder_id = null;
              group_id = splitLocation[3];
            }

            if(splitLocation[5] == ''){
              section_id = null;
            }else{
              section_id = splitLocation[5];
            }
          
          
            const onAddLink = async e => {
              e.preventDefault();
          
              setLoading(true);



              const links = {
                link: link,
                name: linkname,
                folder: folder_id,
                group: group_id,
                section: section_id,
              };

              // console.log(folder_id);
          
              await fetch(LinksCreateAPI, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(links)
              })
                .then(res => res.json())
                .then(data => {
                  if (data) {
                    setLoading(false);
                    // window.location.replace(currentLocation);
                  }
                });
                setTimeout(() => {
                  window.location.replace(currentLocation);
                }, 1000);
            };

          const isAbsoluteUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

        return (
            <div className="modal fade" id="AddLink">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={onAddLink} autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-plus"></i> Adding new link</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="Forms">
                                    <div className="form-group">
                                        <input type="text" name="link" className="form-control clearable" autoFocus value={link} id="link" placeholder="Link adress" required onChange={e => setLink(e.target.value)} />
                                        <label htmlFor="link">Link adress</label>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control clearable" value={linkname} id="name" placeholder="Link name" onChange={e => setLinkname(e.target.value)} />
                                        <label htmlFor="link">Link name</label>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                                {loading == false ? 
                                  <Route>
                                    {isAbsoluteUrl(link) == false ? 
                                    <button type='submit' disabled className='Button Button--disable'>Add</button> : 
                                    <button type='submit' className='Button Button--bg'>Add</button>
                                    }
                                  </Route> :
                                  (<button type='submit' disabled className="Button Button--disable">Saving..</button>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
}

export default AddLink;