import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';
import FoldersView from '../folders/View';
import GroupsView from '../groups/View';
import moment from "moment";
import { LinkAPI, DeletedSectionsInFolderListAPI, DeletedLinksInFolderListAPI, DeletedSectionsInGroupListAPI, DeletedLinksInGroupListAPI } from '../../utils';

class Trash extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            filter: "",
            FolderId: '',
            GroupId: '',
            SectionId: '',
            SectionList: [],
            LinksList: [],
            GroupSectionId: '',
            sessid: this.props.sessid,
            curentTab: 1,
        }
        
    }
    
    handleGetFolderId = FolderId => {
        this.setState({ FolderId })
    }
    
    handleGetGroupId = GroupId => {
        this.setState({ GroupId })
    }

    handleGetSectionId = SectionId => {
        this.setState({ SectionId })
    }

    handleGetGroupSectionId = GroupSectionId => {
        this.setState({ GroupSectionId })
    }

    handleSearchChange = event => {
        this.setState({ filter: event.target.value });
    };



    async componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        if(splitLocation[1] == 'dashboard'){
            this.setState({
                curentTab: 1,
            });
        }else if(splitLocation[1] == 'groups'){
            this.setState({
                curentTab: 1,
            });
        }

        if(splitLocation[2] == 'folder'){
            await fetch(DeletedSectionsInFolderListAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    SectionList: data,
                });
            });
            
            await fetch(DeletedLinksInFolderListAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    LinksList: data,
                });
            });
        }else if(splitLocation[2] == 'group'){

            await fetch(DeletedSectionsInGroupListAPI+splitLocation[3]+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        SectionList: data,
                    });
                });
            
            await fetch(DeletedLinksInGroupListAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({
                     LinksList: data,
                });
            });

        }

    }



    render(props) {

        var IDFolder = this.state.FolderId;
        var IDGroup = this.state.GroupId;
        var SectionId = this.state.SectionId;
        var GroupSectionId = this.state.GroupSectionId;


        if(this.state.curentTab == '1'){
            IDGroup = null
        }else if(this.state.curentTab == '2'){
            IDFolder = null
            SectionId = GroupSectionId
        }


        const filter = this.state.filter;

        const data_sections = this.state.SectionList;
        const data_links = this.state.LinksList;
        const lowercasedFilter = filter;
        var SectionsfilteredData = data_sections.filter(item => {
            return item.name.toLowerCase().includes(lowercasedFilter)
        });               
        var LinksfilteredData = data_links.filter(item => {
            return item.name.toLowerCase().includes(lowercasedFilter)
        });               

        
        this.tiggerTabsOnClick = (e) => {
            this.setState({
                curentTab: e.currentTarget.getAttribute("curenttab"),
            });
        }


        function checkImage(url) {
            var http = new XMLHttpRequest();
            http.open('GET', url, false);
            http.send();
            if (http.status == 200){
                return false;
            }else{
                return true;
            }
        }

        const isAbsoluteUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

        function truncateString(str, num) {
            if (str.length <= num) {
              return str;
            }
            return str.slice(0, num) + '...';
          }

        return (
            <div className="modal fade" id="Trash">
                <div className="modal-dialog modal-dialog-centered modal-lg text-center">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title"><i className="icon-trash"></i> Trash<b></b></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                </div>
                <div className="modal-body pt-0 px-0">
                    <div className="Tabs mt-0 d-none d-md-flex">
                        <div className={this.state.curentTab == '1' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="1">
                            <i className="icon-section"></i>
                            <span>Sections</span>
                        </div>
                        <div className={this.state.curentTab == '2' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="2">
                            <i className="icon-link"></i>
                            <span>Links</span>
                        </div>
                    </div>

                    <div className="Tabs-content">
                        <div id="Folders" className={this.state.curentTab == '1' ? "tab-content activ":"tab-content"}>
                            <form className="Search">
                                <i className="icon-search"></i>
                                <input className="form-control mr-sm-2 mb-0" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={this.handleSearchChange} />
                            </form>

                            <div className="Folders">
                              {SectionsfilteredData.length > 0 ?
                                <ul className='Section-List'>
                                    {SectionsfilteredData.map((section, index) => (
                                        <li key={index}>
                                            <div className='d-flex align-items-center'>
                                                <i className="icon-section"></i>
                                                <p>{section.name}</p>
                                            </div>
                                            <span className='text-red ml-auto pr-10'>{moment(section.delete_time).add(90, 'days').diff(moment(), 'days')} days</span>
                                            <i className="icon-more_vert"></i>
                                        </li>
                                    ))}
                                </ul>
                                :<p className='w-100 text-center pt-15 pb-10'>There is no deleted items yet.</p>
                              }
                            </div>
                        </div>

                        <div id="Groups" className={this.state.curentTab == '2' ? "tab-content activ":"tab-content"}>
                            <form className="Search">
                                <i className="icon-search"></i>
                                <input className="form-control mr-sm-2 mb-0" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={this.handleSearchChange} />
                            </form>

                            <div className="Folders">
                              {LinksfilteredData.length > 0 ?
                                <ul className='Section-List'>
                                    {LinksfilteredData.map((link, index) => (
                                        <li key={index}>
                                            <div className='d-flex align-items-center'>
                                                {link.favicon_img > 0 || isAbsoluteUrl(link.favicon_img) || checkImage(link.favicon_img) !== false ? 
                                                    (<img className="Link__share-img Link__share-img--list Link__icon img-fluid" src={link.favicon_img} alt="Logo" />) : 
                                                    (<img className="Link__share-img Link__share-img--list Link__icon img-fluid" src="/assets/img/placeholder.png" alt="Logo" />)
                                                }
                                                <p>{truncateString(link.name, 25)}</p>
                                            </div>
                                            <span className='text-red ml-auto pr-10'>{moment(link.delete_time).add(90, 'days').diff(moment(), 'days')} days</span>
                                            <i className="icon-more_vert"></i>
                                        </li>
                                    ))}
                                </ul>
                                :<p className='w-100 text-center mb-0 pt-15 pb-10'>There is no deleted items yet.</p>
                              }
                            </div>

                        </div>
                    </div>


                </div>
                <div className="modal-footer d-flex">
                    <button type="button" className="Button mr-auto" data-toggle="modal" data-target="#FolderSettings" data-dismiss="modal">Back</button>
                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default Trash;