import React, { Component } from 'react';
import FoldersView from '../folders/View';
import GroupsView from '../groups/View';

import GeneralSearch from '../GeneralSearch';

import { FoldersListAPI } from '../../utils';
class Sidebar extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            Folders: [],
        }        
    }


    async componentDidMount(props) {
        await fetch(FoldersListAPI, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    Folders: data,
                });
        });
    }

    render() {

        // var currentLocation = window.location.href;
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // console.log(splitLocation[2]);

        var Name = this.props.username.first_name ? this.props.username.first_name+' '+this.props.username.last_name:this.props.username.username;

        return (
                <div className={splitLocation[2] == 'folder' || splitLocation[2] == 'group' ? "Sidebar inactive":"Sidebar"}>
                    <div className="d-flex align-items-center pt-md-15">
                        <div className="Logo d-md-none pl-15"><img className="img-fluid" src="/assets/img/giallaib.png" alt="Logo" /></div>
                        <div className="MyProfile d-none d-md-flex align-items-center mr-15">
                            <div className={this.props.username.id == '2' ? "PR_photo King ml-15":"PR_photo ml-15"}>
                                {this.props.photo ?
                                    <img className="embed-responsive-item cover_img img-fluid" src={this.props.photo} alt="Logo" />:
                                    <img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                }
                            </div>
                            <div>
                                <h5 className="name">{Name}</h5>
                                <span className="type">Free account</span>
                            </div>
                        </div>
                        <div className="Notification ml-auto mr-15 d-flex"><i className="icon-bell"></i></div>
                    </div>
                    <div className="Menu d-none d-md-flex">
                        <a href="/dashboard" className={splitLocation[1] == 'dashboard' ? "activ":null}>
                            <i className="icon-folder"></i>
                            <div className="d-flex align-items-center">
                                <span>Folders</span> 
                                {/* <b className="Unsorted__nr ml-10">{this.state.Folders.filter(Folders => Folders.name != null).length}</b> */}
                            </div>
                        </a>
                        <a href="/groups" className={splitLocation[1] == 'groups' ? "activ":null}>
                            <i className="icon-users"></i>
                            <span>Groups</span>
                        </a>
                    </div>

                    <form className="Search" data-toggle="modal" data-target="#GeneralSearch">
                        <i className="icon-search"></i>
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                    </form>
                    
                    <a className={currentLocation == '/dashboard' || currentLocation == '/groups' ? "Unsorted d-flex activ" : "Unsorted d-flex"} href={splitLocation[1] == 'dashboard' && splitLocation[2] != undefined || splitLocation[1] == 'groups' && splitLocation[2] != undefined ? "/"+splitLocation[1] : "#"}>
                        <i className="icon-unsorted"></i>
                        <div>
                            <h4 className="Unsorted__name">Unsorted</h4>
                            <span className="Unsorted__links">{this.props.Unsorted} links</span>
                        </div>
                        <span className="Unsorted__nr ml-auto mt-auto">{this.props.Unsorted}</span>
                    </a>


                    <div className="Folders">
                        {splitLocation[1] == 'dashboard' ?
                            <FoldersView />:null
                        }
                        {splitLocation[1] == 'groups' ?
                            <GroupsView/>:null
                        }
                    </div>

                        {splitLocation[1] == 'dashboard' ?
                            <button className="New-folder"><i className="icon-plus"></i> Create new folder</button>:null
                        }
                        {splitLocation[1] == 'groups' ?
                            <button className="New-group" data-toggle="modal" data-target="#CreatingGroup"><i className="icon-plus"></i> Create new group</button>:null
                        }

                        <GeneralSearch/>
                </div>
        );
    }
}

export default Sidebar;