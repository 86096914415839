import { isEmptyObject } from 'jquery';
import React, { Component, useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

// import { getUniqueId, getManufacturer } from 'react-native-device-info';


import { ShortCodeLinkAPI, AddLinkStatsAPI, SiteURL } from '../utils';

const ShortURL = (props) => {

  let { ShortCODE } = useParams();

  const [LinksToRedirect, setLinksData] = useState('');
  const [LinkID, setLinkID] = useState('');
  const [SuccessAddStats, setSuccessAddStats] = useState([]);

  useEffect(() => {
    // if (ShortCODE[0] == 'l') {
      if (ShortCODE) {
        fetch(ShortCodeLinkAPI+ShortCODE, {
          method: 'GET',
          // headers: {
          //   'Content-Type': 'application/json',
          //   Authorization: `Token ${localStorage.getItem('token')}`
          // }
        })
          .then(res => res.json())
          .then(data => {
            setLinksData(data[0].link);
            setLinkID(data[0].id);

            var device = '';
            // if (navigator.userAgentData.platform === null) {
            //   device = '';
            // }else{
            //   device = navigator.userAgentData.platform
            // }
            

              const link_stats = {
                link: data[0].id,
                device: device,
                country: "country",
                city: "city"
              };
              
              fetch(AddLinkStatsAPI, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(link_stats)
              })
                .then(res => res.json())
                .then(data => {
                  if (data) {
                    setSuccessAddStats(data);
                    // setCurrentLinkID(LinkID);
                    // CurrentLinkID++;
                    // console.log(LinkID);
                  }
              });
          });
      }

    // }
  }, []);
  

// console.log(ShortCODE[0]);

if(LinksToRedirect && SuccessAddStats){
  // window.location.href = LinksToRedirect;
  
  window.location.replace(LinksToRedirect);
  // console.log(LinkID);
  // console.log(navigator);
  // console.log(SuccessAddStats);
}

  return (
    <Route path="/:ShortCODE">
      {/* {LinksData[{}][0]} */}
    </Route>
  );
};

export default ShortURL;