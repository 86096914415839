import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';
import moment from "moment";
import { LinksListAPI, DeletedSectionsInFolderListAPI, DeletedLinksInFolderListAPI, DeletedSectionsInGroupListAPI, DeletedLinksInGroupListAPI } from '../utils';
import FoldersView from "./folders/View"
import GroupsView from "./groups/View"
import SortedViewList from "./links/SortedViewList"

class GeneralSearch extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            filter: "",
            FolderId: '',
            GroupId: '',
            SectionId: '',
            SectionList: [],
            LinksList: [],
            GroupSectionId: '',
            sessid: false,
            curentTab: 1,
        }
        
    }
    
    handleGetFolderId = FolderId => {
        this.setState({ FolderId })
    }
    
    handleGetGroupId = GroupId => {
        this.setState({ GroupId })
    }

    handleGetSectionId = SectionId => {
        this.setState({ SectionId })
    }

    handleGetGroupSectionId = GroupSectionId => {
        this.setState({ GroupSectionId })
    }

    handleSearchChange = event => {
        this.setState({ filter: event.target.value });
    };



    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        if(splitLocation[1] == 'dashboard'){
            this.setState({
                curentTab: 1,
            });
        }else if(splitLocation[1] == 'groups'){
            this.setState({
                curentTab: 2,
            });
        }


    }



    render(props) {

        var IDFolder = this.state.FolderId;
        var IDGroup = this.state.GroupId;
        var SectionId = this.state.SectionId;
        var GroupSectionId = this.state.GroupSectionId;

        if(this.state.filter.length > 0 && this.state.sessid != true){
            fetch(LinksListAPI, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
              .then(res => res.json())
              .then(data => {
                  data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
                  this.setState({
                    LinksList: data,
                    sessid: true,
                  });
              });
        }


        if(this.state.curentTab == '1'){
            IDGroup = null
        }else if(this.state.curentTab == '2'){
            IDFolder = null
            SectionId = GroupSectionId
        }

        var LinksfilteredData = [];

        const filter = this.state.filter;

        if(this.state.filter.length > 0){

            const data_links = this.state.LinksList;
            const lowercasedFilter = filter;
    
            var LinksfilteredData = data_links.filter(item => {
                return item.name.toLowerCase().includes(lowercasedFilter)
            });

        }else{
            LinksfilteredData = [];
        }

        
        this.tiggerTabsOnClick = (e) => {
            this.setState({
                curentTab: e.currentTarget.getAttribute("curenttab"),
            });
        }


        function checkImage(url) {
            var http = new XMLHttpRequest();
            http.open('GET', url, false);
            http.send();
            if (http.status == 200){
                return false;
            }else{
                return true;
            }
        }

        const isAbsoluteUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

        function truncateString(str, num) {
            if (str.length <= num) {
              return str;
            }
            return str.slice(0, num) + '...';
          }

        return (
            <div className="modal fade" id="GeneralSearch">
                <div className="modal-dialog modal-dialog-centered modal-lg text-center">
                <div className="modal-content">
                    <button type="button" className="close closeONcorner" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                <div className="modal-body pt-0 px-0">
                    <div className='bg-graylight px-16 pt-16'>
                        <form className="Search Search__General">
                            <i className="icon-search"></i>
                            <input className="form-control mr-sm-2 mb-0" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={this.handleSearchChange} />
                        </form>

                        <div className="Tabs Tabs__Search mt-0 d-none d-md-flex">
                            <div className={this.state.curentTab == '1' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="1">
                                <i className="icon-folder_m_off"></i>
                                <span>Folders</span>
                            </div>
                            <div className={this.state.curentTab == '2' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="2">
                                <i className="icon-users"></i>
                                <span>Groups</span>
                            </div>
                            <div className={this.state.curentTab == '3' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="3">
                                <i className="icon-link"></i>
                                <span>Links</span>
                            </div>
                        </div>
                    </div>

                    <div className="Tabs-content">
                        <div id="Folders" className={this.state.curentTab == '1' ? "tab-content activ":"tab-content"}>

                            {this.state.filter.length > 0 ?
                                <FoldersView sort='search' SearchFilter={this.state.filter} />
                                :<p className='w-100 text-center mb-0 pt-15 pb-10'>There are no searched items yet.</p>
                            }

                        </div>

                        <div id="Groups" className={this.state.curentTab == '2' ? "tab-content activ":"tab-content"}>

                            {this.state.filter.length > 0 ?
                                <GroupsView sort='search' SearchFilter={this.state.filter} />
                                :<p className='w-100 text-center mb-0 pt-15 pb-10'>There are no searched items yet.</p>
                            }

                        </div>

                        <div id="Links" className={this.state.curentTab == '3' ? "tab-content activ":"tab-content"}>

                            <div className="Folders">
                              {LinksfilteredData.length > 0 ?
                                <Route>
                                    {LinksfilteredData.map((link, index) => (
                                        <SortedViewList key={index} link={link} />
                                    ))}
                                </Route>
                                :<p className='w-100 text-center mb-0 pt-15 pb-10'>There are no searched items yet.</p>
                              }
                            </div>

                        </div>


                    </div>


                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default GeneralSearch;