import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';
import FoldersView from '../folders/View';
import GroupsView from '../groups/View';

import { LinkAPI } from '../../utils';

class SortLink extends Component {

    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         openDeleteModal: this.props.link_id
    //     }
    // }

    constructor(props) {
        super(props);
    
        this.state = {
            filter: "",
            LinkId: '',
            FolderId: '',
            GroupId: '',
            SectionId: '',
            GroupSectionId: '',
            link_name: '',
            sessid: this.props.sessid,
            curentTab: 1,
        }
        
    }

    
    handleGetFolderId = FolderId => {
        this.setState({ FolderId })
    }
    
    handleGetGroupId = GroupId => {
        this.setState({ GroupId })
    }

    handleGetSectionId = SectionId => {
        this.setState({ SectionId })
    }

    handleGetGroupSectionId = GroupSectionId => {
        this.setState({ GroupSectionId })
    }

    handleSearchChange = event => {
        this.setState({ filter: event.target.value });
    };



    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        if(splitLocation[1] == 'dashboard'){
            this.setState({
                curentTab: 1,
            });
        }else if(splitLocation[1] == 'groups'){
            this.setState({
                curentTab: 2,
            });
        }
    }


    async componentDidUpdate(prevProps){
        var sessid  = this.props.LinkID;
        if(sessid !== this.state.sessid) {
            // console.log(this.props.LinkID);
            if(this.props.LinkID){
                await fetch(LinkAPI+this.props.LinkID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        link_name: data.name,
                        sessid: this.props.LinkID,
                    });
                });
            }
        }
    }

    render(props) {
        // console.log(this.props.LinkID);
        // console.log(this.state);

        var IDLink = this.props.LinkID;
        var IDFolder = this.state.FolderId;
        var IDGroup = this.state.GroupId;
        var SectionId = this.state.SectionId;
        var GroupSectionId = this.state.GroupSectionId;
        var link_name = this.state.link_name;


        if(this.state.curentTab == '1'){
            IDGroup = null
        }else if(this.state.curentTab == '2'){
            IDFolder = null
            SectionId = GroupSectionId
        }
        
        
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        async function SaveSortLink(e){
            e.preventDefault();
            
            var Linkid = IDLink;
            var Folderid = IDFolder;
            var LinkName = link_name;

            if(SectionId == ''){
                SectionId = null
            }

                const links = {
                    folder: Folderid,
                    group: IDGroup,
                    section: SectionId,
                };

            await fetch(LinkAPI+Linkid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(links)
            })
            .then(res => res.json())
            .then(data => {
                // this.setState({Folder_data: data});
                if (data) {
                    // window.location.replace('/dashboard');
                    if(IDGroup == null){
                        if(SectionId == null){
                            window.location.replace('/dashboard/folder/'+Folderid);
                        }else{
                            window.location.replace('/dashboard/folder/'+Folderid+'/section/'+SectionId);
                        }
                    }else{
                        if(SectionId == null){
                            window.location.replace('/groups/group/'+IDGroup);
                        }else{
                            window.location.replace('/groups/group/'+IDGroup+'/section/'+SectionId);
                        }
                    }
                    // console.log(data);
                }
            });
            if(LinkName == 'loading...'){
                setTimeout(() => {
                    window.location.replace(currentLocation);
                }, 1000);
            }
        }
        
        this.tiggerTabsOnClick = (e) => {
            this.setState({
                curentTab: e.currentTarget.getAttribute("curenttab"),
            });
        }


        return (
            <div className="modal fade" id="SortLink">
                <div className="modal-dialog modal-dialog-centered modal-lg text-center">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{typeof splitLocation[2] != "undefined" ? <i className="icon-move"></i>:<i className="icon-plus"></i> } {typeof splitLocation[2] != "undefined" ? 'Move link':'Sort link'} <b></b></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                </div>
                <div className="modal-body pt-0 px-0">
                    <div className="Tabs mt-0 d-none d-md-flex">
                        <div className={this.state.curentTab == '1' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="1">
                            <i className="icon-folder"></i>
                            <span>Folders</span>
                        </div>
                        <div className={this.state.curentTab == '2' ? "Titem activ":"Titem"} onClick={this.tiggerTabsOnClick} curenttab="2">
                            <i className="icon-users"></i>
                            <span>Groups</span>
                        </div>
                    </div>

                    <div className="Tabs-content">
                        <div id="Folders" className={this.state.curentTab == '1' ? "tab-content activ":"tab-content"}>
                            <form className="Search">
                                <i className="icon-search"></i>
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={this.handleSearchChange} />
                            </form>


                            <div className="Folders">

                                <FoldersView sort='yes' SearchFilter={this.state.filter} GetFolderId={this.handleGetFolderId} GetSectionId={this.handleGetSectionId} />

                            </div>
                            
                        </div>

                        <div id="Groups" className={this.state.curentTab == '2' ? "tab-content activ":"tab-content"}>
                            <form className="Search">
                                <i className="icon-search"></i>
                                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={this.handleSearchChange} />
                            </form>


                            <div className="Folders">

                                <GroupsView sort='yes' SearchFilter={this.state.filter} GetGroupId={this.handleGetGroupId} GetGroupSectionId={this.handleGetGroupSectionId} />

                            </div>

                        </div>
                    </div>


                </div>
                <div className="modal-footer d-flex">
                    {/* <button type="button" className="Button mr-auto pl-5 pr-25" data-dismiss="modal"><i className="icon-group"></i> Create new folder</button> */}
                    <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                    <button type="button" onClick={SaveSortLink} className={IDFolder == '' || IDGroup == '' ? "Button Button--disable":"Button Button--bg"}> {typeof splitLocation[2] != "undefined" ? 'Move link':'Sort link'}</button>
                </div>
                </div>
            </div>
            </div>
        );
    }
}

export default SortLink;