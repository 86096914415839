import React, { useState, useEffect,useCallback } from 'react';
import { Route } from 'react-router';

import { GroupUsersListAPI, ProfileInfoAPI } from '../../utils';

const GrupTransferUsers = (props, { GetGroupUserId }) => {

    const [groupMember, setgroupMember] = useState([]);
    const [SelectedUser, setSelectedUser] = useState('');

          
    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split("/");

    useEffect(() => {
        
        if(splitLocation[3]){
            fetch(GroupUsersListAPI+splitLocation[3], {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {

                var GroupRoleAdmins = data;

                    fetch(ProfileInfoAPI, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Token ${localStorage.getItem('token')}`
                        }
                      })
                    .then(res => res.json())
                    .then(data => {

                        GroupRoleAdmins.filter(Role => Role.group_role.name == 'admin' && Role.user.id != data.id).map((group_role, index)=>{
                            // setgroupMember(groupMember.concat([group_role]));
                            setgroupMember(groupMember => [...groupMember, group_role]);
                        });

                    });

            });
        }
    }, []);

    const handleGetUserId = useCallback(e => {
        props.GetGroupUserId(e.currentTarget.getAttribute("uid"));
        setSelectedUser(e.currentTarget.getAttribute("uid"));
    }, [props.GetGroupUserId]);
        // console.log(groupMember);

        return (
            <div className="modal fade" id="GrupTransferUsers">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-users"></i> Group control transfer<b></b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body pt-0 px-0">
                                        <h3 className="bg-graylight px-16 py-16 text-left mb-0"><span className='RoleT'>Admins</span>: <span className="Mnumber text-secondary">{groupMember.length} users</span></h3>
                                            <div className="Members">
                                                <div className="MMenu MMenu--members">
                                                    <ul>
                                                        {groupMember.map((member, index) => (
                                                            <li key={member.id} onClick={handleGetUserId} mbi={member.id} uid={member.id} className={member.id == SelectedUser ? "d-block bg-bluelight":"d-block"}>
                                                                <div className="MMenu_Item">
                                                                    <div className="d-flex align-items-center">
                                                                        <img className="GRimg img-fluid mr-8" src="/assets/img/img.png" alt="Logo" />
                                                                        {member.user.first_name ? 
                                                                            <div>{member.user.first_name+' '+member.user.last_name}</div>:
                                                                            <div>{member.user.username}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="text-secondary d-flex align-items-center ml-auto"><span>Permissions: <i>{member.user_permisions.length}</i></span></div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        
                                                    </ul>
                                                </div>
                                            </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button Button--bg" data-dismiss="modal">Cancel</button>
                                {SelectedUser == '' ?
                                    <button type='submit' className="Button Button--disable">Transfer</button>
                                    :<button type='submit' className="Button Button--border-red" data-toggle="modal" data-target="#GrupTransfer" data-dismiss="modal">Transfer</button>
                                }
                            </div>
                    </div>
                </div>
            </div>
        );
}

export default GrupTransferUsers;