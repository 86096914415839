import React, { Component, useEffect, useState, useCallback } from 'react';
import $, { isEmptyObject } from 'jquery'; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import { GroupsListAPI, SectionsListAPI, LinksListAPI, GroupSectionsListAPI } from '../../utils';

const GroupsView = (props, {GetGroupId, GetGroupSectionId}) => {

        const [GroupsData, setGroupsData] = useState([]);

        useEffect( async () => {
            if (localStorage.getItem('token') === null) {
            window.location.replace('/login');
            } else {
            await fetch(GroupsListAPI, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(data => {
                setGroupsData(data);
                //   setloadingLinks(false);
                });
            }
        }, []);

        // const location = useLocation();
        // const { pathname } = location;
        // const splitLocation = pathname.split("/");

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // console.log(splitLocation);
        
        // function SortinFolder(e){
        //     e.preventDefault();
        //     // var rez = e.target.getAttribute("folder");
        //     var rez = e.currentTarget.getAttribute("folder");
        //     console.log(rez);
        //     $("#SortLink .modal-footer .Button").removeClass('Button--disable');
        // }

        // $(".FSrt").click(function(){
        //     var folder_id = $(this).attr('folder');
        //     // $('.modal-title b').text(link_id);
        //     // $("#SortLink").modal('show');
        //     console.log(folder_id);
        // });

        // var vio = props.sort;
        const [SelectedGroup, setSelectedGroup] = useState('');
        const [SelectedSection, setSelectedSection] = useState('');

        const [SectionData, setSectionData] = useState([]);

        useEffect( async () => {
            // console.log(splitLocation);
            
            if(SelectedGroup){

                await fetch(GroupSectionsListAPI+SelectedGroup+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    setSectionData(data[0]);
                    // console.log(data[0]);
                });

            }


        }, [SelectedGroup]);


        const [LinksData, setLinksData] = useState([]);
        //   const [LoadingLinks, setloadingLinks] = useState(true);
        
          useEffect( async () => {
            if (localStorage.getItem('token') === null) {
              window.location.replace('/login');
            } else {
                await fetch(LinksListAPI, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  setLinksData(data);
                //   setloadingLinks(false);
                });
            }
          }, []);

        const handleGetGroupId = useCallback(e => {
            props.GetGroupId(e.currentTarget.getAttribute("group"))
            // $("#SortLink .modal-footer .Button").removeClass('Button--disable');
            setSelectedGroup(e.currentTarget.getAttribute("group"));
        }, [GetGroupId])

        const handleGetGroupSectionId = useCallback(e => {
            props.GetGroupSectionId(e.currentTarget.getAttribute("section"))
            // $("#SortLink .modal-footer .Button").removeClass('Button--disable');
            setSelectedSection(e.currentTarget.getAttribute("section"));
        }, [GetGroupSectionId])



        var filteredData = [];

        if(props.sort == 'yes' || props.sort == 'search'){
            const filter = props.SearchFilter;
            const data = GroupsData;
            // const { data } = state;
            // console.log(data);
            // const lowercasedFilter = filter.toLowerCase();
            const lowercasedFilter = filter;
            var filteredData = data.filter(item => {
            //   return Object.keys(item).some(key =>
            //     item[key].toLowerCase().includes(lowercasedFilter)
            //   );
                // return Object.keys(item).some(key =>
                //     typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
                // );
                return item.name.toLowerCase().includes(lowercasedFilter)
            });
        }else{
            var filteredData = GroupsData;
        }
        
        return (
            <div>

                {filteredData.sort((a, b) => a.name.localeCompare(b.name)).map((group, index) => (
                    // onClick={() => {window.location.href="/dashboard/group/"+group.id}}
                    // <Link key={index} to={`/dashboard/group/${group.id}`} className="group d-flex"></Link>
                    props.sort == 'yes' ?
                        (
                            <Route key={index}>
                            <div onClick={handleGetGroupId} group={group.id} className={SelectedGroup == group.id ? "Folder d-flex activ" : "Folder d-flex"}>
                                {group.image ? 
                                    <div className="GRimg embed-responsive embed-responsive-1by1"><img className="embed-responsive-item cover_img img-fluid" src={group.image} alt="Logo" /></div>:
                                    <div className="GRimg embed-responsive embed-responsive-1by1"><img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" /></div>
                                }
                                <div>
                                    <h4 className="Folder__name">{group.name}</h4>
                                    <div className="d-flex">
                                        <span className="Folder__links">{SectionData.filter(SectionData => SectionData.group == group.id).length} sections</span>
                                        <span className="Folder__links">{LinksData.filter(LinksData => LinksData.group == group.id).length} links</span>
                                    </div>
                                </div>
                            </div>
                            {SelectedGroup == group.id ? 
                                (
                                    SectionData.filter(SectionsData => SectionsData.group == group.id).map((section, index_id) => (
                                        <div key={index_id} onClick={handleGetGroupSectionId} section={section.id} className={SelectedSection == section.id ? "Section_sort d-flex activ" : "Section_sort d-flex"}>
                                            <i className="icon-section"></i>
                                            <div>
                                                <h4 className="Section_sort__name">{section.name}</h4>
                                            </div>
                                        </div> 
                                    ))
                                ): null
                            }
                            </Route>
                        ):
                        (
                            <a key={index} href={`/groups/group/${group.id}`} className={splitLocation[3] == group.id ? "Folder d-flex activ" : "Folder d-flex"}>
                               {group.image ? 
                                <div className="GRimg embed-responsive embed-responsive-1by1"><img className="embed-responsive-item cover_img img-fluid" src={group.image} alt="Logo" /></div>:
                                <div className="GRimg embed-responsive embed-responsive-1by1"><img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" /></div>
                                }
                                <div>
                                    <h4 className="Folder__name">{group.name}</h4>
                                    <div className="d-flex">
                                        <span className="Folder__links">{SectionData.filter(SectionData => SectionData.group == group.id).length} sections</span>
                                        <span className="Folder__links">{LinksData.filter(LinksData => LinksData.group == group.id).length} links</span>
                                    </div>
                                </div>
                            </a>
                        )
                    
                ))} 

            </div>
        );
}

export default GroupsView;