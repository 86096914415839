import React, { useState, useEffect } from 'react';

import { registerAPI } from '../../utils';
import HomeContent from '../../components/HomeContent';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
const SignUp = () => {


    // const handleLogin = () => {
    //     // login();
    //     props.history.push('/dashboard');
    // }

    const [email, setEmail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ShowError, setShowError] = useState('');
    const [SuccessRegistration, setSuccessRegistration] = useState(false);
  
    useEffect(() => {
      if (localStorage.getItem('token') !== null) {
        window.location.replace('/dashboard');
      } else {
        setLoading(false);
      }
    }, []);
  
    const onSubmit = e => {
      e.preventDefault();
  
      const user = {
        email: email,
        password1: password1,
        password2: password2
      };
      
      fetch(registerAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then(res => res.json())
        .then(data => {
          console.log(data);
          if (data.key) {
            localStorage.clear();
            localStorage.setItem('token', data.key);
            window.location.replace('/dashboard');
          } else {
            // setEmail('');
            setPassword1('');
            setPassword2('');
            localStorage.clear();
            setErrors(true);
            if(Array.isArray(data.password1) ){
              setShowError(data.password1);
            }else if(Array.isArray(data.password1) ){
              setShowError(data.detail);
            }else if(Array.isArray(data.email) ){
              setEmail('');
              setShowError(data.email[0]);
            }else if(Array.isArray(data.non_field_errors) ){
              setShowError(data.non_field_errors[0]);
            }else{
              setShowError(data.detail);
              if(data.detail == "Verification e-mail sent."){
                setSuccessRegistration(true);
              }
            }
          }
        });
    };

    function GoToHome(){
      window.location.replace('/login')
    }

    return (
      <Route>
      
      {loading === false && (
        <Route>

          <HomeContent/>
          <div className='SideH activ'>
            <div className="Sidebar h-md-100 Sidebar--scroll px-25">
              <button className="close d-md-block"><i className="icon-close"></i></button>
                      <h3 className="Conect-title mt-30 mb-25 text-center">{loading === false && 'Sign up'}</h3>

                      <form onSubmit={onSubmit}>
                      <div className="Forms Forms--blue">
                          {/* <div className="form-group">
                              <input type="text" name="name" className="form-control clearable" id="email" placeholder="Name">
                              <label for="link">Name</label>
                          </div> */}
                          {errors === true && <span className="d-block text-center mb-10 text-red">{ShowError}</span>}
                          <div className="form-group">
                              <input type="email" name="email" className="form-control clearable" id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required />{' '}
                              <label htmlFor="link">Email</label>
                          </div>
                          <div className="form-group">
                              <input type="password" name="name" className="form-control passv" id="password" placeholder="Password" value={password1} onChange={e => setPassword1(e.target.value)} required />{' '}
                              <label htmlFor="link">Password</label>
                              <div className="pview"><i className="icon-visible_true"></i></div>
                          </div>
                          <div className="form-group">
                              <input type="password" name="name" className="form-control passv" id="password2" placeholder="Password" value={password2} onChange={e => setPassword2(e.target.value)} required />{' '}
                              <label htmlFor="link">Confirm password</label>
                              <div className="pview"><i className="icon-visible_true"></i></div>
                          </div>
                          <div className="form-group form-check">
                              <input type="checkbox" name="name" id="agree" required />
                              <label htmlFor="agree">I agree to the <a href="">Terms and Conditions</a> and <a href="">Privacy Policy</a></label>
                          </div>
                      </div>
                      <button type='submit' className="Button Button--blue w-100">Sign up</button>
                      </form>

                      <div className="Conect-question mt-auto text-center mb-10">Already have an account?</div>
                      <Link to="/login" className="Button Button--blue-border mb-20">Sign in</Link>
            </div>
          </div>
          {SuccessRegistration === true &&
            <Route>
              <div className="modal in" id="SuccessRegistration" tabIndex="-1" role="dialog" style={{display:'block'}}>
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                            <div className="modal-body px-25 py-25">
                                <h3>You have successfully registered! <br/><br/> <b>To access your account, <span className='text-secondary'>check the confirmation email sent to your inbox.</span></b></h3>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" onClick={GoToHome}>OK</button>
                            </div>
                    </div>
                </div>
              </div>
              <div className="modal-backdrop fade show"></div>
            </Route>
          }
        </Route>
      )}
    </Route>
    );
};

export default SignUp;