import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import { LinksListAPI, LinkGetByAPI } from '../../utils';
class SectionsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LinksData: []
            // folderID: 1,
        }
    }
    
    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        if(splitLocation[2] == 'group'){
            fetch(LinkGetByAPI+'?group='+splitLocation[3], {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({LinksData: data});
            });
        }else{
            fetch(LinksListAPI, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({LinksData: data});
            });
        }
    }

    render() {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        return (
            <Route>
                {this.props.Group_user_role !== 'member' && this.props.Group_user_role !== 'author' && this.props.section.status !== false || this.props.section.status == true || this.props.Group_user_role == 'admin'  || this.props.Group_user_role == 'private_author' || splitLocation[2] == 'folder' ?
                    <a className="col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0" id={this.props.section.id} onDrop={this.props.onDrop} onDragOver={this.props.onDragOver} onDragEnter={this.props.onDragEnter} onDragLeave={this.props.onDragLeave} href={splitLocation[2] == 'folder' ? "/dashboard/folder/"+this.props.FolderID+"/section/"+this.props.section.id+"":"/groups/group/"+this.props.FolderID+"/section/"+this.props.section.id+""}>
                        <div className="Section" id={this.props.section.id}>
                            <div className="Section__bg d-flex align-items-center" id={this.props.section.id}>
                                <i className="icon-section"></i>
                                <div className="Section__Options" id={this.props.section.id}>
                                    <h3 className="title">{this.props.section.name}</h3>
                                    <span className="nr_links">{this.state.LinksData.filter(LinksData => LinksData.section == this.props.section.id).length} links</span>
                                </div>
                            </div>
                        </div>
                    </a>:null
                }
            </Route>
        );
    }
}

export default SectionsView;