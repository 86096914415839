import React, { Component } from 'react';
import { logout, isLogin } from '../utils';
import { Header } from '../components/Header';
import BlogContent from '../components/BlogContent';
import SignIn  from './auth/SignIn';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";



class Blog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: isLogin()
        }
    }

    handleLogout = () => {
        logout();
        this.setState({
            isLogin: false
        })
    }
   

    render() {

        var currentLocation = this.props.location.pathname;
        return (
            <Route>
                <BlogContent/>
                <SignIn/>
            </Route>
        );
    }
}

export default Blog;