import React, { useState } from 'react';

import { FoldersCreateAPI } from '../../utils';

const FoldersCreate = (props) => {

            const [folder, setFolder] = useState('');
            const [errors, setErrors] = useState(false);
            const [loading, setLoading] = useState(true);
          
          
            const onAddFolder = async e => {
              e.preventDefault();
          
              const folders = {
                name: folder,
              };
          
              await fetch(FoldersCreateAPI, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(folders)
              })
                .then(res => res.json())
                .then(data => {
                  if (data) {
                    window.location.replace('/dashboard/folder/'+data.id);
                  }
                });
              };

        return (
            <div className="modal fade" id="CreatingFolder">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={onAddFolder}>
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-plus"></i> Creating new folder</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="Forms">
                                <div className="form-group">
                                    <input type="text" name="folder_name" className="form-control clearable" value={folder} id="folder_name" placeholder="Folder name" required onChange={e => setFolder(e.target.value)} />
                                    <label htmlFor="folder_name">Folder name</label>
                                </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                                <button type='submit' className="Button Button--bg">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
}

export default FoldersCreate;