import React, { Component, useState, useEffect, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { logout } from '../utils';

import MobileMenu from './dashboard-elements/MobileMenu';
import TopBar from './dashboard-elements/TopBar';
import Sidebar from './dashboard-elements/Sidebar';
import MenuSidebarProfil from './profile/MenuSidebar';

import LinksView from './links/View';
import LinkInfo from './links/LinkInfo';
import LinkStatistics from './links/LinkStatistics';
import SortLink from './links/SortLink';
import SortedView from './links/SortedView';
import AddLink from './links/Add';
import GroupsCreate from './groups/Create';

import { ProfileInfoAPI, LinksListAPI } from '../utils';

const Groups = (props) => {
  const [userEmail, setUserEmail] = useState('');
  const [UserPhoto, setUserPhoto] = useState('');
  const [UserUsername, setUserUsername] = useState('');
  const [UserData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        logout();
        props.history.push('/dashboard');
    }

  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      window.location.replace('/login');
    } else {
      fetch(ProfileInfoAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(data => {
          setUserEmail(data.email);
          setUserPhoto(data.photo);
          setUserUsername(data.username,data.first_name,data.last_name);
          setUserData(data);
          setLoading(false);
        });
    }
  }, []);

  const [LinksData, setLinksData] = useState([]);
//   const [LoadingLinks, setloadingLinks] = useState(true);


const [currentCount, setCount] = useState(10);
const timer = () => {
      fetch(LinksListAPI, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(data => {
          data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
          setLinksData(data);
        //   setloadingLinks(false);
        if(currentCount >= 5){
          setCount(currentCount - 1);
        }
      });
}

useEffect(
    () => {
      // console.log(currentCount);
        if (currentCount == 10) {
            // return;
            const id = setInterval(timer, 1);
            return () => clearInterval(id);
        }else{
          const id = setInterval(timer, 1000);
          return () => clearInterval(id);
        }
    },
    [currentCount]
);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetch(LinksListAPI, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Token ${localStorage.getItem('token')}`
  //       }
  //     })
  //       .then(res => res.json())
  //       .then(data => {
  //         data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
  //         setLinksData(data);
  //       //   setloadingLinks(false);
  //       });
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

//   const vio = [LinksData];

var text_top = 'Unsorted';
var icon_top = 'icon-unsorted';


const [LinkID, setGetId] = useState('')

  // console.log(LinkID);

  document.body.classList.add('GR');

  const [Search_term, setSearch_term] = useState('');
  var Search_term_notfound = '';

  if(Search_term != ''){
    Search_term_notfound = '';
    const filter = Search_term;
    const data = LinksData;
    const lowercasedFilter = filter;
    var filteredData = data.filter(item => {
        return item.name.toLowerCase().includes(lowercasedFilter)
    });
    if (filteredData.length === 0) {
      Search_term_notfound = 'No matches found. Try another word.';
    }
  }else{
    var filteredData = LinksData;
  }

  return (
          <Route>
            <MobileMenu/>

            
            <div className="Dashboard d-flex">

                <MenuSidebarProfil photo={UserPhoto} username={UserData} />

                <Sidebar photo={UserPhoto} username={UserData} Unsorted={LinksData.filter(LinksData => LinksData.folder == null && LinksData.group == null).length} />

                

                <div className="Content d-none d-md-block">
                    <div className="container-fluid">

                      <TopBar icon={icon_top} text={text_top} Search_term={Search_term} onSearchTermChange={setSearch_term} />


                        <div className="row">

                            <div className="Content__Zone col-12">

                                <div className="Links row">

                                {/* {apiData.map((link) => <LinksView link={link} />)} */}

                                {/* {LinksData.length>0 && LinksData.map(link => <LinksView link={link} />)} */}

                                <Router>
                                  <Switch>
                                    
                                        <Route path="/groups" children={ 
                                          <Router>
                                            {/* input.sort((a, b) => a.timestamp.localeCompare(b.timestamp)); */}
                                          {/* {LinksData.filter(LinksData => LinksData.folder_id !== "" && LinksData.folder_id !== null).map((link, index) => ( */}
                                          {filteredData.filter(filteredData => filteredData.folder == null && filteredData.group == null).map((link, index) => (
                                            <LinksView key={index} link={link} onGetId={setGetId} />
                                          ))}
                                          {Search_term_notfound != '' ?
                                          <p className='w-100 py-15 text-center'>{Search_term_notfound}</p>:null}
                                          </Router>
                                        } />

                                        {/* <Route path="/dashboard/folder/:id" children={ 
                                          <Router>
                                          {LinksData.filter(LinksData => LinksData.folder_id !== "" && LinksData.folder_id !== null).map((link, index) => (
                                          // {LinksData.filter(LinksData => LinksData.folder_id == null).map((link, index) => (
                                            <SortedView key={index} link={link} />
                                          ))}
                                          </Router>
                                        } /> */}

                                  </Switch>
                                </Router>
                                    


                                    <button className="Add-link" data-toggle="modal" data-target="#AddLink"><i className="icon-plus"></i> <span>Add new link</span></button>
                                </div>
                                
                            </div>

                        </div>

                    </div>
                </div>

                <AddLink />

                <GroupsCreate />

                <SortLink LinkID={LinkID} />

                <LinkInfo LinkID={LinkID} />
                <LinkStatistics LinkID={LinkID} />

            </div>
          </Route>
  );
};

export default Groups;