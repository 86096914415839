import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";
  
  import Footer from './Footer';

const HomeContent = () => {

    return (
    <Route>
    <header className="Header" id="navbar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-8 d-flex align-items-center">
                    <a className="Header__Logo" href=""><img className="img-fluid" src="/assets/img/logo.svg" alt="Logo" /></a>
                    <div className="Header__Nav">
                        <ul>
                            <li><a href="">Save links</a></li>
                            <li><a href="">Folders and Sections</a></li>
                            <li><a href="">Groups and Members</a></li>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-4 col-md-4 d-flex align-items-center justify-content-end">
                    <ul className="Header__Language d-lg-flex">
                        <li><a className="activ" href="">EN</a></li>
                        <li><a href="">RO</a></li>
                    </ul>
                    <button className="Header__Button mr-10 mr-md-0 d-lg-flex Try">Login / Register</button>

                    <button className="Header__toggle d-lg-none p-0">
						<span></span>
						<span></span>
						<span></span>
					</button>
                </div>
            </div>
        </div>
    </header>

    <section className="Hero">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 mb-25 mb-md-0">
                    <h1>The ultimate bookmark manager designed to <span>save, shorten and organize links!</span></h1>
                    <p>Your virtual world is made up of all the information you find online. And that information is enormous. Giallaib is a simple and convenient solution to save and organize links so that you can always return to the information you value.</p>
                    <button className="Btn Try">Create an account</button>
                </div>
                <div className="col-md-6">
                    <img className="img-fluid" src="/assets/img/hero_img.png" alt="Logo" />
                </div>
            </div>
        </div>
    </section>

    <section className="Slider" id="Slider1">
        <div className="container">
            <div className="row">
                <div className="col-md-5 mb-30 mb-md-0">
                    <h2 className="title">Save links</h2>
                    <p className="description">Saving links on the Giallaib platform - the ultimate way of organizing and managing links</p>
                    <div className="Slider__List">
                        <div className="item active" img="/assets/img/slider.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Quick link saver from the Browser <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>If you use a browser such as Chrome, Firefox, Opera, install the Giallaib extension to be able to  save your links without being connected to Giallaib in another tab.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider1.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>“Unsorted links” section <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>After saving links with the Giallaib extension, you will find them all on your profile page, under the "Unsorted links”  section. Using the "Sort" button, you can organize every link into the desired folder or group.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider2.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>"Statistics" button <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>By accessing the "Statistics" button on any shared link, you will see the saved link’s accessing details. Statistics can be useful to see how many times your saved and shared link was accessed.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider3.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>"More" button <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>By accessing the "More" button on any link, you will find a wide range of links management tools, such as: URL shortener, the link’s description, QR code, automatic delete, etc.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <img className="Slider__Img img-fluid" src="/assets/img/slider.png" alt="Logo" />
                </div>
            </div>
        </div>
    </section>


    <section className="Prezents">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/save.svg" alt="Logo" />
                        <h2>Save</h2>
                        <p>Save links with just one click</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/organize.svg" alt="Logo" />
                        <h2>Organize</h2>
                        <p>Organize links from your favorite sites, create your own "Google", your own logic</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/search.svg" alt="Logo" />
                        <h2>Find</h2>
                        <p>With the Giallaib smart search engine you can easily find all the important links.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="Slider Slider--model2" id="Slider2">
        <div className="container">
            <div className="row">
                <div className="col-md-5 order-md-2 mb-30 mb-md-0">
                    <h2 className="title">Folders and Sections</h2>
                    <p className="description">From now on, you can sort links into Folders and Sections</p>
                    <div className="Slider__List">
                        <div className="item active" img="/assets/img/slider.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Create Folders <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Just as you sort your personal data into folders on your phone or PC, you can also organize links into folders on the Giallaib platform.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider1.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Create Sections <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Inside the folders you can create sections, in order to more efficiently organize and manage your personal links.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider2.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Transfer the File to Groups <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>If you have a well-organized folder you want your family, friends, team, or someone else to have access to, go to File Settings - "Transfer Folder to Group", and then add users and set their permissions.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider3.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Moving links <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>COMING SOON… If you have saved a link into a folder or section and then want to move it to another section, you can easily do so without deleting and resaving the link. It is as easy as transferring a file to your computer from one folder to another.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 order-md-1">
                    <img className="Slider__Img img-fluid" src="/assets/img/slider.png" alt="Logo" />
                </div>
            </div>
        </div>
    </section>

    
    <section className="Prezents">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/save.svg" alt="Logo" />
                        <h2>Save</h2>
                        <p>Save, then manage your own link library at a click away.</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/organize.svg" alt="Logo" />
                        <h2>Organize</h2>
                        <p>Organize your links from your favorite sites, create your own "Google", your own logic</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/search.svg" alt="Logo" />
                        <h2>Find</h2>
                        <p>The Giallaib search engine quickly finds every important link for you</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="Slider" id="Slider3">
        <div className="container">
            <div className="row">
                <div className="col-md-5 mb-30 mb-md-0">
                    <h2 className="title">Groups and Members</h2>
                    <p className="description">Create Shared Groups, where you can manage important bookmarks with your family, friends or team</p>
                    <div className="Slider__List">
                        <div className="item active" img="/assets/img/slider.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Create Groups <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Easily create a shared group in order to collaborate with others to save and manage links you are all interested in.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider1.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Public Groups <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Are you an expert in your niche and you want to help others with useful information in the online space? If so, then create a public group and become a real help for your community. They will surely appreciate your shared information.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider2.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Private groups <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Are you a beautiful family, a productive team, or good friends? Would you like to help each other with some useful information you found online? If so, the private groups offered by Giallaib, where you can manage your favorite bookmarks, are exactly what you need. Create a private group today, and tomorrow its members will thank you.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                        <div className="item" img="/assets/img/slider3.png">
                            <div className="progress-bar"><div className="completed"></div></div>
                            <h3>Change of the Owner <i className="icon-arrow-down"></i></h3>
                            <div className="content">
                                <p>Whether you are the owner of a public or private group, you can opt out at any time by offering to someone else the role of "Group Owner". This way, you do not need to worry if your interests change. You can offer the management role to another group member.</p>
                                {/* <a href="">Подробнее о перемещении ссылок <i className="icon-big-arrow-right"></i></a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-7">
                    <img className="Slider__Img img-fluid" src="/assets/img/slider.png" alt="Logo" />
                </div>
            </div>
        </div>
    </section>


    <section className="Prezents">
        <div className="container">
            <div className="row">
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/save.svg" alt="Logo" />
                        <h2>Save</h2>
                        <p>Save, then manage your own links library at a click away</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/organize.svg" alt="Logo" />
                        <h2>Organize</h2>
                        <p>Organize your links from your favorite sites, create your own "Google", your own logic</p>
                    </div>
                </div>
                <div className="col-md-4 mb-15 mb-md-0">
                    <div className="Prezents__Item">
                        <img className="img-fluid" src="/assets/img/search.svg" alt="Logo" />
                        <h2>Find</h2>
                        <p>The Giallaib search engine quickly finds every important link for you</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="Concluzion">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 text-center position-relative">
                    <img className="img-fluid" src="/assets/img/logo.svg" alt="Logo" />
                    <h3>Try Giallaib</h3>
                    <p>Free to use bookmarks manager.<br/>Add your entire Team as your needs grow.</p>
                    <button className="Btn Btn--primary Try">Get to work</button>
                </div>
            </div>
        </div>
    </section>

        <Footer/>

    </Route>
    );
};

export default HomeContent;