import React, { Component } from 'react';
import { Route } from 'react-router';

import { GroupUsersListAPI, SiteURL, RolesListAPI, ProfileInfoAPI } from '../../utils';
import AddUsers from './AddUsers';
import GroupUser from './GroupUser';
import GroupRole from './GroupRole';

class GroupMembers extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            filter: "",
            Group_users: [],
            Roles: [],
            Group_user_group_role: [],
            UserPhoto: '',
            UserId: '',
            current_role: '',
            invite_and_delete_users: false,
            tab_selected: '',
            tab_selected_title: '',
            tab_selected_description: '',
            tab_selected_mnumber: '',
            UserID_open: '',
        }
                
    }

    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[3]){
            fetch(GroupUsersListAPI+splitLocation[3], {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_users: data,
                    });
                    var Group_usersGR = data;

                    fetch(ProfileInfoAPI, {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Token ${localStorage.getItem('token')}`
                        }
                      })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({
                            UserPhoto: data.photo,
                            UserId: data.id,
                        });
                        var GUserId = data.id;

                        var vaz = Group_usersGR.filter(Group_users => Group_users.user.id == GUserId);
                        vaz.map((d)=>{
                            this.setState({
                                current_role: d.group_role.name,
                                tab_selected: d.group_role.name,
                                tab_selected_title: d.group_role.title,
                                tab_selected_description: d.group_role.description,
                            });
                            d.user_permisions.filter(Perm => Perm.permission.id == '3').map((v)=>{
                                this.setState({
                                    invite_and_delete_users: true,
                                });
                            });
                        });

                    });

            });
        }


        
        fetch(RolesListAPI, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
                this.setState({
                    Roles: data,
                });
        });

        // setTimeout(() => {

        // }, 1000);

    }

    handleTabChange = event => {
        // console.log(event.currentTarget.getAttribute("role"));
        this.setState({ 
            tab_selected: event.currentTarget.getAttribute("role"),
            tab_selected_title: event.currentTarget.getAttribute("title"),
            tab_selected_description: event.currentTarget.getAttribute("description"),
            tab_selected_mnumber: event.currentTarget.getAttribute("mnumber"),
        });
    }

    handleInputChange = event => {
        this.setState({UserID_open: event.currentTarget.getAttribute("mbi")});
    }

    handleSearchChange = event => {
        this.setState({ filter: event.target.value });
    };

    handleUpdate = event => {
        this.setState({ 
            Group_user_group_role: event.group_role,
        })
        this.HandleUpdateList();
    }
    
    HandleUpdateList = e => {
        // e.preventDefault();
        
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        // if(e == true){
            if(splitLocation[3]){
                fetch(GroupUsersListAPI+splitLocation[3], {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                        this.setState({
                            Group_users: data,
                        });
                });
            }
        // }
    }
    
    render() {

        // const [Search_term, setSearch_term] = useState('')

        if(this.state.filter != ''){
            var filter = this.state.filter;
          var data = this.state.Group_users;
          var lowercasedFilter = filter;
          var filteredData = data.filter(item => { 
              return item.user.first_name.toLowerCase().includes(lowercasedFilter) || item.user.last_name.toLowerCase().includes(lowercasedFilter) || item.user.username.toLowerCase().includes(lowercasedFilter)
          });
        }else{
          var filteredData = this.state.Group_users;
        }

        // console.log('tab-sel-'+this.state.tab_selected);
        // console.log('User-curent-role-'+this.state.current_role);

        return (
            <Route>
                <div className="modal fade" id="GroupMembers">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-users"></i> User administration<b></b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body pt-0 px-0">
                            
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 px-0">
                                            <form className="Search">
                                                <i className="icon-search"></i>
                                                <input className="form-control mr-sm-2 mb-0" type="search" onChange={this.handleSearchChange} value={this.state.filter} placeholder="Search" aria-label="Search" />
                                            </form>
                                        </div>
                                        <div className="col-md-4 px-0 d-flex flex-column">

                                            <div className="MMenu MMenu--group">
                                                <ul>
                                                    {this.state.Roles.map((rol, index) => (
                                                        <li key={rol.id}>
                                                            <a onClick={this.handleTabChange} role={rol.name} className={rol.name + (this.state.tab_selected == rol.name ? ' active':'') } title={rol.title} description={rol.description} mnumber={this.state.Group_users.filter(Group_users => Group_users.group_role.name == rol.name ).length}>
                                                                <div className="d-flex align-items-center"><i className="icon-users"></i> <span>{rol.title}</span></div>
                                                                <div className="text-secondary d-flex align-items-center ml-auto"><span>{this.state.Group_users.filter(Group_users => Group_users.group_role.name == rol.name ).length}</span></div>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={this.props.Can_invite_and_delete_users == true ? "Buttons px-8 mt-auto":"Buttons px-8 mt-auto pt-25"}>
                                                {this.props.Can_invite_and_delete_users == true ?
                                                    <button type="button" className="Button AddU w-100 px-8 mb-8 mt-25" data-toggle="modal" data-target="#AddUsers"> <i className="icon-user-plus"></i> Add new user</button>:null
                                                }
                                                <button type="submit" className="Button w-100 px-8 mb-8 Button--bg"><i className="icon-copy"></i> Copy invitation link</button>
                                            </div>
                                        </div>
                                        <div className="col-md-8 px-0 py-10 border-left">
                                            <h3 className="px-16"><span className='RoleT'>{this.state.tab_selected_title}</span>: <span className="Mnumber text-secondary">{this.state.tab_selected_mnumber}</span></h3>
                                            <p className="px-16"><span className='RoleD'>{this.state.tab_selected_description}</span></p>

                                            <div className="Members">
                                                <div className="MMenu MMenu--members">
                                                    <ul>
                                                        {filteredData.map((member, index) => (
                                                            <li key={member.id} mbi={member.id} className={member.group_role.name  + (this.state.tab_selected == member.group_role.name ? ' d-block':'')} onClick={this.handleInputChange} data-toggle="modal" data-target={this.state.current_role == 'admin' && this.state.UserId !== member.user.id ? '#GroupUser':''}>
                                                                <div className="MMenu_Item">
                                                                    <div className="d-flex align-items-center">
                                                                        <img className="GRimg img-fluid mr-8" src="/assets/img/img.png" alt="Logo" />
                                                                        {member.user.first_name ? 
                                                                            <div>{member.user.first_name+' '+member.user.last_name}</div>:
                                                                            <div>{member.user.username}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="text-secondary d-flex align-items-center ml-auto"><span>Permissions: <i>{member.user_permisions.length}</i></span></div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            

                            </div>

                        </div>
                    </div>
                </div>

                <AddUsers onAddUser={this.HandleUpdateList}/>
                <GroupUser onDeleteUser={this.HandleUpdateList} UserGrID={this.state.UserID_open} GroupUserRole={this.props.GroupUserRole} Can_invite_and_delete_users={this.props.Can_invite_and_delete_users} Can_add_new_admins={this.props.Can_add_new_admins} UserId={this.state.UserId} ChangeRole={this.state.Group_user_group_role} />
                <GroupRole UserGrID={this.state.UserID_open} onChangeRole={this.handleUpdate} />

            </Route>
        );
    }
}

export default GroupMembers;