import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import { UserAPI } from '../../utils';

class BrowserExtensions extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
        }
    }
    render() {
        return (     
            <Route>   
                <div className="modal fade ProfAct" id="BrowserExtensions">
                    <div className="modal-dialog modal-dialog-centered text-center">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-majesticons_puzzle-line"></i> Browser Extensions</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 pt-0">
                                
                                <div className="MMenu">
                                    <ul>
                                        <li>
                                            <div className="MMenu_Item">
                                                <div><img className="mr-15" src="/assets/img/logos_chrome.svg" alt="Browser" /> Chrome</div>
                                                <Link to="" className="text-secondary ml-auto"><i className="icon-external-link"></i> Coming soon...</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="MMenu_Item">
                                                <div><img className="mr-15" src="/assets/img/logos_firefox.svg" alt="Browser" /> Mozilla FireFox</div>
                                                <Link to="" className="text-secondary ml-auto"><i className="icon-external-link"></i> Coming soon...</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="MMenu_Item">
                                                <div><img className="mr-15" src="/assets/img/logos_opera.svg" alt="Browser" /> Opera</div>
                                                <Link to="" className="text-secondary ml-auto"><i className="icon-external-link"></i> Coming soon...</Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="MMenu_Item">
                                                <div><img className="mr-15" src="/assets/img/logos_microsoft-edge.svg" alt="Browser" /> Microsoft Edge</div>
                                                <Link to="" className="text-secondary ml-auto"><i className="icon-external-link"></i> Coming soon...</Link>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>

            </Route>
        );
    }
}

export default BrowserExtensions;