import React, { Component } from 'react';
import { Route } from 'react-router';

import { ConfirmEmailAPI, SiteURL } from '../../utils';

class ConfirmEmail extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            // Confirm: [],
        }
                
    }

    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        // const { userName } = this.props.match.params;
        
        if(splitLocation[2]){
            fetch(ConfirmEmailAPI+splitLocation[2], {
                method: 'GET',
                headers: {
                    'Host': 'app.giallaib.com',
                    'Access-Control-Allow-Origin': '*'
                //   'Content-Type': 'application/json',
                //   'User-Agent': 'PostmanRuntime/7.29.0'
                //   Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    // this.setState({
                    //     Confirm: data,
                    // });
                window.location.replace('/');
            })
            .catch(err => {
                // console.log(err);
                return {} //(or [], or an empty return, or any return at all)
            });
        }
    }
    
    render() {


        return (
            <Route>
     
            </Route>
        );
    }
}

export default ConfirmEmail;