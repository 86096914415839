import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { ChangePass } from '../../utils';

class ChangePassword extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            old_password: '', 
            new_password: '', 
            confirm_new_password: '', 
            return_old_password: '', 
            return_new_password: '', 
            return_confirm_new_password: '', 
            password_changed_successfully: '', 
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    render() {

        var currentLocation = window.location.pathname;

        var Pold = this.state.old_password;
        var Pnew = this.state.new_password;
        var Pnewconf = this.state.confirm_new_password;
        
        this.onSubmit = (e) => {
            e.preventDefault();
            
            
            const ChangeP = {
                old_password: Pold,
                new_password1: Pnew,
                new_password2: Pnewconf,
            }
            // console.log(ChangeP);
    
            fetch(ChangePass, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(ChangeP)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                
                if(Array.isArray(data.old_password) ){
                    if(data.old_password[0].length){
                        this.setState({
                            return_old_password: data.old_password[0],
                        });
                    }else{
                        this.setState({
                            return_old_password: '',
                        });
                    }
                }else{
                    this.setState({
                        return_old_password: '',
                    });
                }
                if(Array.isArray(data.new_password1) ){
                    if(data.new_password1[0].length){
                        this.setState({
                            return_new_password: data.new_password1[0],
                        });
                    }else{
                        this.setState({
                            return_new_password: '',
                        });
                    }
                }else{
                    this.setState({
                        return_new_password: '',
                    });
                }
                if(Array.isArray(data.new_password2) ){
                    if(data.new_password2[0].length){
                        this.setState({
                            return_confirm_new_password: data.new_password2[0],
                        });
                    }else{
                        this.setState({
                            return_confirm_new_password: '',
                        });
                    }
                }else{
                    this.setState({
                        return_confirm_new_password: '',
                    });
                }
                // console.log(data.detail);
                // if(typeof data[0] !== "undefined"){
                if(data.detail != undefined){
                    if(data.detail.length ){
                        // if(data.detail.length){
                            this.setState({
                                password_changed_successfully: data.detail,
                            });
                            setTimeout(() => {
                                window.location.replace(currentLocation);
                            }, 2000);
                        // }
                    }
                }

            });
        }


        return (        
            <div className="modal fade ProfAct" id="ChangePassword">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={this.onSubmit} autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-lock"></i> Change Password</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 pt-0">
                                
                                <div className="pxy16 mb-25">

                                        <div className="Forms">
                                                <div className="form-group">
                                                    {this.state.return_old_password ? <span className="Form-error text-red fz12">{this.state.return_old_password}</span>: null}
                                                    <input type="password" name="old_password" className="form-control clearable" id="old_password" placeholder="Old password" value={this.state.old_password} onChange={this.onChange} />
                                                    <label htmlFor="old_password">Old password</label>
                                                </div>
        
                                                <div className="modal-space modal-space--abs mb-16"></div>
        
                                                <div className="form-group">
                                                {this.state.return_new_password ? <span className="Form-error text-red fz12">{this.state.return_new_password}</span>: null}
                                                    <input type="password" name="new_password" className="form-control clearable" id="new_password" placeholder="New password" value={this.state.new_password} onChange={this.onChange} />
                                                    <label htmlFor="new_password">New password</label>
                                                </div>
        
        
                                                <div className="form-group">
                                                {this.state.return_confirm_new_password ? <span className="Form-error bottom_space text-red fz12">{this.state.return_confirm_new_password}</span>: null}
                                                    <input type="password" name="confirm_new_password" className="form-control clearable mb-0" id="confirm_new_password" placeholder="Confirm new password" value={this.state.confirm_new_password} onChange={this.onChange} />
                                                    <label htmlFor="confirm_new_password">Confirm new password</label>
                                                </div>
        
                                        </div>

                                </div>
                                
                            </div>

                            {this.state.password_changed_successfully ? <div className="Copyed" time={0.4}> <span>{this.state.password_changed_successfully}</span></div> : null }

                            <div className="modal-footer">
                                {this.state.password_changed_successfully ? 
                                    (<Route>
                                        <button type="button" className="Button EditBackToSeting" data-dismiss="modal">Cancel</button>
                                    </Route>):
                                    (<Route>
                                        <button type="button" className="Button EditBackToSeting" data-dismiss="modal">Back</button>
                                        <button type="submit" className="Button Button--bg">Save</button>
                                    </Route>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;