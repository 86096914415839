import React, { usestate, useEffect } from 'react';
// const TOKEN_KEY = 'jwtGiallaib';

export const SiteURL = 'https://www.giallaib.com/';
const apiURL = 'https://app.giallaib.com';

export const AllGiallaibUsers = apiURL+'/api/v1/users/all/';
export const ConfirmEmailAPI = apiURL+'/api/v1/users/account-confirm-email/';
export const ResetPasswordAPI = apiURL+'/api/v1/users/password_reset/';
export const ConfirmResetPasswordAPI = apiURL+'/api/v1/users/password_reset/confirm/';
export const loginAPI = apiURL+'/api/v1/users/auth/login/';
export const GoogleloginAPI = apiURL+'/api/v1/users/google-login/';
export const registerAPI = apiURL+'/api/v1/users/auth/register/';
export const UserAPI = apiURL+'/api/v1/users/auth/user/';
export const ProfileInfoAPI = apiURL+'/api/v1/users/profile-info/';
export const ProfileUpdateAPI = apiURL+'/api/v1/users/profile-update/';
export const UserImageUploadAPI = apiURL+'/api/v1/users/profile-image-upload/';
export const ChangePass = apiURL+'/api/v1/users/auth/password/change/';
export const ShortCodeLinkAPI = apiURL+'/api/v1/links/short-url/?short_code=';
export const LinkGetByAPI = apiURL+'/api/v1/links/get-by/';
export const LinksListAPI = apiURL+'/api/v1/links/all/';
export const DeletedLinksInFolderListAPI = apiURL+'/api/v1/links/deleted-links-in-folder/';
export const DeletedLinksInGroupListAPI = apiURL+'/api/v1/links/deleted-links-in-group/';
export const AddLinkStatsAPI = apiURL+'/api/v1/links/add-stats/';
export const LinkStatsAPI = apiURL+'/api/v1/links/link-stats/';
export const LinkAPI = apiURL+'/api/v1/links/';
export const LinksCreateAPI = apiURL+'/api/v1/links/create/';
export const FoldersCreateAPI = apiURL+'/api/v1/folders/create/';
export const FoldersListAPI = apiURL+'/api/v1/folders/all/';
export const FolderAPI = apiURL+'/api/v1/folders/';
export const FolderHistoryAPI = apiURL+'/api/v1/folders/folder-history/';
export const FolderToGroup = apiURL+'/api/v1/folders/transfer-folder-to-group/';
export const SectionsCreateAPI = apiURL+'/api/v1/sections/create/';
export const SectionsListAPI = apiURL+'/api/v1/sections/all/';
export const GroupSectionsListAPI = apiURL+'/api/v1/sections/group/';
export const FolderSectionsListAPI = apiURL+'/api/v1/sections/folder/';
export const DeletedSectionsInFolderListAPI = apiURL+'/api/v1/sections/deleted-section-in-folder/';
export const DeletedSectionsInGroupListAPI = apiURL+'/api/v1/sections/deleted-section-in-group/';
export const SectionAPI = apiURL+'/api/v1/sections/';
export const MailSupport = apiURL+'/mails/support/';
export const GroupsCreateAPI = apiURL+'/api/v1/groups/create/';
export const GroupsListAPI = apiURL+'/api/v1/groups/all/';
export const GroupHistoryAPI = apiURL+'/api/v1/groups/group-history/';
export const GrupTransferAPI = apiURL+'/api/v1/groups/group-transfer/';
export const RolesListAPI = apiURL+'/api/v1/groups/roles/';
export const AddUserToGroupAPI = apiURL+'/api/v1/groups/add-group-user/';
export const AddPermissionUserGroupAPI = apiURL+'/api/v1/groups/add-group-user-permisions/';
export const RemovePermissionUserGroupAPI = apiURL+'/api/v1/groups/delete-group-user-permisions/';
export const RemoveUserGroupAPI = apiURL+'/api/v1/groups/remove-group-user/';
export const InfoUserGroupAPI = apiURL+'/api/v1/groups/info-group-user/';
export const GroupUsersListAPI = apiURL+'/api/v1/groups/groups-list-users/?group='; // ?group=2
export const GroupAPI = apiURL+'/api/v1/groups/'; // /id/
export const GroupImageAPI = apiURL+'/api/v1/groups/group-image-upload/?group='; // /id/
export const BlogListAPI = apiURL+'/api/v1/blog/all/';
export const BlogBySlugAPI = apiURL+'/api/v1/blog/';

export const logout = () => {
    // localStorage.removeItem(TOKEN_KEY);
    localStorage.clear();
}

export const isLogin = () => {
    if (localStorage.getItem('token')) {
        return true;
    }else{
        return false;
    }

}

// export const UserInfo = () => {

// }