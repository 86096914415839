import React, { Component } from 'react';
import { useLocation, Route, Link } from "react-router-dom";

import { FolderAPI, GroupAPI, SectionAPI } from '../../utils';
class TopBar extends Component {

    constructor() {
        super();
        this.state = {
            Group_data: [],
            Folder_data: [],
            Section_data: [],
            Sections_parent: [],
            isOpen: false,
        }
    }

    // if(splitLoc[2] == 'folder'){

    // }
    GetSectionParent = async sectionid => {
        if(sectionid){
            await fetch(SectionAPI+sectionid+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // this.setState({Sections_parent: data});
                this.setState(prevState => ({
                    Sections_parent: prevState.Sections_parent.concat([data])
                  }));
                if(data.parent != null){
                    this.GetSectionParent(data.parent);
                }
                console.log(data);
            });
        }
    }

    async componentDidMount(props) {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        if(splitLocation[2] == 'folder'){
            await fetch(FolderAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({Folder_data: data});
            });
        }
        if(splitLocation[2] == 'group'){
            await fetch(GroupAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({Group_data: data});
            });
        }
        if(splitLocation[4] == 'section'){
            await fetch(SectionAPI+splitLocation[5]+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({Section_data: data});
                if(data.parent != null){
                    this.setState({Sections_parent: this.state.Sections_parent.concat([data]) });
                    this.GetSectionParent(data.parent);
                }
            });
        }

    }



    handleInputChange = event => {
        this.props.onSearchTermChange(event.target.value)
    }

    toggleBox = () => {
        if(this.state.isOpen == true){
            this.setState({
              isOpen: false,
            });
        }else{
            this.setState({
              isOpen: true,
            });
        }
    };
    // toggleBoxClose = () => {
    //     if(this.state.isOpen == true){
    //         this.setState({
    //             isOpen: false,
    //         });
    //     }
    // };

    
    render() {

        // document.body.addEventListener('click', this.toggleBoxClose); 
        
        // var date_folder = '';
        // console.log(this.state.Sections_parent)

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // // console.log(splitLocation)
        // this.setState({folderID: splitLocation[3]});

        var currenturl = '';
        if(splitLocation[2] == 'folder'){
            if(splitLocation[4] == 'section'){
              currenturl = '/dashboard/folder/'+splitLocation[3]+'/section/';
            }
          }else if(splitLocation[2] == 'group'){
            if(splitLocation[4] == 'section'){
              currenturl = '/groups/group/'+splitLocation[3]+'/section/';
            }
          }


        return (
            <div className="row">
                <div className="col-md-12 px-0">
                    <div className="TopBar d-flex align-items-center justify-content-between">
                        <h2 className={splitLocation[4] == 'section' ? "TopBar__Title TopBar__Title--gray ml-15 mb-0" : "TopBar__Title ml-15 mb-0"}>
                            <div className="back d-block d-md-none"><i className="icon-left icon-fz24"></i></div>
                            {splitLocation[2] == 'folder' ? 
                                (<Route>
                                    {splitLocation[4] == 'section' ?
                                    (<a href={`/dashboard/folder/${splitLocation[3]}`}><i className='icon-folder_s_on icon-fz24'></i> <span>{this.state.Folder_data.name}</span></a>) : 
                                    (<Route><i className='icon-folder_s_on icon-fz24'></i> <span>{this.state.Folder_data.name}</span></Route>) }
                                </Route>) : null
                            }
                            {splitLocation[2] == 'group' ? 
                                (<Route>
                                    {splitLocation[4] == 'section' ?
                                    (<a href={`/groups/group/${splitLocation[3]}`}><i className='icon-folder_s_on icon-fz24'></i> <span>{this.state.Group_data.name}</span></a>) : 
                                    (<Route>
                                        <div className="GRimg_Top embed-responsive embed-responsive-1by1">
                                            {this.state.Group_data.image ? 
                                                <img className="embed-responsive-item cover_img img-fluid" src={this.state.Group_data.image} alt="Logo" />:
                                                <img className="embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                            }
                                        </div>
                                        <span>{this.state.Group_data.name}</span>
                                    </Route>) }
                                </Route>) : null
                            }
                            {splitLocation[4] == 'section' ? 
                                <Route>
                                    {this.state.Sections_parent[0] ? 
                                    <Route>
                                        {this.state.Sections_parent.length != 1 ?
                                        <div className='ShowSection'>
                                            <div className='d-flex align-items-center'><i className='icon-right icon-fz12'></i><i onClick={this.toggleBox} className='icon-more_horizontal'></i></div>
                                            <ul className={this.state.isOpen == true ? 'ShowAllSection':'ShowAllSection d-none'}>
                                                {this.state.Sections_parent.slice().reverse().map((section, index) => (
                                                    <Route key={index}>
                                                    {(index < (this.state.Sections_parent.length - 1) ) && this.state.Sections_parent.length != '1' ?
                                                        <li to={currenturl+section.id}><a href={currenturl+section.id}><i className='icon-section icon-fz24'></i><span>{section.name}</span></a></li>
                                                        :null
                                                    }
                                                    </Route>
                                                ))}
                                            </ul>
                                        </div>:null
                                        }
                                        {this.state.Sections_parent.length == 1 ?
                                            <Route>
                                                {this.state.Sections_parent.slice().reverse().map((section, index) => (
                                                    <Route key={index}>
                                                    {(index < (this.state.Sections_parent.length - 1) ) && this.state.Sections_parent.length != '1' ?
                                                        null
                                                        :<a href={currenturl+section.id}><i className='icon-right icon-fz12'></i> <i className='icon-section icon-fz24'></i> <span>{section.name}</span></a>
                                                    }
                                                    </Route>
                                                ))}
                                            </Route>
                                            :<Route>
                                                {this.state.Sections_parent.slice().reverse().map((section, index) => (
                                                    <Route key={index}>
                                                    {(index < (this.state.Sections_parent.length - 1) ) && this.state.Sections_parent.length != '1' ?
                                                        null
                                                        :<a href={currenturl+section.id}><i className='icon-right icon-fz12'></i> <i className='icon-section icon-fz24'></i> <span>{section.name}</span></a>
                                                    }
                                                    </Route>
                                                ))}
                                            </Route>
                                        }
                                    </Route>
                                    :<Route><i className='icon-right icon-fz12'></i> <i className='icon-section icon-fz24'></i> <span>{this.state.Section_data.name}</span></Route>
                                    }
                                </Route>
                             : null
                            }
                            {splitLocation[2] !== 'folder' || splitLocation[2] !== 'group' || splitLocation[4] !== 'section' ? 
                                (<Route><i className={this.props.icon}></i> <span>{this.props.text}</span></Route>) : null
                            }
                            
                        </h2>
                        {splitLocation[2] == 'folder' || splitLocation[2] == 'group' || splitLocation[4] == 'section' ? 
                            (<div className="TopBar__Setting-button mr-15">
                                { splitLocation[4] == 'section' ?
                                    <Route>
                                        {splitLocation[2] != 'group' ?
                                            <button className="Setting" data-toggle="modal" data-target="#SectionSettings"><i className="icon-settings"></i></button>:
                                            <Route>
                                                {this.props.GroupUserRole == 'admin' && this.props.Can_delete_sections == true || this.state.Section_data.user == this.props.UserId ?
                                                    <Route>
                                                        {this.props.If_is_member_of_group == true ?
                                                            <button className="Setting" data-toggle="modal" data-target="#SectionSettings"><i className="icon-settings"></i></button>:
                                                            <button className="Button Button--join"><i className="icon-plus"></i> Join the group</button>
                                                        }
                                                    </Route>:null
                                                }
                                            </Route>
                                        }
                                    </Route>:null
                                }
                                { splitLocation[2] == 'folder' && splitLocation[4] != 'section' ?
                                    (<button className="Setting" data-toggle="modal" data-target="#FolderSettings"><i className="icon-settings"></i></button>):null
                                }
                                { splitLocation[2] == 'group' && splitLocation[4] != 'section' ?
                                    <Route>
                                        {this.props.GroupUserRole == 'admin' ?
                                            <button className="Setting mr-30" data-toggle="modal" data-target="#GroupMembers"><i className="icon-users"></i></button>:null
                                        }
                                        {this.props.GroupUserRole == 'admin' && this.props.Owner_id == this.props.UserId ?
                                            <button className="Setting" data-toggle="modal" data-target="#GroupSettings"><i className="icon-settings"></i></button>:
                                            <Route>
                                                {this.props.If_is_member_of_group == true ?
                                                    <button className="Setting" data-toggle="modal" data-target="#GroupInfo"><i className="icon-info"></i></button>:
                                                    <button className="Button Button--join"><i className="icon-plus"></i> Join the group</button>
                                                }
                                            </Route>
                                        }
                                    </Route>
                                    :null
                                }
                            </div>):
                            (<div className="TopBar__Setting mr-15">
                                <button className="Show-list"><i className="icon-list"></i></button>
                                <button className="Show-grid"><i className="icon-grid"></i></button>
                            </div>)
                        }
                    </div>
                </div>
                <div className="col-md-12 px-0">
                    <div className="Filter-Box d-flex align-items-center">
                        <form className="d-flex w-100">
                            <div className="Filter-Box__search">
                                <i className="icon-search"></i>
                                <input className="form-control mr-sm-2" type="search" onChange={this.handleInputChange} value={this.props.Search_term} placeholder="Search" aria-label="Search" />
                            </div>

                            <div className="Filter-Box__select d-flex align-items-center form-group ml-auto pl-15 pr-15">
                                <label htmlFor="Sort">Sort:</label>
                                <select id="Sort" className="form-control">
                                    <option defaultValue>by name</option>
                                    <option>...</option>
                                </select>
                                <i className="icon-down"></i>
                            </div>
                            {splitLocation[2] == 'folder' || splitLocation[2] == 'group' || splitLocation[4] == 'section' ? 
                                (<div className="TopBar__Setting mr-15">
                                    <button className="Show-list"><i className="icon-list"></i></button>
                                    <button className="Show-grid"><i className="icon-grid"></i></button>
                                </div>) : null
                            }
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;