import React, { Component } from 'react';
import moment from "moment";

import { LinkAPI, UserAPI, ProfileInfoAPI, SiteURL } from '../../utils';

class LinkInfo extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
            user_id: '', 
            copySuccess: '', 
            link_address: '', 
            link_name: '', 
            link_descriptions: '', 
            created_at: '', 
            Link_data: [],
            sessid: this.props.sessid,
            saving: false,
        }
    }

    async componentDidUpdate(prevProps){
        var sessid  = this.props.LinkID;
        if(sessid !== this.state.sessid) {
            
            if(this.props.LinkID){
                await fetch(LinkAPI+this.props.LinkID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data.descriptions == null){
                        data.descriptions = '';
                    }
                        this.setState({
                            Link_data: data,
                            link_address: data.link,
                            link_name: data.name,
                            link_descriptions: data.descriptions,
                            created_at: data.created_at,
                            sessid: this.props.LinkID,
                        });
                });
            }
        }
    }

    copyCodeToClipboard = (e) => {
        var textField = document.createElement('textarea')
        textField.innerText = e.currentTarget.getAttribute("link")
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        this.setState({ copySuccess: 'Copied!' });
        textField.remove()

        setTimeout(() => {
            this.setState({ copySuccess: '' });
        }, 2000);
    }

    async componentDidMount(props) {

        await fetch(ProfileInfoAPI, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    username: data.username,
                    user_id: data.id,
                });
            });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    
    render() {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        // console.log(splitLocation[2]);

        var LinkiD = this.state.sessid;
        var link = this.state.link_address;
        var name = this.state.link_name;
        var descriptions = this.state.link_descriptions;

        async function onSubmitLinkChanges(e){
            e.preventDefault();
    
            const Linkid = LinkiD;

            const links = {
                link: link,
                name: name,
                descriptions: descriptions,
            };
    
    
            await fetch(LinkAPI+Linkid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(links)
            })
            .then(res => res.json())
            .then(data => {
                // this.setState({Folder_data: data});
                if (data) {
                    // window.location.replace('/dashboard');
                    window.location.replace(currentLocation);
                    // console.log(data);
                    // this.setState({saving: false});
                }
            });
            if(name == 'loading...'){
                window.location.replace(currentLocation);
            }
        }


        async function DeleteLink(e){
            e.preventDefault();

            const Linkid = LinkiD;   

            const links = {
                trash: true,
            };
    
            await fetch(LinkAPI+Linkid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(links)
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace(currentLocation);
                }
            });
        }


        function downloadBase64File(e){
            e.preventDefault();
            const linkSource = e.currentTarget.getAttribute("src");
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = e.currentTarget.getAttribute("name");
            downloadLink.click();
       }

        function getAddedDate(data) {
            if(data){
                var date = data.getDate() + "."+ parseInt(data.getMonth()+1) +"."+data.getFullYear();
                return date;
            }
        }

        if (this.state.Link_data.user) {
            var user_link_id = this.state.Link_data.user.id;
            var user_link_username = this.state.Link_data.user.username;
        }

        return (
            <div className="modal fade" id="LinkOptions">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                    <form onSubmit={onSubmitLinkChanges}>
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-more_vert"></i> More options<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                        <div className="LinkOptions pxy16">
                            <div className="LinkOptions__binfo d-flex justify-content-between">
                                <div className="date">Added {moment(this.state.Link_data.created_at).utc().format('DD.MM.YYYY')}</div>
                                {this.state.user_id == user_link_id ?
                                    <div className="author">Creator <span>{this.state.username}</span></div>
                                    :<div className="author">Creator <span>{user_link_username}</span></div>
                                }
                            </div>

                            <div className="LinkOptions__Short d-md-flex justify-content-between" onClick={this.copyCodeToClipboard} link={SiteURL+this.state.Link_data.short_url_code}>
                                <b>Short link:</b>
                                <a href={SiteURL+this.state.Link_data.short_url_code} target="_blank" className="link">{SiteURL+this.state.Link_data.short_url_code}</a>
                                <span className="copy">Copy <i className="icon-copy"></i></span>
                            </div>

                            

                                <div className="Forms">
                                    <div className="form-group">
                                        <input type="text" name="link_address" className="form-control clearable" id="link_address" placeholder="Link address" value={this.state.link_address} onChange={this.onChange} />{' '}
                                        <label htmlFor="link_address">Link address</label>
                                    </div>

                                    <div className="modal-space modal-space--abs mb-16"></div>

                                    <div className="form-group">
                                        <input type="text" name="link_name" className="form-control clearable" id="link_name" placeholder="Link name" value={this.state.link_name} onChange={this.onChange} />{' '}
                                        <label htmlFor="link_name">Link name</label>
                                    </div>
                                    <div className="form-group">
                                        <textarea onChange={this.onChange} name="link_descriptions" className="form-control clearable" id="link_description" value={this.state.link_descriptions} rows="3" />{' '}
                                        <label htmlFor="link_description">Description</label>
                                    </div>

                                </div>


                        </div>


                            <div className="modal-space"></div>
                            <div className="MMenu">
                                <ul>
                                    {/* <li>
                                        <a>
                                            <div className="d-flex align-items-center"><i className="icon-trash text-red"></i> <span>Will be deleted</span></div>
                                            <div className="text-tertiary d-flex align-items-center ml-auto"><span>Never</span> <i className="icon-right ml-15 mr-0"></i></div>
                                        </a>
                                    </li> */}
                                    {typeof splitLocation[2] != "undefined" ?
                                    <li data-toggle="modal" data-target="#SortLink">
                                        <a>
                                            <div className="d-flex align-items-center"><i className="icon-move text-red"></i> <span>Move link</span></div>
                                        </a>
                                    </li>:null
                                    }
                                    <li><a><span>Static QR Code</span></a></li>
                                </ul>
                            </div>
                            <div className="QRcode pxy16 d-flex align-items-center">
                                <img className="img-fluid" src={this.state.Link_data.qrcode} alt="QR code" />
                                <div className="QRcode__download">
                                    <button type="button" className="Button" name={this.state.Link_data.short_url_code} src={this.state.Link_data.qrcode} onClick={downloadBase64File}>Download</button>
                                </div>
                            </div>


                        </div>

                        {this.state.copySuccess ? <div className="Copyed" time={0.4}><i className="icon-copy"></i> <span>Link copied to clipboard</span></div> : null }


                        <div className="modal-footer d-flex">
                            <button type="button" className="Button Button--border-red mr-auto" onClick={DeleteLink}>Delete link</button>
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                            {this.state.saving == false ? 
                                (<button type="submit" className="Button Button--bg">Save</button>) : 
                                (<button type="submit" className="Button Button--disable">Saving..</button>)
                            }
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinkInfo;