import React, { useState, useEffect } from 'react';
import { Route } from 'react-router';

import { InfoUserGroupAPI, GrupTransferAPI } from '../../utils';

import GrupTransferUsers from './GrupTransferUsers';

const GrupTransfer = (props) => {

    const [group, setGroup] = useState('');
    const [selected_user_id, setselected_user_id] = useState([]);

          
    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split("/");

    
    useEffect(() => {
        
    }, []);
    
    const handleGetUserId = UserId => {
        if(UserId){
            fetch(InfoUserGroupAPI+UserId+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                if(data){
                    setselected_user_id(data)
                }
            });
        }
    }

    const handleGrupTransfer = e => {

        const group_update = {
            user: selected_user_id.user.id,
            group_user: selected_user_id.id,
        };

        fetch(GrupTransferAPI+splitLocation[3]+'/', {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(group_update)
        })
        .then(res => res.json())
        .then(data => {
            if (data) {
                window.location.replace(currentLocation);
                // console.log(data);
            }
        });
    }
          
        // console.log(selected_user_id);

        return (
            <Route>
            <div className="modal fade" id="GrupTransfer">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                            <div className="modal-body">

                                <div className='container-fluid'>
                                    <div className='row'>
                                        <div className='col-md-12 py-50 d-flex align-items-center justify-content-center'>
                                            <div className='Gtransfer'>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true"><i className="icon-close"></i></span>
                                                </button>
                                                <i className='icon-alert'></i>
                                                <h3>Transfer group?</h3>
                                                <p>By transferring the Group and choosing another Owner, you will not have full control over the Group.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {selected_user_id.length != 0  ?
                                        <div className="Members">
                                            <div className="MMenu MMenu--members">
                                                <ul>
                                                    <li key={selected_user_id.id} className="d-block">
                                                        <div className="MMenu_Item">
                                                            <div className="d-flex align-items-center">
                                                                <img className="GRimg img-fluid mr-8" src="/assets/img/img.png" alt="Logo" />
                                                                {selected_user_id.user.first_name ? 
                                                                    <div>{selected_user_id.user.first_name+' '+selected_user_id.user.last_name}</div>:
                                                                    <div>{selected_user_id.user.username}</div>
                                                                }
                                                            </div>
                                                            <div className="text-secondary d-flex align-items-center ml-auto"><span>Permissions: <i>{selected_user_id.user_permisions.length}</i></span></div>
                                                        </div>
                                                    </li>
                                                    
                                                </ul>
                                            </div>
                                        </div>:null
                                }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button Button--bg" data-dismiss="modal">Cancel</button>
                                {selected_user_id.length != 0  ?
                                    <button type='button' onClick={handleGrupTransfer} className="Button Button--border-red">Transfer</button>:null
                                }
                                {selected_user_id.length == 0  ?
                                    <button type='submit' className="Button Button--border-red GrupTransferUsers" data-toggle="modal" data-target="#GrupTransferUsers">Transfer</button>:null
                                }
                            </div>
                    </div>
                </div>
            </div>
            <GrupTransferUsers GetGroupUserId={handleGetUserId} />
            </Route>
        );
}

export default GrupTransfer;