import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";

class MobileMenu extends Component {

    // constructor() {
    //     super();
    //     this.state = {
    //         // folderID: 1,
    //     }
    // }

    render() {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        return (
            <div className="Mobile-menu d-flex justify-content-between d-md-none">
                <a href="/dashboard" className={splitLocation[1] == 'dashboard' ? "activ":""}>
                    <i className="icon-folder"></i>
                    <span>Folders</span>
                </a>
                <a href="/groups" className={splitLocation[1] == 'groups' ? "activ":""}>
                    <i className="icon-users"></i>
                    <span>Groups</span>
                </a>
                <a className="Account">
                    <img className="img-fluid" src="/assets/img/prm.png" alt="Account" />
                    <span>Account</span>
                </a>
            </div>
        );
    }
}

export default MobileMenu;