import React, { Component } from 'react';
import '../assets/sass/general.scss';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default Header;