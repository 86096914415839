import React, { useState, useEffect } from 'react';

import { ResetPasswordAPI, ConfirmResetPasswordAPI } from '../../utils';
import HomeContent from '../../components/HomeContent';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const ResetPassword = () => {

  let { TokenCODE } = useParams();
    // const handleLogin = () => {
    //     // login();
    //     props.history.push('/dashboard');
    // }

    const [email, setEmail] = useState('');

    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [sendrecover, setRecover] = useState(false);
    const [PassChanged, setPassChanged] = useState(false);

    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {

      if (localStorage.getItem('token') !== null) {
        window.location.replace('/dashboard');
      } else {
        setLoading(false);
      }

      if (TokenCODE) {
        
        console.log(TokenCODE);
      }
    }, []);

  
    const onSubmit = e => {
      e.preventDefault();
  
      const user = {
        email: email
      };
  
      fetch(ResetPasswordAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          // if(typeof data.status != "undefined"){
          if(Array.isArray(data.email) ){
            if(data.email[0].length){
              setErrors(data.email[0]);
            }
          }
          if(data.status != undefined){
            if(data.status == 'OK'){
              setRecover(true);
            // if(Array.isArray(data.detail) ){
              // setErrors('The recovery link has already been used or has expired.');
            }
          }
        });
    };


    const onSubmitReset = e => {
      e.preventDefault();
  
      const user = {
        password: password1,
        token: TokenCODE
      };
  
      fetch(ConfirmResetPasswordAPI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      })
        .then(res => res.json())
        .then(data => {
          // console.log(data);
          if(data.detail != undefined){
            // if(Array.isArray(data.detail) ){
              setErrors('The recovery link has already been used or has expired.');
            // }
          }
          if(Array.isArray(data.password) ){
            if(data.password[0].length){
              setErrors(data.password[0]);
            }
          }
          if(data.status != undefined){
            if(data.status == 'OK'){
              setPassChanged(true);
            }
          }
        });
    };

    return (
      <Route>
      
      {loading === false && (
        <Route>

          <HomeContent/>
          <div className='SideH activ'>
            <div className="Sidebar h-md-100 Sidebar--scroll px-25">
              <button className="close d-md-block"><i className="icon-close"></i></button>

                {TokenCODE ?

                      <Route>
                        {PassChanged ?
                          <Route>
                            <h3 className="Conect-title mt-30 mb-5 text-center">Success!</h3>
                            <i className="icon-check Conect-mail"></i>
                            <p className="text-center font-pr fz16 mb-40">Now you can Login.</p>

                            <a href="/login" className="Button Button--blue-border mb-20">Login</a>
                          </Route>:
                          <form onSubmit={onSubmitReset}>

                            <h3 className="Conect-title mt-30 mb-25 text-center">Setup new password!</h3>
                            {/* <p className="text-center font-pr fz16 mb-40">Please enter the email address of the account you wish to recover.</p> */}

                            <div className="Forms Forms--blue">

                              <div className="form-group">
                                  {errors ? <span className="Form-error text-red fz12">{errors}</span>: null}
                                  <input type="password" name="name" className="form-control passv" id="password" placeholder="New Password" value={password1} onChange={e => setPassword1(e.target.value)} required />{' '}
                                  <label htmlFor="link">New Password</label>
                                  <div className="pview"><i className="icon-visible_true"></i></div>
                              </div>
                              {/* <div className="form-group">
                                  <input type="password" name="name" className="form-control passv" id="password2" placeholder="Password" value={password2} onChange={e => setPassword2(e.target.value)} required />{' '}
                                  <label htmlFor="link">Confirm new password</label>
                                  <div className="pview"><i className="icon-visible_true"></i></div>
                              </div> */}

                            </div>

                            <button type='submit' className="Button Button--blue mb-25 mb-md-25 mt-15 w-100">Change password</button>
                            
                            <a href="/" className="Button Button--blue-border mb-20">Back</a>
                          </form>
                        }
                      </Route>:
                      <Route>
                        {sendrecover ?
                          <Route>
                            <h3 className="Conect-title mt-30 mb-5 text-center">Success!</h3>
                            <i className="icon-send Conect-mail"></i>
                            <p className="text-center font-pr fz16 mb-40">Please check your email.</p>

                            <a href="/" className="Button Button--blue-border mb-20">Back</a>
                        </Route>:
                          <form onSubmit={onSubmit}>

                            <h3 className="Conect-title mt-30 mb-5 text-center">Recover password</h3>
                            <p className="text-center font-pr fz16 mb-40">Please enter the email address of the account you wish to recover.</p>

                            <div className="Forms Forms--blue">
                                <div className="form-group">
                                    {errors ? <span className="Form-error text-red fz12">{errors}</span>: null}
                                    <input type="email" name="email" className="form-control clearable" id="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                                    <label htmlFor="link">Email</label>
                                </div>
                            </div>
                            <button type='submit' className="Button Button--blue mb-25 mb-md-25 mt-15 w-100">Recover password</button>
                            
                            <a href="/" className="Button Button--blue-border mb-20">Back</a>
                          </form>
                        }
                      </Route>
                  }

            </div>
          </div>
        </Route>
      )}
    </Route>
    );
};

export default ResetPassword;