import React, { useState, useEffect, useCallback } from 'react';
import moment from "moment";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import { BlogListAPI  } from '../utils';


const BlogPost = (props, { setArticleID }) => {

    const [ArticleData, setArticleData] = useState([]);
    
      useEffect(() => {
        setArticleData(props.articleInfo)
      }, []);

    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
      };

    return (
    <Route>
                        <div className="col-md-4 d-md-flex mb-10 mb-md-30">
                            <a href={'/blog/'+ArticleData.slug} slug={ArticleData.slug} className="d-flex flex-column w-100 h-100">
                                {ArticleData.image == "" ?
                                  <div className="Article__Img embed-responsive embed-responsive-16by9">
                                      <img className="img-fluid embed-responsive-item" src="/static/media/concluzion-bg.91a6daa7.png" alt="Articol" />
                                  </div>:
                                  <div className="Article__Img embed-responsive embed-responsive-16by9">
                                      <img className="img-fluid embed-responsive-item" src={ArticleData.image} alt="Articol" />
                                  </div>
                                }
                                <div className="Article__Content">
                                    <h3>{ArticleData.title}</h3>
                                    <p>{extractContent(ArticleData.content).substr(0, 60)} ...</p>
                                    <div className="botom mt-auto">
                                        <span className="data">{moment(ArticleData.created_at).utc().format('DD MMMM YYYY')} &nbsp;{/*•*/}</span>
                                        {/* <span className="category"> Article</span> */}
                                        <span className="barrow"><i className="icon-chevrons-right"></i></span>
                                    </div>
                                </div>
                            </a>
                        </div>
    </Route>
    );
};

export default BlogPost;