import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { LinkStatsAPI } from '../utils';
import axios from 'axios';
import _ from 'lodash';

function Chart(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.LinkID) {
      async function fetchData() {
        const result = await axios.get(LinkStatsAPI+props.LinkID+'/');
        const groupedData = _.groupBy(result.data, function(item) {
          return item.created_at.substr(0, 10); // grupam datele dupa zi
        });
        const chartData = _.mapValues(groupedData, function(item) {
          return item.length; // numaram numarul de inregistrari din fiecare grup
        });
        setData(chartData);
      }
      fetchData();
    }
  }, [props.LinkID]);

            // console.log(props.LinkID);
  return (
    <LineChart width={600} height={300} data={Object.entries(data).map(([date, count]) => ({ date, count }))}>
      <XAxis dataKey="date" />
      <YAxis />
      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
      <Line type="monotone" dataKey="count" stroke="#8884d8" />
      <Tooltip />
      <Legend />
    </LineChart>
  );
}

export default Chart;