import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { MailSupport } from '../../utils';

class ContactTeams extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            subject_message: '', 
            message: '', 
            send_successfully: '', 
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    render() {

        var currentLocation = window.location.pathname;

        var Psubject = this.state.subject_message;
        var Pmesage = this.state.message;
        var Pname = this.props.username;
        
        this.onSubmit = (e) => {
            e.preventDefault();
            
            
            const ChangeP = {
                subject_message: Psubject,
                message: Pmesage,
                name: Pname,
            }
            // console.log(ChangeP);
    
            fetch(MailSupport, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(ChangeP)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                this.setState({
                    send_successfully: data.message,
                });
                setTimeout(() => {
                    window.location.replace(currentLocation);
                }, 2000);
            });
        }


        return (        
            <div className="modal fade ProfAct" id="ContactTeams">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={this.onSubmit} autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-send"></i> Support</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 pt-0">
                                
                                <div className="pxy16">

                                        <div className="Forms">

                                            <div className="form-group">
                                                <select name="subject_message" className="form-control mb-16" id="subject_message" onChange={this.onChange}>
                                                    <option defaultValue>Select subject</option>
                                                    <option value="conducere">Departamentul Conducere</option>
                                                    <option value="marketing">Departamentul Marketing</option>
                                                    <option value="programare">Departamentul Programare</option>
                                                    <option value="design">Departamentul Design</option>
                                                </select>
                                                <label htmlFor="subject_message">Subject</label>
                                            </div>
        
                                            <div className="form-group">
                                                <textarea onChange={this.onChange} name="message" className="form-control mb-16 clearable" id="message" defaultValue={this.state.message} rows="3" />{' '}
                                                <label htmlFor="message">Message</label>
                                            </div>
        
                                        </div>

                                        <button type="submit" className="Button Button--bg ml-auto">Send</button>
                                </div>
                                
                            </div>

                            {this.state.send_successfully ? <div className="Copyed" time={0.4}> <span>{this.state.send_successfully}</span></div> : null }


                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactTeams;