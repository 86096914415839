import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { GroupAPI, SiteURL, GroupImageAPI } from '../../utils';

import GrupTransfer from './GrupTransfer';

class GroupSettings extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            group_name: '',
            group_status: false,
            Group_data: [],
            saving: false,
            group_image: '',
        }
                
    }

    async componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[3]){
            await fetch(GroupAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_data: data,
                        group_name: data.name,
                        group_status: data.status,
                        group_image: data.image,
                    });
            });
        }

    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    onChangeStatus = e => {
        if(e.currentTarget.getAttribute("stats") == 'true') {
            this.setState({ [e.target.name]: true })
        }else{
            this.setState({ [e.target.name]: false })
        }
    }

    onChangeImg = async e => {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // this.setState({ [e.target.name]: e.target.files[0] })
        let formData = new FormData();
        formData.append('file',e.target.files[0]);
        await fetch(GroupImageAPI+splitLocation[3], {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body:formData,
        }).then(res => res.json())
          .then(async (data) => {
            // console.log(data);
            await fetch(GroupAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        group_image: data.image,
                    });
            });
          })
          .catch(
            //   err => console.log(err)
          );
    }
    // onChangeDes = e => {console.log(e.target.value )}
    


    
    render() {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        var FolderiD = splitLocation[3];
        var name = this.state.group_name;
        var status = this.state.group_status;

        async function onSubmitGroupChanges(e){
            e.preventDefault();
    
            const Folderid = FolderiD;            
    
            const folders = {
                name: name,
                status: status,
            };
            // console.log(folders);
    
            await fetch(GroupAPI+Folderid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(folders)
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace(currentLocation);
                }
            });
        }


        async function DeleteFolder(e){
            e.preventDefault();
    
            await fetch(GroupAPI+FolderiD+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace('/groups');
                }
            });
        }

        // console.log(this.state.group_status);


        return (
            <Route>
            <div className="modal fade" id="GroupSettings">
                <div className="modal-dialog modal-dialog-centered modal-md text-center">
                    <div className="modal-content">
                    <form onSubmit={onSubmitGroupChanges}>
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-settings"></i> General settings<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                            <div className="FolderOptions pxy16">

                                <div className="LinkOptions__Short d-md-flex justify-content-between" onClick={this.copyCodeToClipboard} link={SiteURL+this.state.Group_data.group_link}>
                                    <b>Short link:</b>
                                    <a href={SiteURL+this.state.Group_data.group_link} target="_blank" className="link">{SiteURL+this.state.Group_data.group_link}</a>
                                    <span className="copy">Copy <i className="icon-copy"></i></span>
                                </div>
                            
                                    <div className="Forms">

                                        <div className="d-flex align-items-center">
                                            <div className="Group_image embed-responsive embed-responsive-1by1 mr-16">
                                                {this.state.group_image ?
                                                    <img className="GRimg embed-responsive-item cover_img img-fluid" src={this.state.group_image} alt="Logo" />:
                                                    <img className="GRimg embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                                }
                                                <div className="GR_image_upload">
                                                    <label htmlFor="group_image"><i className="icon-photo_camera"></i></label>
                                                    <input type="file" name="group_image" className="form-control clearable mb-0" id="group_image" placeholder="Group image" onChange={this.onChangeImg} />
                                                </div>
                                            </div>
                                            <div className="form-group w-100">
                                                <input type="text" name="group_name" className="form-control clearable mb-0" id="group_name" placeholder="Group name" value={this.state.group_name} onChange={this.onChange} />{' '}
                                                <label htmlFor="group_name">Group name</label>
                                            </div>
                                        </div>

                                    </div>

                            </div>
                        
                            <div className="MMenu">
                                <ul>
                                    <li>
                                        <a>
                                            <div className="d-flex align-items-center"><i className="icon-lock"></i> <span>Status</span></div>
                                            <div className="Custom-radius text-tertiary d-flex align-items-center ml-auto">
                                                {/* {this.state.group_status == true ? */}
                                                    <Route>
                                                        {this.state.group_status == true ?
                                                        <div className="custom-control custom-radio mr-25">
                                                            <input type="radio" className="custom-control-input" id="public_gr" stats="true" onClick={this.onChangeStatus} name="group_status" defaultChecked='checked' />
                                                            <label className="custom-control-label" htmlFor="public_gr">
                                                                <b className='d-block'>Public</b>
                                                            </label>
                                                        </div>:
                                                        <div className="custom-control custom-radio mr-25">
                                                            <input type="radio" className="custom-control-input" id="public_gr" stats="true" onClick={this.onChangeStatus} name="group_status" />
                                                            <label className="custom-control-label" htmlFor="public_gr">
                                                                <b className='d-block'>Public</b>
                                                            </label>
                                                        </div>
                                                        }
                                                    </Route>
                                                    <Route>
                                                        {this.state.group_status == false ?
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="custom-control-input" id="private_gr" stats="false" onClick={this.onChangeStatus} name="group_status" defaultChecked='checked' />
                                                                <label className="custom-control-label" htmlFor="private_gr">
                                                                    <b className='d-block'>Private</b>
                                                                </label>
                                                            </div>:
                                                            <div className="custom-control custom-radio">
                                                                <input type="radio" className="custom-control-input" id="private_gr" stats="false" onClick={this.onChangeStatus} name="group_status" />
                                                                <label className="custom-control-label" htmlFor="private_gr">
                                                                    <b className='d-block'>Private</b>
                                                                </label>
                                                            </div>
                                                        }
                                                    </Route>
                                                {/* } */}
                                            </div>
                                        </a>
                                    </li>
                                    <li data-toggle="modal" data-target="#GroupHistory" data-dismiss="modal"><a><i className="icon-history"></i><span>History of changes</span></a></li>
                                    <li data-toggle="modal" data-target="#Trash" data-dismiss="modal">
                                        <a>
                                            <div className="d-flex align-items-center"><i className="icon-trash"></i> <span>Trash</span></div>
                                            {/* <div className="text-tertiary d-flex align-items-center ml-auto"><span>Never</span> <i className="icon-right ml-15 mr-0"></i></div> */}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="modal-space"></div>

                            <div className="MMenu">
                                <ul>
                                    <li><a className='GrupTransferOpen' data-toggle="modal" data-target="#GrupTransfer"><span>Group control transfer</span></a></li>
                                    <li onClick={DeleteFolder}><a><span className="text-red">Delete group</span></a></li>
                                </ul>
                            </div>

                            <div className="bg-graylight py-30 py-md-50 py-lg-100"></div>

                        </div>


                        <div className="modal-footer d-flex">
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                            {this.state.saving == false ? 
                                (<button type="submit" className="Button Button--bg">Save</button>) : 
                                (<button type="submit" className="Button Button--disable">Saving..</button>)
                            }
                        </div>
                    </form>
                    </div>
                </div>
            </div>

            <GrupTransfer/>

            </Route>
        );
    }
}

export default GroupSettings;