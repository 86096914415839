import React, { Component } from 'react';

import { GroupAPI, SiteURL, RemoveUserGroupAPI } from '../../utils';

class GroupInfo extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            group_name: '',
            copySuccess: '', 
            Group_data: [],
            saving: false,
            group_image: '',
        }
                
    }

    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[3]){
            fetch(GroupAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_data: data,
                        group_name: data.name,
                        group_image: data.image,
                    });
            });
        }

    }

    copyCodeToClipboard = (e) => {
        var textField = document.createElement('textarea')
        textField.innerText = e.currentTarget.getAttribute("link")
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        this.setState({ copySuccess: 'Copied!' });
        textField.remove()

        setTimeout(() => {
            this.setState({ copySuccess: '' });
        }, 2000);
    }

    LeaveGroup(e){
        e.preventDefault();

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        var group_user_id = e.target.id;

        console.log(group_user_id);

        fetch(RemoveUserGroupAPI+group_user_id+'/', {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            window.location.replace('/groups/');
        });
    }

    render() {
        // console.log(this.props.GUid);

        return (
            <div className="modal fade" id="GroupInfo">
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-info"></i> Group information<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                            <div className="GroupInfo pxy16">

                                <div className="d-flex align-items-center">
                                    <div className="Group_image embed-responsive embed-responsive-1by1 mr-16">
                                        {this.state.group_image ?
                                            <img className="GRimg embed-responsive-item cover_img img-fluid" src={this.state.group_image} alt="Logo" />:
                                            <img className="GRimg embed-responsive-item cover_img img-fluid" src="/assets/img/placeholder.png" alt="Logo" />
                                        }
                                    </div>
                                    <div className="d-block">
                                        <h3 className='GroupInfo__name'>Group name</h3>
                                        <div className='GroupInfo__stats'><span>{this.props.Gusers} members</span> <span>{this.props.SectionS} sections</span> <span>{this.props.Glinks} links</span></div>
                                    </div>
                                </div>
                                <div className='GroupInfo__item px-md-10'>
                                    <h4>Description</h4>
                                    <p>Venenatis massa odio risus purus faucibus ligula eget odio amet viverra purus laoreet amet, quisque lectus</p>
                                </div>
                                <div className='GroupInfo__item px-md-10 pb-md-10'>
                                    <h4>Group link:</h4>
                                    <div className='Copy_link d-flex align-items-center' onClick={this.copyCodeToClipboard} link={SiteURL+this.state.Group_data.group_link}>
                                        <a href={SiteURL+this.state.Group_data.group_link} target="_blank" className="link">{SiteURL+this.state.Group_data.group_link}</a>
                                        <span className="copy"><i className="icon-copy"></i> Copy</span>
                                    </div>
                                </div>


                            </div>

                            <div className="modal-space"></div>

     

                            <div className="bg-graylight py-30 py-md-50 py-lg-100"></div>

                        </div>

                        {this.state.copySuccess ? <div className="Copyed" time={0.4}><i className="icon-copy"></i> <span>Link copied to clipboard</span></div> : null }

                        <div className="modal-footer d-flex">
                            <button type="button" className="Button Button--border-red mr-auto" id={this.props.GUid} onClick={this.LeaveGroup}>Leave group</button>
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupInfo;