import React, { Component } from 'react';
import moment from "moment";

import { LinkAPI, UserAPI, ProfileInfoAPI, SiteURL } from '../../utils';

class LinkShortInfo extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
            user_id: '', 
            copySuccess: '', 
            link_address: '', 
            link_name: '', 
            link_descriptions: '', 
            created_at: '', 
            Link_data: [],
            sessid: this.props.sessid,
            saving: false,
        }
    }

    async componentDidUpdate(prevProps){
        var sessid  = this.props.LinkID;
        if(sessid !== this.state.sessid) {
            
            if(this.props.LinkID){
                await fetch(LinkAPI+this.props.LinkID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data.descriptions == null){
                        data.descriptions = '';
                    }
                        this.setState({
                            Link_data: data,
                            link_address: data.link,
                            link_name: data.name,
                            link_descriptions: data.descriptions,
                            created_at: data.created_at,
                            sessid: this.props.LinkID,
                        });
                });
            }
        }
    }

    copyCodeToClipboard = (e) => {
        var textField = document.createElement('textarea')
        textField.innerText = e.currentTarget.getAttribute("link")
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        this.setState({ copySuccess: 'Copied!' });
        textField.remove()

        setTimeout(() => {
            this.setState({ copySuccess: '' });
        }, 2000);
    }

    async componentDidMount(props) {

        await fetch(ProfileInfoAPI, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    username: data.username,
                    user_id: data.id,
                });
            });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    
    render() {
        var currentLocation = window.location.pathname;
        // var splitLocation = currentLocation.split("/");


        function downloadBase64File(e){
            e.preventDefault();
            const linkSource = e.currentTarget.getAttribute("src");
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = e.currentTarget.getAttribute("name");
            downloadLink.click();
       }

        function getAddedDate(data) {
            if(data){
                var date = data.getDate() + "."+ parseInt(data.getMonth()+1) +"."+data.getFullYear();
                return date;
            }
        }

        return (
            <div className="modal fade" id="LinkShortInfo">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-more_vert"></i> Link info<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                        <div className="LinkOptions pxy16">
                            <div className="LinkOptions__binfo d-flex justify-content-between">
                                <div className="date">Added {moment(this.state.Link_data.created_at).utc().format('DD.MM.YYYY')}</div>
                                {this.state.user_id == this.state.Link_data.user ?
                                    <div className="author">Creator <span>{this.state.username}</span></div>:null
                                }
                            </div>

                            <div className="LinkOptions__Short d-md-flex justify-content-between" onClick={this.copyCodeToClipboard} link={SiteURL+this.state.Link_data.short_url_code}>
                                <b>Short link:</b>
                                <a href={SiteURL+this.state.Link_data.short_url_code} target="_blank" className="link">{SiteURL+this.state.Link_data.short_url_code}</a>
                                <span className="copy">Copy <i className="icon-copy"></i></span>
                            </div>

                            

                                <div className="Forms">

                                    <div className='GroupInfo__item px-md-10'>
                                        <h4>Link name</h4>
                                        <p>{this.state.link_name}</p>
                                    </div>
                                    <div className='GroupInfo__item px-md-10'>
                                        <h4>Link description</h4>
                                        <p>{this.state.link_descriptions}</p>
                                    </div>

                                </div>

                        </div>

                            <div className="modal-space"></div>
                            <div className="MMenu">
                                <ul>
                                    <li><a><span>Static QR Code</span></a></li>
                                </ul>
                            </div>
                            <div className="QRcode pxy16 d-flex align-items-center">
                                <img className="img-fluid" src={this.state.Link_data.qrcode} alt="QR code" />
                                <div className="QRcode__download">
                                    <button type="button" className="Button" name={this.state.Link_data.short_url_code} src={this.state.Link_data.qrcode} onClick={downloadBase64File}>Download</button>
                                </div>
                            </div>


                        </div>

                        {this.state.copySuccess ? <div className="Copyed" time={0.4}><i className="icon-copy"></i> <span>Link copied to clipboard</span></div> : null }


                        <div className="modal-footer d-flex">
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinkShortInfo;