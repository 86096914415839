import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';

import { GroupAPI, AddPermissionUserGroupAPI, InfoUserGroupAPI, RemovePermissionUserGroupAPI, RemoveUserGroupAPI, ProfileInfoAPI } from '../../utils';

class GroupUser extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            filter: "",
            Group_user: [],
            Group_user_group_role: [],
            Group_user_user: [],
            Group_user_user_permisions: [],
            sessid: '',
            update_id: [],
            Owner_id: '',

            current_user_permision_add_new_admins: false,
            
            delete_sections: 'false',
            delete_links: 'false',
            invite_and_delete_users: 'false',
            add_new_admins: 'false',

            edit_delete_sections: 'false',
            edit_delete_links: 'false',
            edit_invite_and_delete_users: 'false',
            edit_add_new_admins: 'false',

            loading: false,

        }
        this.onSubmitPermissionsChanges = this.onSubmitPermissionsChanges.bind(this);
        this.Update_User_group = this.Update_User_group.bind(this);
        this.RemoveUser = this.RemoveUser.bind(this);
        
    }
    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

                    /// get Owner_id
                    fetch(GroupAPI+splitLocation[3]+'/', {
                        method: 'GET',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Token ${localStorage.getItem('token')}`
                        }
                    })
                    .then(res => res.json())
                    .then(data => {
                        this.setState({
                            Owner_id: data.user,
                        });
                    });

    }

    componentDidUpdate(prevProps){
        var sessid = this.props.UserGrID;
        if(sessid !== this.state.sessid) {
            // console.log(this.props.UserGrID);
            if(this.props.UserGrID){
                fetch(InfoUserGroupAPI+this.props.UserGrID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data){
                        this.setState({
                            delete_sections: 'false',
                            delete_links: 'false',
                            invite_and_delete_users: 'false',
                            add_new_admins: 'false',
                
                            edit_delete_sections: 'false',
                            edit_delete_links: 'false',
                            edit_invite_and_delete_users: 'false',
                            edit_add_new_admins: 'false',

                            Group_user: data,
                            Group_user_group_role: data.group_role,
                            Group_user_user: data.user,
                            Group_user_user_permisions: data.user_permisions,
                            sessid: this.props.UserGrID,
                        });

                        var vaz = data.user_permisions;
                        vaz.map((d)=>{
                            var name = d.permission.name;
                            this.setState({
                                [name]: d.id,
                            });
                        });
                    }
                });
            }

        }
        if(this.props.ChangeRole.length !== 0){
            var update_id = this.props.ChangeRole;
            if(update_id !== this.state.update_id) {
                this.setState({
                    Group_user_group_role: this.props.ChangeRole,
                    update_id: this.props.ChangeRole
                });
            }
            // console.log(this.props.ChangeRole);
        }
    }

    RemoveUser(e){
        e.preventDefault();

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        var group_user_id = this.props.UserGrID;

        // console.log(group_user_id);

        fetch(RemoveUserGroupAPI+group_user_id+'/', {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            const element = document.querySelector("#GroupUser .close");
            element.click();
            this.props.onDeleteUser(true);
        });
    }
    onChange = e => { 
        if(e.target.checked){
            if(e.currentTarget.getAttribute("per_id")){
                // this.setState({ [e.target.name]: e.currentTarget.getAttribute("per_id") })
                this.setState({ [e.target.name]: 'false' })
                this.setState({ [e.target.id]: 'true' })
            }else{
                this.setState({ [e.target.name]: ""+e.target.checked+"" })
                this.setState({ [e.target.id]: 'true' })
            }
        }else{
            if(e.currentTarget.getAttribute("per_id")){
                this.setState({ [e.target.name]: ""+e.currentTarget.getAttribute("per_id")+"" })
                this.setState({ [e.target.id]: 'false' })
            }else{
                this.setState({ [e.target.name]: ""+e.target.checked+"" })
                this.setState({ [e.target.id]: 'false' })
            }
        }
    };

    Update_User_group(){
        // e.preventDefault();
        var currentLocation = window.location.pathname;

        if(this.props.UserGrID){
            fetch(InfoUserGroupAPI+this.props.UserGrID+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                if(data){
                    this.setState({
                        
                        Group_user: data,
                        Group_user_group_role: data.group_role,
                        Group_user_user: data.user,
                        Group_user_user_permisions: data.user_permisions,
                        sessid: this.props.UserGrID,

                        delete_sections: 'false',
                        delete_links: 'false',
                        invite_and_delete_users: 'false',
                        add_new_admins: 'false',
                    });

                    var vaz = data.user_permisions;
                    vaz.map((d)=>{
                        var name = d.permission.name;
                        this.setState({
                            [name]: d.id,
                        });
                    });
                    console.log('updated');
                    this.setState({ loading: false })
                    // this.setState({ loading: false })
                    // window.location.replace(currentLocation);
                }
            });
        }
    }


    
    onSubmitPermissionsChanges(e){
        e.preventDefault();

        // console.log();
        var edit_delete_sections = this.state.edit_delete_sections;
        var edit_delete_links = this.state.edit_delete_links;
        var edit_invite_and_delete_users = this.state.edit_invite_and_delete_users;
        var edit_add_new_admins = this.state.edit_add_new_admins;
    
        var grup_id = this.state.Group_user.group;
        var user_id = this.state.Group_user_user.id;
        var group_user_id = this.state.Group_user.id;

        if(edit_delete_links !== 'false' || edit_delete_links !== 'false' || edit_invite_and_delete_users !== 'false' || edit_add_new_admins !== 'false'){
            this.setState({ loading: true });
        }

        if(edit_delete_sections == 'false'){
            // console.log('la fel');
        }else if(edit_delete_sections == 'true' ){
            // console.log('add');

            const add_user_permision = {
                group: grup_id,
                user: user_id,
                groupsusers: group_user_id,
                permission: '2',
            };

            // console.log(add_user_permision);
    
            fetch(AddPermissionUserGroupAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(add_user_permision)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);     
                this.Update_User_group();
            });

        }else if(edit_delete_sections != 'false' && edit_delete_sections != 'true' ){

            console.log('del'+edit_delete_sections);
            
            fetch(RemovePermissionUserGroupAPI+edit_delete_sections+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data); 
                this.Update_User_group();
            });
        }

        // Edit_delete_links
        if(edit_delete_links == 'false'){
            // console.log('la fel');
        }else if(edit_delete_links == 'true' ){
            // console.log('add');

            const add_user_permision = {
                group: grup_id,
                user: user_id,
                groupsusers: group_user_id,
                permission: '1',
            };
    
            fetch(AddPermissionUserGroupAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(add_user_permision)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);     
                this.Update_User_group();
            });

        }else if(edit_delete_links != 'false' && edit_delete_links != 'true' ){

            console.log('del'+edit_delete_links);
            
            fetch(RemovePermissionUserGroupAPI+edit_delete_links+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data); 
                this.Update_User_group();
            });
        }

        

        // Edit_invite_and_delete_users
        if(edit_invite_and_delete_users == 'false'){
            // console.log('la fel');
        }else if(edit_invite_and_delete_users == 'true' ){
            // console.log('add');

            const add_user_permision = {
                group: grup_id,
                user: user_id,
                groupsusers: group_user_id,
                permission: '3',
            };
    
            fetch(AddPermissionUserGroupAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(add_user_permision)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);     
                this.Update_User_group();
            });

        }else if(edit_invite_and_delete_users != 'false' && edit_invite_and_delete_users != 'true' ){

            console.log('del'+edit_invite_and_delete_users);
            
            fetch(RemovePermissionUserGroupAPI+edit_invite_and_delete_users+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data); 
                this.Update_User_group();
            });
        }
        

        // Edit_add_new_admins
        if(edit_add_new_admins == 'false'){
            // console.log('la fel');
        }else if(edit_add_new_admins == 'true' ){
            console.log(edit_add_new_admins);

            const add_user_permision = {
                group: grup_id,
                user: user_id,
                groupsusers: group_user_id,
                permission: '4',
            };
    
            fetch(AddPermissionUserGroupAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(add_user_permision)
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data);     
                this.Update_User_group();
            });

        }else if(edit_add_new_admins != 'false' && edit_add_new_admins != 'true' || edit_add_new_admins == '1'){

            console.log('del'+edit_add_new_admins);
            
            fetch(RemovePermissionUserGroupAPI+edit_add_new_admins+'/', {
                method: 'DELETE',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // console.log(data); 
                this.Update_User_group();
            });
        }
        
        
        

    }


    render(props) {
        // console.log(this.state.Owner_id);

                 

        return (
            <div className="modal fade" id="GroupUser">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={this.onSubmitPermissionsChanges}>
                            <div className="modal-header">
                                <h5 className="modal-title d-flex align-items-center"><div className="back Members" data-dismiss="modal"><i className="icon-left icon-fz24"></i></div> <i className="icon-sliders mr-5"></i> User permissions <b></b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body pt-0 px-0">


                                                        <div className="Members">
                                                            <div className="MMenu MMenu--user">
                                                                <ul>
                                                                    <li className={this.state.Group_user.id}>
                                                                            <div className="MMenu_Item">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img className="GRimg img-fluid mr-8" src="/assets/img/img.png" alt="Logo" />
                                                                                    {this.state.Group_user_user.first_name ? 
                                                                                        <div>{this.state.Group_user_user.first_name+' '+this.state.Group_user_user.last_name}</div>:
                                                                                        <div>{this.state.Group_user_user.username}</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    
                                                                </ul>
                                                            </div>
                                                            <div className="modal-space"></div>
                                    
                                                            <div className="MMenu MMenu--user">
                                                                <ul>
                                                                    <li data-toggle="modal" data-target={this.props.GroupUserRole == 'admin' && this.props.Can_add_new_admins == true && this.state.Group_user_user.id != this.state.Owner_id ? "#GroupRole":null } >
                                                                        <a>
                                                                            <div className='fwb'>Group role</div>
                                                                            <div className="text-secondary ml-auto d-flex align-items-center">{this.state.Group_user_group_role.title} {this.props.GroupUserRole == 'admin' && this.props.Can_add_new_admins == true && this.state.Group_user_user.id != this.state.Owner_id ? <i className='icon-right fz12 mr-0'></i>:null }</div>
                                                                        </a>
                                                                    </li>
                                                                    { this.state.Group_user_group_role.name == 'admin' && this.props.UserId == this.state.Owner_id ?
                                                                    <Route>
                                                                    <li>
                                                                        <a>
                                                                            <div className='fwb'>Delete sections</div>
                                                                            <div className="text-secondary ml-auto d-flex align-items-center">
                                                                                <div className="custom-switch">
                                                                                    {this.state.delete_sections !== 'false' ?
                                                                                        <input id="delete_sections" name='edit_delete_sections' per_id={this.state.delete_sections} type="checkbox" onClick={this.onChange} className="custom-control-input" defaultChecked='checked'/>:
                                                                                        <Route>
                                                                                            {this.state.delete_sections !== 'false' ?
                                                                                                <input id="delete_sections" name='edit_delete_sections' type="checkbox" onClick={this.onChange} className="custom-control-input" />:
                                                                                                <input id="delete_sections" name='edit_delete_sections' type="checkbox" onClick={this.onChange} className="custom-control-input" />
                                                                                            }
                                                                                        </Route>
                                                                                    }
                                                                                    <label className="custom-control-label" htmlFor="delete_sections"></label>    
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a>
                                                                            <div className='fwb'>Delete links</div>
                                                                            <div className="text-secondary ml-auto d-flex align-items-center">
                                                                                <div className="custom-switch">
                                                                                    {this.state.delete_links !== 'false' ? 
                                                                                        <input id="delete_links" name='edit_delete_links' per_id={this.state.delete_links} type="checkbox" onClick={this.onChange} className="custom-control-input" defaultChecked='checked'/>:
                                                                                        <Route>
                                                                                            {this.state.delete_links !== 'false' ?
                                                                                                <input id="delete_links" name='edit_delete_links' type="checkbox" onClick={this.onChange} className="custom-control-input" />:
                                                                                                <input id="delete_links" name='edit_delete_links' type="checkbox" onClick={this.onChange} className="custom-control-input" />
                                                                                            }
                                                                                        </Route>
                                                                                    }
                                                                                    <label className="custom-control-label" htmlFor="delete_links"></label>    
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a>
                                                                            <div className='fwb'>Invite and delete users</div>
                                                                            <div className="text-secondary ml-auto d-flex align-items-center">
                                                                                <div className="custom-switch">
                                                                                    {this.state.invite_and_delete_users !== 'false' ? 
                                                                                        <input id="invite_and_delete_users" name='edit_invite_and_delete_users' per_id={this.state.invite_and_delete_users} type="checkbox" onClick={this.onChange} className="custom-control-input" defaultChecked='checked'/>:
                                                                                        <Route>
                                                                                            {this.state.invite_and_delete_users !== 'false' ?
                                                                                                <input id="invite_and_delete_users" name='edit_invite_and_delete_users' type="checkbox" onClick={this.onChange} className="custom-control-input" />:
                                                                                                <input id="invite_and_delete_users" name='edit_invite_and_delete_users' type="checkbox" onClick={this.onChange} className="custom-control-input" />
                                                                                            }
                                                                                        </Route>
                                                                                    }
                                                                                    <label className="custom-control-label" htmlFor="invite_and_delete_users"></label>    
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a>
                                                                            <div className='fwb'>Add new admins</div>
                                                                            <div className="text-secondary ml-auto d-flex align-items-center">
                                                                                <div className="custom-switch">
                                                                                    {this.state.add_new_admins !== 'false' ? 
                                                                                        <input id="add_new_admins" name='edit_add_new_admins' per_id={this.state.add_new_admins} type="checkbox" onClick={this.onChange} className="custom-control-input" defaultChecked='checked'/>:
                                                                                        <Route>
                                                                                            {this.state.add_new_admins !== 'false' ?
                                                                                                <input id="add_new_admins" name='edit_add_new_admins' type="checkbox" onClick={this.onChange} className="custom-control-input" />:
                                                                                                <input id="add_new_admins" name='edit_add_new_admins' type="checkbox" onClick={this.onChange} className="custom-control-input" />
                                                                                            }
                                                                                        </Route>
                                                                                    }
                                                                                    
                                                                                    <label className="custom-control-label" htmlFor="add_new_admins"></label>    
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    </Route>:null
                                                                    }
                                                                    {this.props.Can_invite_and_delete_users == true ?
                                                                        <li><a className="text-red" onClick={this.RemoveUser}> Delete user</a></li>
                                                                        :null
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div className="bg-graylight py-30 py-md-50"></div>

                                                        </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Back</button>
                                {this.state.loading == true ?
                                <button type='submit' disabled className='Button Button--disable'>Saving..</button>:
                                    <Route>
                                        {this.state.edit_delete_sections !== 'false' || this.state.edit_delete_links !== 'false' || this.state.edit_invite_and_delete_users !== 'false' || this.state.edit_add_new_admins !== 'false' ?
                                            <button type="submit" className="Button Button--bg">Save</button>:
                                            <button type="submit" className="Button Button--disable">Save</button>
                                        }
                                    </Route>
                                }
                            </div>
                        </form>
                        {/* <div className="modal-footer d-flex">
                            <button type="button" className="Button Members" data-dismiss="modal">Back</button>
                            <button type="button" onClick='{SaveSortLink}' className="Button Button--disable">Save</button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupUser;