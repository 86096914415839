import React, { useState, useEffect } from 'react';
import moment from "moment";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import { BlogListAPI  } from '../utils';

import BlogPost from './BlogPost';
import SingleBlogContent from './BlogContentSingle';
import Footer from './Footer';


const BlogContent = () => {

    const [BlogData, setBlogData] = useState([]);
    const [ArticleSlug, setArticleSlug] = useState('');
    
      useEffect(() => {
          fetch(BlogListAPI, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          })
            .then(res => res.json())
            .then(data => {
              setBlogData(data);
            });

      }, []);

      
    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split("/");

    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };
     
    // console.log(ArticleData);

    return (
    <Route>
    <header className="Header" id="navbar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-8 d-flex align-items-center">
                    <a className="Header__Logo" href="/"><img className="img-fluid" src="/assets/img/logo.svg" alt="Logo" /></a>
                    <div className="Header__Nav">
                        <ul>
                            <li><a href="">Save links</a></li>
                            <li><a href="">Folders and Sections</a></li>
                            <li><a href="">Groups and Members</a></li>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-4 col-md-4 d-flex align-items-center justify-content-end">
                    <ul className="Header__Language d-lg-flex">
                        <li><a className="activ" href="">EN</a></li>
                        <li><a href="">RO</a></li>
                    </ul>
                    <button className="Header__Button mr-10 mr-md-0 d-lg-flex Try">Login / Register</button>

                    <button className="Header__toggle d-lg-none p-0">
						<span></span>
						<span></span>
						<span></span>
					</button>
                </div>
            </div>
        </div>
    </header>

    <section className="Article pb-0">
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 text-center px-0'>
                    <div className='Breadcrumbs'>
                        <h1 className='Breadcrumbs_title'>Blog</h1>
                        {splitLocation[2] ?
                        <a className='Breadcrumbs_back' href='/blog' title="Blog"><i className='icon-big-arrow-right'></i> Blog</a>:null
                        }
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section className="Article pt-0">
        <div className='container'>
            <div className='row'>


        {splitLocation[2] ? 
            <SingleBlogContent/>:
            <Router>
                {BlogData.map((articol, index) => (

                    <BlogPost key={articol.id} articleInfo={articol}></BlogPost>                       
                ))
                }
            </Router>
        }

            </div>
        </div>
    </section>

    <section className="Concluzion">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 text-center position-relative">
                    <img className="img-fluid" src="/assets/img/logo.svg" alt="Logo" />
                    <h3>Try Giallaib</h3>
                    <p>Free to use bookmarks manager.<br/>Add your entire Team as your needs grow.</p>
                    <button className="Btn Btn--primary Try">Get to work</button>
                </div>
            </div>
        </div>
    </section>

    <Footer/>

    </Route>
    );
};

export default BlogContent;