import React, { Component } from 'react';
import Chart from "../../components/Chart";

import { LinkAPI, UserAPI, ProfileInfoAPI, SiteURL } from '../../utils';

class LinkStatistics extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
            user_id: '', 
            link_address: '', 
            link_name: '', 
            link_descriptions: '', 
            created_at: '', 
            Link_data: [],
            sessid: this.props.sessid,
            saving: false,
        }
    }

    async componentDidUpdate(prevProps){
        var sessid  = this.props.LinkID;
        if(sessid !== this.state.sessid) {
            
            if(this.props.LinkID){
                await fetch(LinkAPI+this.props.LinkID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data.descriptions == null){
                        data.descriptions = '';
                    }
                        this.setState({
                            Link_data: data,
                            link_address: data.link,
                            link_name: data.name,
                            link_descriptions: data.descriptions,
                            created_at: data.created_at,
                            sessid: this.props.LinkID,
                        });
                });
            }
        }
    }


    componentDidMount(props) {


    }


    
    render() {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        const views = [{ date: "2022-02-01", views: 10 }, { date: "2022-02-02", views: 20 }, { date: "2022-02-03", views: 5 }];


        // const dataByLink = {};
        // linkViews.forEach(view => {
        //   const linkId = view.link_id;
        //   const date = new Date(view.date).toLocaleDateString();
        //   if (!dataByLink[linkId]) {
        //     dataByLink[linkId] = {};
        //   }
        //   if (!dataByLink[linkId][date]) {
        //     dataByLink[linkId][date] = 0;
        //   }
        //   dataByLink[linkId][date]++;
        // });
      
        // const linkIds = Object.keys(dataByLink);
        // const chartData = {
        //   labels: [],
        //   datasets: []
        // };
        // linkIds.forEach(linkId => {
        //   const linkData = dataByLink[linkId];
        //   const dates = Object.keys(linkData);
        //   const data = dates.map(date => linkData[date]);
        //   chartData.labels = dates;
        //   chartData.datasets.push({
        //     label: `Link ${linkId}`,
        //     data: data
        //   });
        // });

        // console.log(this.props.LinkID);


        return (
            <div className="modal fade" id="LinkStatistics">
                <div className="modal-dialog modal-dialog-centered modal-lg text-center">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-more_vert"></i> Link statistics<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                            <div className="LinkOptions pxy16">
        
                                <Chart LinkID={this.props.LinkID} />

                            </div>

                        </div>

                        <div className="modal-footer d-flex">
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LinkStatistics;