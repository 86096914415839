import React, { Component, useEffect, useState, useCallback } from 'react';
import $, { isEmptyObject } from 'jquery'; 
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import { FoldersListAPI, SectionsListAPI, LinksListAPI } from '../../utils';

const FoldersView = (props, {GetFolderId, GetSectionId}) => {

        const [FoldersData, setFoldersData] = useState([]);

        useEffect( async () => {
            if (localStorage.getItem('token') === null) {
            window.location.replace('/login');
            } else {
            await fetch(FoldersListAPI, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(data => {
                setFoldersData(data);
                //   setloadingLinks(false);
                });
            }
        }, []);

        const location = useLocation();
        const { pathname } = location;
        const splitLocation = pathname.split("/");
        // console.log(splitLocation);
        
        // function SortinFolder(e){
        //     e.preventDefault();
        //     // var rez = e.target.getAttribute("folder");
        //     var rez = e.currentTarget.getAttribute("folder");
        //     console.log(rez);
        //     $("#SortLink .modal-footer .Button").removeClass('Button--disable');
        // }

        // $(".FSrt").click(function(){
        //     var folder_id = $(this).attr('folder');
        //     // $('.modal-title b').text(link_id);
        //     // $("#SortLink").modal('show');
        //     console.log(folder_id);
        // });

        // var vio = props.sort;
        const [SelectedFolder, setSelectedFolder] = useState('');
        const [SelectedSection, setSelectedSection] = useState('');

        const [SectionData, setSectionData] = useState([]);

        useEffect( async () => {
            await fetch(SectionsListAPI, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
                .then(res => res.json())
                .then(data => {
                setSectionData(data);
                //   setloadingLinks(false);
            });
        }, []);


        const [LinksData, setLinksData] = useState([]);
        //   const [LoadingLinks, setloadingLinks] = useState(true);
        
          useEffect( async () => {
            if (localStorage.getItem('token') === null) {
              window.location.replace('/login');
            } else {
            await fetch(LinksListAPI, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
              })
                .then(res => res.json())
                .then(data => {
                  setLinksData(data);
                //   setloadingLinks(false);
                });
            }
          }, []);

        const handleGetFolderId = useCallback(e => {
            props.GetFolderId(e.currentTarget.getAttribute("folder"))
            // $("#SortLink .modal-footer .Button").removeClass('Button--disable');
            setSelectedFolder(e.currentTarget.getAttribute("folder"));
        }, [GetFolderId])

        const handleGetSectionId = useCallback(e => {
            props.GetSectionId(e.currentTarget.getAttribute("section"))
            // $("#SortLink .modal-footer .Button").removeClass('Button--disable');
            setSelectedSection(e.currentTarget.getAttribute("section"));
        }, [GetSectionId])



        var filteredData = [];

        if(props.sort == 'yes' || props.sort == 'search'){
            const filter = props.SearchFilter;
            const data = FoldersData;
            // const { data } = state;
            // console.log(data);
            // const lowercasedFilter = filter.toLowerCase();
            const lowercasedFilter = filter;
            var filteredData = data.filter(item => {
            //   return Object.keys(item).some(key =>
            //     item[key].toLowerCase().includes(lowercasedFilter)
            //   );
                // return Object.keys(item).some(key =>
                //     typeof item[key] === "string" && item[key].toLowerCase().includes(lowercasedFilter)
                // );
                return item.name.toLowerCase().includes(lowercasedFilter)
            });
        }else{
            var filteredData = FoldersData;
        }

        
        return (
            <div>

                {filteredData.map((folder, index) => (
                    // onClick={() => {window.location.href="/dashboard/folder/"+folder.id}}
                    // <Link key={index} to={`/dashboard/folder/${folder.id}`} className="Folder d-flex"></Link>
                    props.sort == 'yes' ?
                        (
                            <Route key={index}>
                            <div onClick={handleGetFolderId} folder={folder.id} className={SelectedFolder == folder.id ? "Folder d-flex activ" : "Folder d-flex"}>
                                <i className="icon-folder_m_off"></i>
                                <div>
                                    <h4 className="Folder__name">{folder.name}</h4>
                                    <div className="d-flex">
                                        <span className="Folder__links">{SectionData.filter(SectionData => SectionData.folder == folder.id).length} sections</span>
                                        <span className="Folder__links">{LinksData.filter(LinksData => LinksData.folder == folder.id).length} links</span>
                                    </div>
                                </div>
                            </div>
                            {SelectedFolder == folder.id ? 
                                (
                                    SectionData.filter(SectionsData => SectionsData.folder == folder.id).map((section, index_id) => (
                                        <div key={index_id} onClick={handleGetSectionId} section={section.id} className={SelectedSection == section.id ? "Section_sort d-flex activ" : "Section_sort d-flex"}>
                                            <i className="icon-section"></i>
                                            <div>
                                                <h4 className="Section_sort__name">{section.name}</h4>
                                            </div>
                                        </div> 
                                    ))
                                ): null
                            }
                            </Route>
                        ):
                        (
                            <a key={index} href={`/dashboard/folder/${folder.id}`} className={splitLocation[3] == folder.id ? "Folder d-flex activ" : "Folder d-flex"}>
                                <i className="icon-folder_m_off"></i>
                                <div>
                                    <h4 className="Folder__name">{folder.name}</h4>
                                    <div className="d-flex">
                                        <span className="Folder__links">{SectionData.filter(SectionData => SectionData.folder == folder.id).length} sections</span>
                                        <span className="Folder__links">{LinksData.filter(LinksData => LinksData.folder == folder.id).length} links</span>
                                    </div>
                                </div>
                            </a>
                        )
                    
                ))} 

            </div>
        );
}

export default FoldersView;