import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Route } from 'react-router';

import { GroupHistoryAPI } from '../../utils';

const GroupHistory = (props) => {

            const [GroupHistory, setGroupHistory] = useState([]);
            const [errors, setErrors] = useState(false);
            const [loading, setLoading] = useState(true);
          
            var currentLocation = window.location.pathname;
            var splitLocation = currentLocation.split("/");
          
          useEffect( async () => {

            if(splitLocation[3]){
              await fetch(GroupHistoryAPI+splitLocation[3]+'/', {
                  method: 'GET',
                  headers: {
                  'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
              })
                  .then(res => res.json())
                  .then(data => {
                    setGroupHistory(data);
                  });
            }
          }, []);

          // console.log(GroupHistory);

        return (
            <div className="modal fade" id="GroupHistory">
                <div className="modal-dialog modal-dialog-centered modal-lg text-center">
                    <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title d-flex align-items-center"><div className="back" data-toggle="modal" data-target="#GroupSettings" data-dismiss="modal"><i className="icon-left icon-fz24"></i></div> <i className="icon-history"></i> History of changes</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 py-0">

                              {GroupHistory.length > 0 ?
                                <ul className='FolderHistory-List'>
                                    {GroupHistory.sort((a, b) => moment(b.created_at).utc() - moment(a.created_at).utc()).map((grouph, index) => (
                                        <li key={index}>
                                            <div>
                                              <span>{moment(grouph.created_at).utc().format('DD.MM.YYYY HH:mm')}</span>
                                              <p>{grouph.name}</p>
                                            </div>
                                            <i className="icon-more_vert"></i>
                                        </li>
                                    ))}
                                </ul>
                                :<p className='w-100 text-center pt-15'>There is no change history yet.</p>
                              }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button mr-auto" data-toggle="modal" data-target="#GroupSettings" data-dismiss="modal">Back</button>
                            </div>
                    </div>
                </div>
            </div>
        );
}

export default GroupHistory;