import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { SectionAPI } from '../../utils';

class SectionSettings extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            section_name: '',
            section_status: false,
            Section_data: [],
            saving: false,
        }
                
    }

    componentDidMount(props) {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[5]){
            fetch(SectionAPI+splitLocation[5]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Section_data: data,
                        section_name: data.name,
                        section_status: data.status,
                    });
            });
        }

    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })
    
    onChangeStatus = e => {
        if(e.currentTarget.getAttribute("stats") == 'true') {
            this.setState({ [e.target.name]: true })
        }else{
            this.setState({ [e.target.name]: false })
        }
    }
    
    render() {

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");

        var SectioniD = splitLocation[5];
        var name = this.state.section_name;
        var status = this.state.section_status;

        function onSubmitSectionChanges(e){
            e.preventDefault();
    
            const Sectionid = SectioniD;            
            
            const sections = {
                name: name,
                [splitLocation[2]]: splitLocation[3],
                status: status,
            };
    
            fetch(SectionAPI+Sectionid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(sections)
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    window.location.replace(currentLocation);
                }
            });
        }


        function DeleteSection(e){
            e.preventDefault();
    
            // fetch(SectionAPI+SectioniD+'/', {
            //     method: 'DELETE',
            //     headers: {
            //     'Content-Type': 'application/json',
            //     Authorization: `Token ${localStorage.getItem('token')}`
            //     }
            // })
            // .then(res => res.json())
            // .then(data => {
            //     if (data) {
            //         if(splitLocation[2] == 'folder'){
            //             window.location.replace('/dashboard/folder/'+splitLocation[3]);
            //         }else{
            //             window.location.replace('/groups/group/'+splitLocation[3]);
            //         }
            //     }
            // });

            const Sectionid = SectioniD;   

            const sections = {
                trash: true,
            };
    
            fetch(SectionAPI+Sectionid+'/', {
                method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(sections)
            })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    if(splitLocation[2] == 'folder'){
                        window.location.replace('/dashboard/folder/'+splitLocation[3]);
                    }else{
                        window.location.replace('/groups/group/'+splitLocation[3]);
                    }
                }
            });
        }


        return (
            <div className="modal fade" id="SectionSettings">
                <div className="modal-dialog modal-dialog-centered modal-md text-center">
                    <div className="modal-content">
                    <form onSubmit={onSubmitSectionChanges}>
                        <div className="modal-header">
                            <h5 className="modal-title"><i className="icon-section"></i> Section settings<b></b></h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                            </button>
                        </div>
                        <div className="modal-body pt-0 px-0">
                        
                            <div className="FolderOptions pxy16">
                            
                                    <div className="Forms">

                                        <div className="form-group">
                                            <input type="text" name="section_name" className="form-control clearable mb-0" id="section_name" placeholder="Section name" value={this.state.section_name} onChange={this.onChange} />{' '}
                                            <label htmlFor="section_name">Section name</label>
                                        </div>

                                    </div>

                            </div>
                        
                            <div className="MMenu">
                                <ul>
                                    {splitLocation[2] == "group" ?
                                        <li>
                                            <a>
                                                <div className="d-flex align-items-center"><i className="icon-lock"></i> <span>Status</span></div>
                                                <div className="Custom-radius text-tertiary d-flex align-items-center ml-auto">
                                                    {/* {this.state.section_status == true ? */}
                                                        <Route>
                                                            {this.state.section_status == true ?
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_sec" stats="true" onClick={this.onChangeStatus} name="section_status" defaultChecked='checked' />
                                                                <label className="custom-control-label" htmlFor="public_sec">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>:
                                                            <div className="custom-control custom-radio mr-25">
                                                                <input type="radio" className="custom-control-input" id="public_sec" stats="true" onClick={this.onChangeStatus} name="section_status" />
                                                                <label className="custom-control-label" htmlFor="public_sec">
                                                                    <b className='d-block'>Public</b>
                                                                </label>
                                                            </div>
                                                            }
                                                        </Route>
                                                        <Route>
                                                            {this.state.section_status == false ?
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_sec" stats="false" onClick={this.onChangeStatus} name="section_status" defaultChecked='checked' />
                                                                    <label className="custom-control-label" htmlFor="private_sec">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>:
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" className="custom-control-input" id="private_sec" stats="false" onClick={this.onChangeStatus} name="section_status" />
                                                                    <label className="custom-control-label" htmlFor="private_sec">
                                                                        <b className='d-block'>Private</b>
                                                                    </label>
                                                                </div>
                                                            }
                                                        </Route>
                                                    {/* } */}
                                                </div>
                                            </a>
                                        </li>:null
                                    }
                                    {/* <li><a><i className="icon-users"></i><span>History of changes</span></a></li> */}
                                </ul>
                            </div>

                            <div className="modal-space"></div>

                            <div className="MMenu">
                                <ul>
                                    <li onClick={DeleteSection}><a><span className="text-red">Delete section</span></a></li>
                                </ul>
                            </div>

                            <div className="bg-graylight py-30 py-md-50 py-lg-100"></div>

                        </div>


                        <div className="modal-footer d-flex">
                            <button type="button" className="Button" data-dismiss="modal">Cancel</button>
                            {this.state.saving == false ? 
                                (<button type="submit" className="Button Button--bg">Save</button>) : 
                                (<button type="submit" className="Button Button--disable">Saving..</button>)
                            }
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionSettings;