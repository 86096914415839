import React, { Component } from 'react';
import { Route } from "react-router-dom";

import { UserAPI, UserImageUploadAPI, ProfileUpdateAPI, ProfileInfoAPI } from '../../utils';

class ProfilEdit extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            photo_img: '', 
            first_name: '', 
            last_name: '', 
            email: '', 
            bio: '', 
            updating: false, 
            // UserData: [], 
        }
        this.onSubmitProfileChanges = this.onSubmitProfileChanges.bind(this);
    }

    componentDidMount(props) {
    
        fetch(ProfileInfoAPI, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            // console.log(data[0]);
            this.setState({
                photo_img: data.photo,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                bio: data.bio,
                // UserData: data,
            });
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    onChangeImg = e => {
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // this.setState({ [e.target.name]: e.target.files[0] })
        let formData = new FormData();
        formData.append('file',e.target.files[0]);
        fetch(UserImageUploadAPI, {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body:formData,
        }).then(res => res.json())
          .then((data) => {
            console.log(data);
            fetch(ProfileInfoAPI, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        photo_img: data.photo,
                    });
            });
          })
          .catch(
            //   err => console.log(err)
          );
    }

    // var currentLocation = window.location.pathname;
    // var splitLocation = currentLocation.split("/");
    
    // var firstname = this.state.first_name;
    // var lastname = this.state.last_name;
    // var bio_info = this.state.bio;
    
    
    onSubmitProfileChanges(e){
        e.preventDefault();   

        // var currentLocation = window.location.pathname;
        // var splitLocation = currentLocation.split("/");
        
        this.setState({updating: true});

        const user_info = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            bio: this.state.bio,
        };


        fetch(ProfileUpdateAPI, {
            method: 'PATCH',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(user_info)
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                photo_img: data.photo,
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                bio: data.bio,
            });
            setTimeout(() => {
                // window.location.replace(currentLocation);
                this.setState({
                    updating: false,
                });
                const element = document.querySelector("#ProfilEdit .EditBackToSeting");
                element.click();
            }, 1000);
            if (data) {
                // window.location.replace('/dashboard');
                // window.location.replace(currentLocation);
                // console.log(data);
                // this.setState({saving: false});
            }
        });
    }
    

    render() {



        return (        
            <div className="modal fade ProfAct" id="ProfilEdit">
                <div className="modal-dialog modal-dialog-centered text-center">
                    <div className="modal-content">
                        <form onSubmit={this.onSubmitProfileChanges} autoComplete="off">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-settings"></i> Edit profil</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 pt-0">

                                <div className="MEditProfile">
                                    <div className="PR_image embed-responsive embed-responsive-1by1">
                                        {this.state.photo_img ?
                                            <img className="embed-responsive-item cover_img img-fluid mb-15" width="110" height="110" src={this.state.photo_img} alt="Logo" />:
                                            <img className="embed-responsive-item cover_img img-fluid mb-15" width="110" height="110" src="/assets/img/placeholder.png" alt="Logo" />
                                        }
                                    </div>
                                    <input type="file" name="profile_image" className="form-control clearable mb-0" id="profile_image" placeholder="Profile image" onChange={this.onChangeImg} />
                                    <label htmlFor="profile_image" className="Button cursor-pointer Button--bg d-inline-block m-auto">Edit photo</label>
                                </div>

                                <div className="modal-space"></div>

                                    <div className="Forms pxy16">

                                        <div className="form-group">
                                            <input type="text" name="first_name" className="form-control clearable" id="first_name" placeholder="First name" value={this.state.first_name} onChange={this.onChange} />
                                            <label htmlFor="name">First name</label>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="last_name" className="form-control clearable" id="last_name" placeholder="Last name" value={this.state.last_name} onChange={this.onChange} />
                                            <label htmlFor="link">Last name</label>
                                        </div>

                                        <div className="modal-space modal-space--abs mb-16"></div>

                                        <div className="form-group">
                                            <input type="text" name="email" className="form-control clearable" id="email" placeholder="Email" value={this.state.email} disabled />
                                            <label htmlFor="email">Email</label>
                                        </div>

                                        <div className="modal-space modal-space--abs mb-16"></div>

                                        <div className="form-group">
                                            <textarea onChange={this.onChange} name="bio" className="form-control clearable" id="bio" value={this.state.bio} rows="3" />
                                            <label htmlFor="bio">Bio</label>
                                        </div>

                                    </div>
                                
                            </div>

                            {this.state.updating == true ? <div className="Copyed" time={0.4}> <span>Update saved!</span></div> : null }

                            <div className="modal-footer">
                                <button type="button" className="Button EditBackToSeting">Back</button>
                                {this.state.updating == true ?
                                <button type="submit" className="Button Button--disable" disabled>Saving...</button>:
                                <button type="submit" className="Button Button--bg">Save</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfilEdit;