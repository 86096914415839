import React, { Component } from 'react';
import { Route, Link } from "react-router-dom";

import { UserAPI } from '../../utils';

class ChooseAplan extends Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            username: '', 
        }
    }
    render() {
        return (     
            <Route>   
                <div className="modal fade ProfAct" id="ChooseAplan">
                    <div className="modal-dialog modal-dialog-centered modal-90">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"><i className="icon-ph_rocket"></i> Choose a plan</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body px-0 pt-0">
                                
                                <div className="container-fluid">
                                    <div className="StatsP row">
                                        <div className="col-md-4 py-25 d-flex flex-column">
                                            <div className="Item d-flex align-items-center">
                                                <div className="tx"><i className="icon-link"></i> <span>number<br/>of links</span></div>
                                                <div className="nr ml-auto">1 547/2 000</div>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{width: 78 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 py-25 d-flex flex-column">
                                            <div className="Item d-flex align-items-center">
                                                <div className="tx"><i className="icon-folder-_m_on"></i> <span>number<br/>of folders</span></div>
                                                <div className="nr ml-auto">35/200</div>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{width: 28 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 py-25 d-flex flex-column">
                                            <div className="Item d-flex align-items-center">
                                                <div className="tx"><i className="icon-users"></i> <span>number<br/>of groups</span></div>
                                                <div className="nr ml-auto">12/20</div>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{width: 48 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row d-flex border-top">
                                        <div className="TypePlan TypePlan--Free col-md-3 d-flex flex-column px-0">
                                            <div>
                                                <h2 className="TypePlan__name">Free <span className="activated">Activated</span></h2>
                                                <h3 className="TypePlan__title">Care sunt posibilitățile?</h3>
                                                    <h4 className="TypePlan__info">Protecția datelor personale</h4>
                                                    <h4 className="TypePlan__info">Panou comod de gestionare linkuri</h4>
                                                    <h4 className="TypePlan__info">Salvare până la 100 Linkuri</h4>
                                                    <h4 className="TypePlan__info">Creare până la 20 Dosare</h4>
                                                    <h4 className="TypePlan__info">Creare în dosar până la 3 Secții</h4>
                                                    <h4 className="TypePlan__info">Creare până la 5 Grupe</h4>
                                                    <h4 className="TypePlan__info">Creare în grupă până la 3 Secțiuni</h4>
                                                    <h4 className="TypePlan__info">Abonați în grupă până la 30 Membri</h4>
                                                <h3 className="TypePlan__title">Pentru cine se potrivește?</h3>
                                                    <h4 className="TypePlan__info">Companii care vor să testeze serviciile</h4>
                                                    <h4 className="TypePlan__info">Persoane care vor să testeze serviciile</h4>
                                                    <h4 className="TypePlan__info">Persoane prezente pasiv online</h4>
                                                <h3 className="TypePlan__title">*Cât costă lunar și/sau anual?</h3>
                                                    <h4 className="TypePlan__info">Costul de <b>0 USD</b> Lunar</h4>
                                                    <h4 className="TypePlan__info">Costul de <b>0 USD</b> Anual</h4>
                                            </div>
                                            <div className="TypePlan-Buttons">
                                                <button type="button" className="Button Button--bg">Free</button>
                                            </div>
                                            <div className="BorderB"></div>
                                        </div>
                                        <div className="TypePlan TypePlan--Basic col-md-3 d-flex flex-column px-0">
                                            <div>
                                                <h2 className="TypePlan__name">Basic</h2>
                                                <h3 className="TypePlan__title">Care sunt posibilitățile?</h3>
                                                    <h4 className="TypePlan__info">Protecția datelor personale</h4>
                                                    <h4 className="TypePlan__info">Panou comod de gestionare linkuri</h4>
                                                    <h4 className="TypePlan__info">Salvare până la 2 000 Linkuri</h4>
                                                    <h4 className="TypePlan__info">Creare până la 200 Dosare</h4>
                                                    <h4 className="TypePlan__info">Creare în dosar până la 30 Secțiuni</h4>
                                                    <h4 className="TypePlan__info">Creare până la 20 Grupe</h4>
                                                    <h4 className="TypePlan__info">Creare în grupă până la 30 Secțiuni</h4>
                                                    <h4 className="TypePlan__info">Abonați în grupă până la 300 Membri</h4>
                                                    <h4 className="TypePlan__info">Transfer din Dosar în Grupă</h4>
                                                <h3 className="TypePlan__title">Pentru cine se potrivește?</h3>
                                                    <h4 className="TypePlan__info">Persoane prezente activ online</h4>
                                                    <h4 className="TypePlan__info">Companiile mici</h4>
                                                    <h4 className="TypePlan__info">Echipa unui Start Up</h4>
                                                <h3 className="TypePlan__title">*Cât costă lunar și/sau anual?</h3>
                                                    <h4 className="TypePlan__info">Costul de <b>2,90 USD</b> Anual</h4>
                                                    <h4 className="TypePlan__info">Costul de <b>29,9 USD</b> Anual</h4>
                                            </div>
                                            <div className="TypePlan-Buttons">
                                                <button type="button" className="Button Button--bg">2,90 USD/month</button>
                                                <button type="button" className="Button">29,9 USD/year</button>
                                            </div>
                                            <div className="BorderB"></div>
                                        </div>
                                        <div className="TypePlan TypePlan--Advanced col-md-3 d-flex flex-column px-0">
                                            <div>
                                                <h2 className="TypePlan__name">Advanced</h2>
                                                <h3 className="TypePlan__title">Care sunt posibilitățile?</h3>
                                                    <h4 className="TypePlan__info">Protecția datelor personale</h4>
                                                    <h4 className="TypePlan__info">Panou comod de gestionare linkuri</h4>
                                                    <h4 className="TypePlan__info">Salvare până la 10 000 Linkuri</h4>
                                                    <h4 className="TypePlan__info">Creare până la 1 000 Dosare</h4>
                                                    <h4 className="TypePlan__info">Creare în dosar până la 100 Secții</h4>
                                                    <h4 className="TypePlan__info">Creare până la 300 Grupe</h4>
                                                    <h4 className="TypePlan__info">Creare în grupă până la 100 Secții</h4>
                                                    <h4 className="TypePlan__info">Abonați în grupă până la 1 000 Membri</h4>
                                                    <h4 className="TypePlan__info">Transfer din Dosar în Grupă</h4>
                                                    <h4 className="TypePlan__info">Transferul Grupei către alt utilizator</h4>
                                                <h3 className="TypePlan__title">Pentru cine se potrivește?</h3>
                                                    <h4 className="TypePlan__info">Persoane prezente hiper-activ online</h4>
                                                    <h4 className="TypePlan__info">Companiile mijlocii</h4>
                                                    <h4 className="TypePlan__info">Companiile mari</h4>
                                                <h3 className="TypePlan__title">*Cât costă lunar și/sau anual?</h3>
                                                    <h4 className="TypePlan__info">Costul de <b>9,90 USD</b> Anual</h4>
                                                    <h4 className="TypePlan__info">Costul de <b>99,90 USD</b> Anual</h4>
                                            </div>
                                            <div className="TypePlan-Buttons">
                                            <button type="button" className="Button Button--bg">9,90 USD/month</button>
                                                <button type="button" className="Button">99,90 USD/year</button>
                                            </div>
                                            <div className="BorderB"></div>
                                        </div>
                                        <div className="TypePlan TypePlan--Business col-md-3 d-flex flex-column px-0">
                                            <div>
                                                <h2 className="TypePlan__name">Business</h2>
                                                <h3 className="TypePlan__title">Care sunt posibilitățile?</h3>
                                                    <h4 className="TypePlan__info">Protecția datelor personale</h4>
                                                    <h4 className="TypePlan__info">Panou comod de gestionare linkuri</h4>
                                                    <h4 className="TypePlan__info">Salvare nelimitată la Linkuri</h4>
                                                    <h4 className="TypePlan__info">Creare nelimitată la Dosare</h4>
                                                    <h4 className="TypePlan__info">Creare nelimitată la Secții în dosare</h4>
                                                    <h4 className="TypePlan__info">Creare nelimitată la Grupe</h4>
                                                    <h4 className="TypePlan__info">Creare nelimitată la Secții în grupe</h4>
                                                    <h4 className="TypePlan__info">Membri nelimitați într-o Grupă</h4>
                                                    <h4 className="TypePlan__info">Transfer din Dosar în Grupă</h4>
                                                    <h4 className="TypePlan__info">Transferul Grupei către alt utilizator</h4>
                                                    <h4 className="TypePlan__info">Perspectiva dezvoltării ca Giallaiber</h4>
                                                <h3 className="TypePlan__title">Pentru cine se potrivește?</h3>
                                                    <h4 className="TypePlan__info">Persoane care vor să fie utile pentru alții</h4>
                                                    <h4 className="TypePlan__info">Echipe cu statut internațional</h4>
                                                    <h4 className="TypePlan__info">Companii internaționale foarte mari</h4>
                                                <h3 className="TypePlan__title">*Cât costă lunar și/sau anual?</h3>
                                                    <h4 className="TypePlan__info">Costul de <b>24,90 USD</b> Anual</h4>
                                                    <h4 className="TypePlan__info">Costul de <b>249,90 USD</b> Anual</h4>
                                            </div>
                                            <div className="TypePlan-Buttons">
                                                <button type="button" className="Button Button--bg">24,90 USD/month</button>
                                                <button type="button" className="Button">249,90 USD/year</button>
                                            </div>
                                            <div className="BorderB"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Route>
        );
    }
}

export default ChooseAplan;