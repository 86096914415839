import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';

import { AllGiallaibUsers, InfoUserGroupAPI, GroupAPI, GroupUsersListAPI, AddUserToGroupAPI, RemoveUserGroupAPI, ProfileInfoAPI } from '../../utils';

class AddUsers extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            filter: "",
            AllGiallaibUsers: [],
            Group_users: [],
            invite_and_delete_users: false,
            current_permisions: [],
            Owner_id: '',
            UserId: '',
        }
        this.AddUserToGroup = this.AddUserToGroup.bind(this);
        this.RemoveUser = this.RemoveUser.bind(this);
        
    }

    componentDidMount(props) {
        fetch(AllGiallaibUsers, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                AllGiallaibUsers: data,
            });
        });


        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        
        if(splitLocation[3]){
            fetch(GroupUsersListAPI+splitLocation[3], {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_users: data,
                        // Group_users_permisions: data,
                    });
            });

            /// get Owner_id
            fetch(GroupAPI+splitLocation[3]+'/', {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    Owner_id: data.user,
                });
            });
        }

        fetch(ProfileInfoAPI, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('token')}`
            }
          })
        .then(res => res.json())
        .then(data => {
            this.setState({
                UserId: data.id,
            });
        });

        setTimeout(() => {
            var vaz = this.state.Group_users.filter(Group_users => Group_users.user.id == this.state.UserId);
            vaz.map((d)=>{
                d.user_permisions.filter(Perm => Perm.permission.id == '3').map((d)=>{
                    this.setState({
                        invite_and_delete_users: true,
                    });
                });
            });
        }, 1000);
    }

    AddUserToGroup(e){
        e.preventDefault();
        
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        var user_id = e.currentTarget.getAttribute("user");
        
        const add_group_user = {
            user: user_id,
            group: splitLocation[3],
        };
        // console.log(add_group_user);

        fetch(AddUserToGroupAPI, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(add_group_user)
        })
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            fetch(GroupUsersListAPI+splitLocation[3], {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_users: data,
                    });
            });

            
            this.props.onAddUser(true);

        });
    }

    RemoveUser(e){
        e.preventDefault();

        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        var group_user_id = e.currentTarget.getAttribute("user");

        // console.log(group_user_id);

        fetch(RemoveUserGroupAPI+group_user_id+'/', {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {

            fetch(GroupUsersListAPI+splitLocation[3], {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                    this.setState({
                        Group_users: data,
                    });
            });

        });
    }

    handleSearchChange = event => {
        this.setState({ filter: event.target.value });
    };

    render(props) {
        // console.log(this.state.current_permisions);

        // this.state.current_permisions.filter(Perm => Perm.permission.id == '3').map((d)=>{
        //     console.log(d.permission.name);
        // });

        if(this.state.filter != ''){
            var filter = this.state.filter;
          var data = this.state.AllGiallaibUsers;
          var lowercasedFilter = filter;
          var filteredData = data.filter(item => { 
              return item.first_name.toLowerCase().includes(lowercasedFilter) || item.last_name.toLowerCase().includes(lowercasedFilter) || item.username.toLowerCase().includes(lowercasedFilter)
          });
        }else{
          var filteredData = this.state.AllGiallaibUsers;
        }


        return (
            <div className="modal fade" id="AddUsers">
                <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title d-flex align-items-center"><div className="back Members" data-dismiss="modal"><i className="icon-left icon-fz24"></i></div> <i className="icon-user-plus mr-5"></i> Adding users <b></b></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="icon-close"></i></span>
                    </button>
                </div>
                <div className="modal-body pt-0 px-0">

                            <form className="Search">
                                <i className="icon-search"></i>
                                <input className="form-control mb-0 mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={this.handleSearchChange} value={this.state.filter} />
                            </form>


                                            <div className="Users">
                                                <div className="MMenu MMenu--add-users">
                                                    <ul>
                                                        {filteredData.map((user, index) => (
                                                            <li key={user.id}>
                                                                <div className="MMenu_Item">
                                                                    <div className="d-flex align-items-center">
                                                                        <img className="GRimg img-fluid mr-8" src="/assets/img/img.png" alt="Logo" />
                                                                        {user.first_name ? 
                                                                            <div>{user.first_name+' '+user.last_name}</div>:
                                                                            <div>{user.username}</div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                    this.state.Group_users.filter(Gusers => Gusers.user.id === user.id).length == 0 ?
                                                                        <Route>
                                                                            {this.state.invite_and_delete_users == true ? 
                                                                                <button type="button" className="Button w-30 px-10 ml-auto" user={user.id} onClick={this.AddUserToGroup}>Add user</button>:null
                                                                            }
                                                                        </Route>:
                                                                        <Route>
                                                                            <div className="d-block added ml-auto">Added</div>
                                                                            {
                                                                                this.state.Group_users.filter(Gusers => Gusers.user.id == user.id && Gusers.user.id != this.state.UserId && user.id != this.state.Owner_id).map((gr_user, index) => (
                                                                                    this.state.invite_and_delete_users == true ?
                                                                                        <button key={gr_user.id} type="button" className="Button Button-rem w-30 px-10 ml-25" user={gr_user.id} onClick={this.RemoveUser}>Remove</button>:null
                                                                                ))
                                                                            }
                                                                        </Route>
                                                                    }
                                                                </div>
                                                            </li>
                                                        ))}
                                                        
                                                    </ul>
                                                </div>
                                            </div>


                </div>
                    {/* <div className="modal-footer d-flex">
                        <button type="button" className="Button Members" data-dismiss="modal">Back</button>
                        <button type="button" onClick='{SaveSortLink}' className="Button Button--disable">Save</button>
                    </div> */}
                </div>
            </div>
            </div>
        );
    }
}

export default AddUsers;