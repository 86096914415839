import React, { Component } from 'react';
import { useLocation, Route } from "react-router-dom";
import $ from 'jquery';

import { InfoUserGroupAPI, RolesListAPI, ProfileInfoAPI } from '../../utils';

class GroupRole extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            Roles: [],
            Group_user: [],
            Group_user_group_role: [],
            Current_selected_role: '',
            sessid: '',
            loading: false,

        }
        this.onSubmitPermissionsChanges = this.onSubmitPermissionsChanges.bind(this);
        
    }
    
    componentDidMount(props) {
        fetch(RolesListAPI, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            this.setState({
                Roles: data,
            });
        });
    }

    componentDidUpdate(prevProps){
        var sessid = this.props.UserGrID;
        if(sessid !== this.state.sessid) {
            // console.log(this.props.UserGrID);
            if(this.props.UserGrID){
                fetch(InfoUserGroupAPI+this.props.UserGrID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data){
                        this.setState({
                            Group_user: data,
                            Group_user_group_role: data.group_role,
                            Current_selected_role: data.group_role.name,
                            sessid: this.props.UserGrID,
                        });

                    }
                });
            }
        }
    }

    // onChange = e => {
    //     this.setState({ Current_selected_role: e.target.id });
    //     this.props.onChangeRole(this.state.Group_user_group_role);
    // }

    onChange = e => this.setState({ Current_selected_role: e.target.id })
    
    onSubmitPermissionsChanges(e){
        e.preventDefault();

        var grup_id = this.state.Group_user.group;
        var user_id = this.state.Group_user.user.id;
        var group_role = this.state.Current_selected_role;

        const change_user_role = {
            group: grup_id,
            user: user_id,
            group_role: group_role,
        };

        fetch(InfoUserGroupAPI+this.props.UserGrID+'/', {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(change_user_role)
        })
        .then(res => res.json())
        .then(data => {
            if(data){
                // console.log('sch');
                const element = document.querySelector("#GroupRole .close");
                element.click();
                // element.classList.remove("show");
                // element.setAttribute('aria-modal', 'false');
                // element.setAttribute('style', 'display: none;');

                // document.body.classList.remove("modal-open");
                // element.addEventListener('click', function(event) {
                //     console.log('am dat click');
                // })

                fetch(InfoUserGroupAPI+this.props.UserGrID+'/', {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                    }
                })
                .then(res => res.json())
                .then(data => {
                    if(data){
                        // this.setState({
                        //     Group_user: data,
                        //     Group_user_group_role: data.group_role,
                        //     Current_selected_role: data.group_role.name,
                        //     sessid: this.props.UserGrID,
                        // });
                        this.props.onChangeRole(data);

                    }
                });
            }
        });

    }



    render(props) {
        // console.log(this.state.delete_sections);
        // console.log(this.state.Current_selected_role);

                 

        return (
            <div className="modal fade" id="GroupRole">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <form onSubmit={this.onSubmitPermissionsChanges}>
                            <div className="modal-header">
                                <h5 className="modal-title d-flex align-items-center"><div className="back Members" data-dismiss="modal"><i className="icon-left icon-fz24"></i></div> <i className="icon-sliders mr-5"></i> Group role <b></b></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="icon-close"></i></span>
                                </button>
                            </div>
                            <div className="modal-body pt-0 px-0">


                                                        <div className="Members">
                                    
                                                            <div className="MMenu MMenu--roles">
                                                                <ul>
                                                                    {this.state.Roles.map((rol, index) => (
                                                                        <li key={rol.id} className={rol.name == this.state.Current_selected_role ? 'active':null}>
                                                                            <div className="custom-control custom-radio">
                                                                                {this.state.Current_selected_role == rol.name ?
                                                                                    <input type="radio" className="custom-control-input" id={rol.id} onClick={this.onChange} name="group_user_role" defaultChecked={this.state.Current_selected_role == rol.name ? 'checked':'null' } />:
                                                                                    <Route>
                                                                                        {this.state.Current_selected_role == rol.name ?
                                                                                            <input type="radio" className="custom-control-input" id={rol.id} onClick={this.onChange} name="group_user_role" defaultChecked='checked'/>:
                                                                                            <input type="radio" className="custom-control-input" id={rol.id} onClick={this.onChange} name="group_user_role" />
                                                                                        }
                                                                                    </Route>
                                                                                }
                                                                                <label className="custom-control-label" htmlFor={rol.id}>
                                                                                    <b className='d-block'>{rol.title}</b>
                                                                                    <p className='mb-0'>{rol.description}</p>
                                                                                </label>
                                                                            </div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="py-20 py-md-25"></div>

                                                        </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="Button" data-dismiss="modal">Back</button>
                                {this.state.loading == true ?
                                <button type='submit' disabled className='Button Button--disable'>Saving..</button>:
                                <button type="submit" className="Button Button--bg">Save</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default GroupRole;