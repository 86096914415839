import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Dashboard from './pages/Dashboard';
import Groups from './pages/Groups';
import Group from './pages/Group';
import Folders from './pages/Folders';
import Sections from './pages/Sections';
import ShortURL from './pages/ShortURL';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ConfirmEmail from './pages/auth/ConfirmEmail';
import ResetPassword from './pages/auth/ResetPassword';
import PrivateRoute from './router/PrivateRoute';
import PublicRoute from './router/PublicRoute';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  return (
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={false} component={Home} path="/" exact />
          <PublicRoute restricted={false} component={Blog} path="/blog" exact />
          <PublicRoute restricted={false} component={Blog} path="/blog/:articolSlug" exact />
          <PublicRoute restricted={true} component={SignIn} path="/login" exact />
          <PublicRoute restricted={true} component={SignUp} path="/signup" exact />
          <PublicRoute restricted={true} component={ResetPassword} path="/recover-password" exact />
          <PublicRoute restricted={true} component={ResetPassword} path="/reset-password-confirm/:TokenCODE" exact />
          <PublicRoute restricted={false} component={Privacy} path="/privacy-policy" exact />
          <PublicRoute restricted={false} component={SignIn} path="/login/:TKcode" exact />
          <PublicRoute restricted={false} component={Terms} path="/terms-and-conditions" exact />
          <PrivateRoute component={Dashboard} path="/dashboard" exact />
          <PrivateRoute component={Groups} path="/groups" exact />
          <PrivateRoute component={Group} path="/groups/group/:id" exact />
          <PrivateRoute component={Sections} path="/groups/group/:id/section/:idsec" exact />
          <PrivateRoute component={Folders} path="/dashboard/folder/:id" exact />
          <PrivateRoute component={Sections} path="/dashboard/folder/:id/section/:idsec" exact />
          <PublicRoute restricted={false} component={ShortURL} path="/:ShortCODE" exact />
        </Switch>
      </BrowserRouter>
    );
    
}

export default App;
