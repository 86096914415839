import React, { useState, useEffect } from 'react';
import moment from "moment";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
  } from "react-router-dom";

import { BlogListAPI, BlogBySlugAPI } from '../utils';


const SingleBlogContent = (props) => {

    const [ArticleData, setArticleData] = useState([]);

    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split("/");
    
      useEffect(() => {


        fetch(BlogBySlugAPI+splitLocation[2]+'/', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then(res => res.json())
          .then(data => {
            setArticleData(data);
          });

    }, []);

      console.log(props.articleSlug);

    function extractContent(s) {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
      };

      // var bread = document.body.classList.contains('Breadcrumbs_title');
      var bread = document.getElementsByClassName("Breadcrumbs_title");
      var meta_title = document.getElementsByTagName("title");
      var meta = document.getElementsByTagName("meta");

      for (var i = 0; i < bread.length; i++) {
        if(bread[i].innerHTML == "Blog"){
          bread[i].innerHTML = ArticleData.title;
        }
      }

      for (var i = 0; i < meta_title.length; i++) {
        meta_title[i].innerHTML = ArticleData.meta_title+' - Giallaib';
      }

      // console.log(meta);
      // for (var i = 0; i < meta.length; i++) {
        meta['description'].content = ArticleData.meta_descriptions;
      // }

    return (
    <Route>
        <div className='col-12'>
            {ArticleData.image !== "" ? <img className="img-fluid w-100 w-lg-auto wimg float-md-left mr-md-25 mb-25" src={ArticleData.image} alt={ArticleData.title} />:null}
            <div className="Article__Single" dangerouslySetInnerHTML={{__html: ArticleData.content}}></div>
            {/* <div>{extractContent(ArticleData.content)}</div> */}
        </div>
    </Route>
    );
};

export default SingleBlogContent;