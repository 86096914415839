import React, { Component, status, useEffect } from 'react';
import { Route } from "react-router-dom";

import moment from "moment";
import SkeletonLoader from "tiny-skeleton-loader-react";

import { SiteURL, LinkStatsAPI } from '../../utils';

class SortedView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            StatsVisits: [],
            StatsLinkId: ''
        }
    }

    handleInputChange = event => {
        // console.log(this.props.onGetId);
        this.props.onGetId(this.props.link.id)

    }

    GetStats(link_id) {
        // var visits = [];
        // useEffect(() => {
        if(this.state.StatsLinkId != link_id){
            
            fetch(LinkStatsAPI+link_id+'/', {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('token')}`
                }
            })
            .then(res => res.json())
            .then(data => {
                // setStatsVisits(data);
                this.setState({
                    StatsVisits: data,
                    StatsLinkId: link_id
                });
                // console.log('2222')
            });
        }
        // }, []);
        return this.state.StatsVisits.filter(visits => visits.id != null).length
    }

    // const handleGetId = useCallback(e => {
    //     props.onGetId(e.currentTarget.getAttribute("id"))
    //   }, [onGetId])

    render(props) {

        function validURL(image_url) {
            var res = image_url.match(/^[a-z][a-z0-9+.-]*:/);

            if(res !== null){
                return image_url;
            }else{
                return '/assets/img/giallaib.png';
            }
        }

        function checkImage(url) {
            var http = new XMLHttpRequest();
            http.open('GET', url, false);
            http.send();
            if (http.status == 200){
                return false;
            }else{
                return true;
            }
        }

        // const [StatsVisits, setStatsVisits] = useState([]);
        var currentLocation = window.location.pathname;
        var splitLocation = currentLocation.split("/");
        // console.log(splitLocation[2]);


        const isAbsoluteUrl = url => /^[a-z][a-z0-9+.-]*:/.test(url);

        return (
            <div className="Link_style col-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2 px-0" id={this.props.link.id} draggable="true" onDragStart={this.props.onDragStart}>
                <div className="Link">
                    <div className="Link__bg">
                        <div className={this.props.link.thumb_img ? 'embed-responsive embed-responsive-16by9 cursor-pointer':'embed-responsive embed-responsive-16by9 cursor-pointer placeholder-bg'} onClick={()=> window.open(SiteURL+this.props.link.short_url_code, "_blank")}>
                            {this.props.link.thumb_img ? 
                                (<img draggable="false" className={isAbsoluteUrl(this.props.link.thumb_img) == false ? 'Link__share-img cover_img cover_img--placeholder img-fluid w-100':'Link__share-img cover_img img-fluid w-100'} src={validURL(this.props.link.thumb_img)} alt="Logo" />):
                                (this.props.link.site == 'loading...' ? <SkeletonLoader /> : <img draggable="false" className="Link__share-img cover_img img-fluid" src="/assets/img/placeholder_bg.png" alt="Logo" />)
                            }
                        </div>
                        <h3 className="title-list title-list--mobile mr-auto d-none" onClick={()=> window.open(SiteURL+this.props.link.short_url_code, "_blank")}>{this.props.link.site == 'loading...' ? <SkeletonLoader /> : this.props.link.name}</h3>
                        <div className="Link__Options d-flex pt-10">
                            {this.props.link.favicon_img > 0 || isAbsoluteUrl(this.props.link.favicon_img) || checkImage(this.props.link.favicon_img) !== false ? 
                                (<img draggable="false" className="Link__share-img Link__share-img--list Link__icon img-fluid" src={this.props.link.favicon_img} alt="Logo" />) : 
                                (<img draggable="false" className="Link__share-img Link__share-img--list Link__icon img-fluid" src="/assets/img/placeholder.png" alt="Logo" />)
                            }
                            <h3 className="title-list mr-auto d-none" onClick={()=> window.open(SiteURL+this.props.link.short_url_code, "_blank")}>{this.props.link.name}</h3>
                            <span className="data d-none">{moment(this.props.link.created_at).utc().format('DD.MM.YYYY HH:mm')}</span>
                            <span className="views mr-15 d-none"><i className="icon-visible_true"></i> {this.GetStats(this.props.link.id)}</span>
                                <button type="button" onClick={this.handleInputChange} className="statistic ml-auto" data-toggle="modal" data-target="#LinkStatistics">
                                    <i className="icon-statistic2"></i>
                                </button>
                                {splitLocation[2] == 'folder' ?
                                <button type="button" onClick={this.handleInputChange} className="more LinkMoreOptions ml-0" data-toggle="modal" data-target="#LinkOptions">
                                    <i className="icon-more_vert"></i>
                                </button>:
                                <Route>
                                    {this.props.Group_user_role == 'admin' && this.props.Can_delete_links == true || this.props.UserId == this.props.link.user ?
                                        <button type="button" onClick={this.handleInputChange} className="more LinkMoreOptions ml-0" data-toggle="modal" data-target="#LinkOptions">
                                            <i className="icon-more_vert"></i>
                                        </button>:
                                        <button type="button" onClick={this.handleInputChange} className="more LinkMoreOptions ml-0" data-toggle="modal" data-target="#LinkShortInfo">
                                            <i className="icon-more_vert"></i>
                                        </button>
                                    }
                                </Route>
                                }
                        </div>
                    </div>
                    <div className="time-views d-flex align-items-center">
                        <span className="data">{moment(this.props.link.created_at).utc().format('DD.MM.YYYY HH:mm')}</span>
                        <span className="views ml-auto"><i className="icon-visible_true"></i> {this.GetStats(this.props.link.id)}</span>
                    </div>
                    <h3 className="title" onClick={()=> window.open(SiteURL+this.props.link.short_url_code, "_blank")}>{this.props.link.site == 'loading...' ? <SkeletonLoader /> : this.props.link.name}</h3>
                </div>
            </div>
        );
    }
}

export default SortedView;